<template>
  <b-modal
    :visible="modalVisible"
    @change="closeModal"
    hide-header-close
    no-close-on-backdrop
    id="modal-dropout"
    size="xl"
  >
    <template slot="modal-title">
      <h2 class="modal-title">
        {{ $t('discontinue_participant_from_study') }}
      </h2>
    </template>
    <label for="dropout_reason" class="m-0">{{ $t('select_a_reason') }}</label>
    <v-select
      :options="selectOptions"
      :placeholder="$t('select_a_reason')"
      v-model="discontinuedSelectedReason"
      class="full square"
    />
    <div v-if="showEnterReasonTextarea">
      <label for="dropout_reason" class="m-0 mt-5">{{
        $t('protocol_reject_enter_reason')
      }}</label>
      <b-form-textarea
        id="dropout_reason"
        placeholder="Enter a drop out reason"
        style="padding-top: 5px"
        rows="3"
        v-model="reasonText"
      ></b-form-textarea>
    </div>

    <b-alert show variant="danger">
      <img src="@/assets/icons/info.svg" alt="info" class="info-icon" />
      <div>
        <p class="avenir-bold">{{ $t('dropout_infobox_header') }}</p>
        <p>{{ $t('dropout_infobox_detail') }}</p>
        <b-form-checkbox
          id="i_understand_continue"
          name="i_understand_continue"
          v-model="checkSelected"
        >
          {{ $t('i_understand_continue') }}
        </b-form-checkbox>
      </div>
    </b-alert>

    <template slot="modal-footer">
      <b-row>
        <base-button
          type="secondary"
          class="avenir-bold cancel-button"
          @click="closeModal"
          :disabled="cancelButtonDisabled"
          >{{ $t('cancel') }}</base-button
        >
        <base-button
          type="primary"
          class="avenir-bold"
          :disabled="discontinuedButtonDisabled"
          @click="postDiscontinuedData"
          >{{ $t('discontinue_participant') }}</base-button
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sendParticipantDiscontinuedReason } from '@/api/participant';

export default {
  name: 'ModalDiscontinuedParticipant',
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    participantData: {
      type: Object,
    },
  },

  data() {
    return {
      isDropOutModalOn: false,
      reasonText: '',
      checkSelected: false,
      discontinuedSelectedReason: '',
      discontinuedButtonDisabled: true,
      cancelButtonDisabled: false,
      showEnterReasonTextarea: false,
      selectOptions: [
        {
          label: this.$t('discontinue_participant_dropout'),
          value: 'dropped-out',
        },
        {
          label: this.$t('discontinue_participant_consent_withdrawn'),
          value: 'consent-withdrawn',
        },
        { label: this.$t('discontinue_participant_lost'), value: 'lost' },
        { label: this.$t('discontinue_participant_other'), value: 'other' },
      ],
    };
  },

  watch: {
    discontinuedSelectedReason(newValue) {
      this.checkSelected = false;
      this.showEnterReasonTextarea = newValue.value === 'other';
    },
    reasonText(value) {
      if (!value.length && this.discontinuedSelectedReason.value === 'other') {
        this.checkSelected = false;
      }
      if (
        value.length &&
        this.discontinuedSelectedReason.value === 'other' &&
        this.checkSelected === true
      ) {
        this.discontinuedButtonDisabled = false;
      }
    },
    checkSelected(value) {
      value ? this.allowNextStep() : (this.discontinuedButtonDisabled = true);
    },
  },

  computed: {
    ...mapState({
      selectedParticipant: ({ participants }) => {
        const { clientId, studyId, protocolId, siteId, id } =
          participants.selectedParticipant;
        return { clientId, studyId, protocolId, siteId, id };
      },
    }),
    discontinuedReason() {
      return this.participantData.discontinuedReason.discontinuedReason;
    },
  },

  methods: {
    ...mapActions({
      setVisitsReschedule: 'participants/updateParticipantVisit',
    }),
    allowNextStep() {
      if (
        (this.discontinuedSelectedReason.value.length &&
          this.discontinuedSelectedReason.value !== 'other') ||
        (this.discontinuedSelectedReason.value === 'other' &&
          this.reasonText.length)
      ) {
        this.discontinuedButtonDisabled = false;
      } else {
        this.discontinuedButtonDisabled = true;
      }
    },

    closeModal() {
      this.reasonText = '';
      this.checkSelected = false;
      this.discontinuedSelectedReason = '';
      this.$emit('close-modal');
    },

    postDiscontinuedData() {
      this.discontinuedButtonDisabled = true;
      this.cancelButtonDisabled = true;
      const discontinuedReason = {
        discontinuedReason: this.discontinuedSelectedReason.value,
        description: this.reasonText,
      };
      const { clientId, studyId, protocolId, siteId, id } =
        this.participantData;
      sendParticipantDiscontinuedReason({
        clientId,
        studyId,
        protocolId,
        siteId,
        id,
        discontinuedReason,
      })
        .then((res) => {
          if (res.code === 201) {
            this.$emit('reload-data');
            this.$toast.info(this.$t('toast_participant_discontinue'));
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t(err.data.msg));
        })
        .finally(() => {
          this.discontinuedButtonDisabled = false;
          this.cancelButtonDisabled = false;
          this.$emit('close-modal');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  color: #565f77 !important;
}
.bold {
  font-weight: bold !important;
}
.cancel-button {
  color: #565f77 !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #a1a8c0 !important;
  border-color: #e8e9ec !important;
}
</style>
