<template>
  <div>
    <h2 class="mb-5" v-if="!isView">{{ $t('overview') }}</h2>
    <client-information :clientId="study.clientId" v-if="!isClientUser" />
    <div class="overview-card">
      <p class="text-lg">{{ $t('study_information') }}</p>
      <b-row>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6">{{ $t('study_name') }}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ study.name }}</b-col>
          </b-row>
        </b-col>
        <b-col cols="8">
          <b-row>
            <b-col class="opacity-6">
              {{ $t('description') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ study.description }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <assigned-users-table
      sectionTitle="Study Coordinators"
      :coordinators="study.coordinators"
    />

    <div class="overview-card" v-if="study.studyFeaturesConfig">
      <p class="text-lg">{{ $t('study_features') }}</p>
      <b-row>
        <b-col>
          <div class="opacity-6 mb-2">{{ $t('mobile_platforms') }}</div>
          <div>
            <p
              class="avenir-bold mb-0"
              v-if="study.studyFeaturesConfig.mobilePlatformIos"
            >
              <i class="ni ni-check-bold text-primary"></i>{{ $t('os_ios') }}
            </p>
            <p
              class="avenir-bold mb-0"
              v-if="study.studyFeaturesConfig.mobilePlatformAndroid"
            >
              <i class="ni ni-check-bold text-primary"></i
              >{{ $t('os_android') }}
            </p>
          </div>
        </b-col>
        <b-col>
          <div class="opacity-6 mb-2">{{ $t('mobile_app_notifications') }}</div>

          <p
            class="avenir-bold"
            v-html="
              study.studyFeaturesConfig.mobileAppNotifications ? 'Yes' : 'No'
            "
          ></p>
        </b-col>
        <b-col>
          <div class="opacity-6 mb-2">{{ $t('relapse_protocol') }}</div>

          <p
            class="avenir-bold"
            v-html="study.studyFeaturesConfig.relapseProtocol ? 'Yes' : 'No'"
          ></p>
        </b-col>
        <b-col>
          <div class="opacity-6 mb-2">{{ $t('quick_tour') }}</div>
          <p
            class="avenir-bold"
            v-html="study.studyFeaturesConfig.quickTour ? 'Yes' : 'No'"
          ></p>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="3">
          <div class="opacity-6 mb-2">{{ $t('custom_participant_id') }}</div>
          <p
            class="avenir-bold"
            v-html="
              study.studyFeaturesConfig.haveCustomParticipantCode ? 'Yes' : 'No'
            "
          ></p>
        </b-col>
        <b-col
          cols="3"
          v-if="study.studyFeaturesConfig.haveCustomParticipantCode"
        >
          <div class="opacity-6 mb-2">{{ $t('custom_code') }}</div>
          <p
            class="avenir-bold"
            v-html="study.studyFeaturesConfig.customParticipantCode"
          ></p>
        </b-col>
        <b-col cols="3">
          <div class="opacity-6 mb-2">{{ $t('patient_diary') }}</div>
          <p
            v-if="study.studyFeaturesConfig.widgets && isNewStudyRoute"
            class="avenir-bold"
          >
            {{ isStudyFeaturesConfigEmpty ? $t('yes') : $t('no') }}
          </p>
          <p v-if="isEditStudyRoute" class="avenir-bold">
            {{
              study.studyFeaturesConfig.widgets.length ? $t('yes') : $t('no')
            }}
          </p>
          <p v-if="!isEditStudyRoute && !isNewStudyRoute" class="avenir-bold">
            {{ studyFeaturesConfigHasWidgets ? $t('yes') : $t('no') }}
          </p>
        </b-col>

        <b-col cols="3">
          <div class="opacity-6 mb-2">{{ $t('languages') }}</div>
          <p
            v-for="(languageCode, index) in study.studyFeaturesConfig.languages"
            :key="index"
            class="avenir-bold mb-1"
          >
            <i class="ni ni-check-bold text-primary"></i>
            {{ languageNameFromCode(languageCode) }} -
            {{ languageCode.toUpperCase() }}
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="text-lg">{{ $t('study_tasks') }}</p>
      <b-row class="mt-2" v-if="!isCreateOrUpdate">
        <b-col
          cols="4"
          v-for="(task, index) in tasksFromAlreadyCreatedStudy"
          :key="index"
        >
          <div class="opacity-6 mb-2">
            {{ task.partnerName }}
          </div>
          <p
            v-for="(testName, index) in task.tests.names"
            :key="index"
            class="avenir-bold mb-1"
          >
            <span class="mr-2"
              ><i class="ni ni-check-bold text-primary"></i
            ></span>
            {{ $t(testName) }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-else>
        <b-col
          cols="4"
          v-for="(task, index) in selectedTasksFromPreviousStep.length > 0
            ? selectedTasksFromPreviousStep
            : tasksFromAlreadyCreatedStudy"
          :key="index"
        >
          <div class="opacity-6 mb-2">
            {{
              selectedTasksFromPreviousStep.length > 0
                ? task.partner
                : task.partnerName
            }}
          </div>
          <p
            v-for="(testName, index) in selectedTasksFromPreviousStep.length > 0
              ? task.testNames
              : task.tests.names"
            :key="index"
            class="avenir-bold mb-1"
          >
            <span class="mr-2"
              ><i class="ni ni-check-bold text-primary"></i
            ></span>
            {{ $t(testName) }}
          </p>
        </b-col>
      </b-row>
    </div>

    <b-row
      class="my-5"
      v-if="!['client-study-details', 'study-details'].includes($route.name)"
    >
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          :disabled="loading"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="isEdit ? updateStudy() : postStudy()"
          :disabled="loading"
          data-testid="submit-button"
          >{{ isEdit ? $t('study_update') : $t('study_create') }}
          <b-spinner v-if="loading" small></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { languagesDefault } from '@/constants/languages-constants';
import ClientInformation from '@/components/Overviews/ClientInformation';
import {
  createStudy,
  postFeatures,
  createCoordinator,
  updateStudy,
  putStudyTests,
  postStudyTests,
  updateFeatures,
  deleteStudyCoordinator,
  fetchClientStudies,
} from '@/api/study';
import { createExpert, updateExpert } from '@/api/experts';
import { mapGetters, mapState } from 'vuex';
import { reduceKeys, hasKeysLength } from '@/utils';
import AssignedUsersTable from '../Overviews/AssignedUsersTable';
import { equalArrays } from '@/utils/array.utils';
import { updateClientRepresentative } from '@/api/client';

export default {
  components: {
    ClientInformation,
    AssignedUsersTable,
  },
  data() {
    return {
      languagesList: [...languagesDefault],
      loading: false,
      isView: this.$route.name === 'client-study-details' || 'study-details',
      isEdit: this.$route.name.includes('edit'),
      isCreateOrUpdate:
        this.$route.name.includes('create-study') ||
        this.$route.name.includes('edit'),
    };
  },
  props: {
    study: Object,
    delete: Array,
  },
  computed: {
    ...mapState({
      clients: (state) => state.clients.clients,
      selectedStudy: (state) => state.studies.selectedStudy,
      selectedTasksFromPreviousStep: (state) => state.studies.selectedTasksData,
    }),
    ...mapGetters({
      hasAppropriateProtocols: 'studies/hasAppropriateProtocols',
      selectedTasksIds: 'studies/allTestIds',
      testIdsToUpdate: 'studies/testIdsForUpdate',
      tasksFromAlreadyCreatedStudy: 'studies/tasksFromAlreadyCreatedStudy',
    }),
    isNewStudyRoute() {
      return this.$route.path.includes('add');
    },
    isEditStudyRoute() {
      return this.$route.path.includes('edit');
    },
    isStudyFeaturesConfigEmpty() {
      return Object.keys(this.study.studyFeaturesConfig.widgets).length > 0;
    },
    studyFeaturesConfigHasWidgets: function () {
      if (this.isView) {
        return this.study.studyFeaturesConfig?.studyFeaturesConfigWidgets
          ?.length;
      } else {
        return false;
      }
    },
  },
  methods: {
    async postStudy() {
      this.loading = true;
      try {
        const payload = {
          name: this.study.name,
          description: this.study.description,
        };
        if (!payload.description.trim()) delete payload.description;

        let {
          data: { id: newStudyId },
        } = await createStudy(this.study.clientId, payload);

        if (!newStudyId) {
          const payload = { 'filter[name]': this.study.name };
          const { data } = await fetchClientStudies(payload);
          newStudyId = data[0]?.id;
        }

        if (newStudyId) {
          await this.completeStudySubmit(newStudyId);
        } else {
          this.$toast.error(this.$t('error_something_went_wrong'));
        }
      } catch (error) {
        this.displayError('Error create study', error);
      } finally {
        this.loading = false;
      }
    },
    async completeStudySubmit(studyId) {
      const { clientId, coordinators, studyFeaturesConfig } = this.study;
      // Submit request for new study coordinators
      coordinators
        .filter((rep) => rep.type === 'new')
        .forEach((rep) =>
          this.addCoordinatorFromNewUser(clientId, studyId, rep)
        );

      // Submit requests for assigning study coordinators
      coordinators
        .filter((rep) => rep.type === 'existing')
        .forEach(({ id, roles }) =>
          createCoordinator(clientId, studyId, {
            expertId: id,
            roles: roles.map((id) => ({ id })),
          })
        );

      try {
        if (!studyFeaturesConfig.haveCustomParticipantCode)
          delete studyFeaturesConfig.customParticipantCode;

        const payload = {
          ...studyFeaturesConfig,
          languages: studyFeaturesConfig.languages,
        };

        await postFeatures(clientId, studyId, payload);
        await postStudyTests(clientId, studyId, this.selectedTasksIds);
        this.$toast.info(this.$t('toast_study_new_created'));
        this.$router.push({
          name: 'client-studies',
          params: { isNewlyCreated: true, studyId },
        });
      } catch (error) {
        this.displayError('Error complete study create', error);
      }
    },
    async updateStudy() {
      this.loading = true;

      try {
        await this.updateStudyInfo();
        await this.updateCoordinators();
        await this.updateStudyFeatures();

        const { clientId, id } = this.study;
        if (this.testIdsToUpdate?.length) {
          await putStudyTests(clientId, id, this.testIdsToUpdate);
        }

        const routeName =
          this.$route.meta.parent === 'study'
            ? 'study-details'
            : `${this.$route.meta.parent}-study-details`;

        this.$router.push({
          name: routeName,
        });
        this.$toast.info(this.$t('toast_study_updated'));
      } catch (error) {
        console.error(error?.data);
        const errorMsg =
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong');
        this.$toast.error(errorMsg);
      } finally {
        this.loading = false;
      }
    },
    async updateStudyInfo() {
      const { name, description, clientId, id } = this.study;
      const basic = reduceKeys(
        {
          name: this.selectedStudy.name,
          description: this.selectedStudy.description,
        },
        {
          name,
          description,
        }
      );

      if (hasKeysLength(basic)) {
        await updateStudy(clientId, id, {
          name,
          description: !description.trim() ? null : description,
        });
      }
    },
    async updateCoordinators() {
      try {
        const { clientId, id, coordinators } = this.study;

        // Delete coordinators
        if (this.delete?.length > 0 && coordinators?.length > 0) {
          let promises = [];
          this.delete.forEach((coordinatorId) => {
            promises.push(deleteStudyCoordinator(clientId, id, coordinatorId));
          });
          await Promise.all(promises);
        }

        // Add new coordinators
        const newUsersToAdd = coordinators.filter(
          (coordinator) => coordinator.type === 'new'
        );

        if (newUsersToAdd.length > 0) {
          let promises = [];
          newUsersToAdd.forEach((coordinator) => {
            promises.push(
              this.addCoordinatorFromNewUser(clientId, id, coordinator)
            );
          });
          await Promise.all(promises);
        }

        // Add existing coordinators
        const existingUsersToAdd = coordinators.filter(
          (coordinator) => coordinator.type === 'existing'
        );

        if (existingUsersToAdd.length > 0) {
          let promises = [];
          existingUsersToAdd.forEach((coordinator) => {
            promises.push(
              this.addCoordinatorFromExistingUser(clientId, id, coordinator)
            );
          });
          await Promise.all(promises);
        }

        // update coordinators fields
        const coordsWithFieldsChanged = coordinators.filter(
          (rep) => !['new'].includes(rep.type) && rep.fieldsChanged
        );
        if (coordsWithFieldsChanged.length > 0) {
          let promises = [];
          coordsWithFieldsChanged.forEach((coordinator) => {
            // eslint-disable-next-line no-unused-vars
            const { expertId, roles, fieldsChanged, rolesChanged, ...user } =
              coordinator;
            promises.push(updateExpert(expertId, user));
          });
          await Promise.all(promises);
        }

        // update coordinators roles
        const coordsWithRolesChanged = coordinators.filter(
          (rep) => !['new', 'existing'].includes(rep.type) && rep.rolesChanged
        );
        if (coordsWithRolesChanged.length > 0) {
          let promises = [];
          coordsWithRolesChanged.forEach((coordinator) => {
            // eslint-disable-next-line no-unused-vars
            const { roles, id } = coordinator;
            promises.push(
              updateClientRepresentative({
                clientId,
                representativeId: id,
                payload: { roles: this.selectedUserRoles },
              })
            );
          });
          await Promise.all(promises);
        }
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    updateStudyFeatures: async function () {
      const { studyFeaturesConfig, clientId, id } = this.study;
      const { studyFeaturesConfig: storeFeatures } = this.selectedStudy;
      const languagesEdited = !equalArrays(
        storeFeatures.languages,
        studyFeaturesConfig.languages
      );

      if (this.hasAppropriateProtocols || languagesEdited) {
        let features = reduceKeys(storeFeatures, studyFeaturesConfig);

        if (languagesEdited) {
          features['languages'] = [...studyFeaturesConfig.languages];
        }

        if (hasKeysLength(features)) {
          const { languages, widgets, ...rest } = studyFeaturesConfig;
          const widgetsData =
            Object.keys(widgets).length === 0
              ? []
              : [
                  {
                    id: widgets[0]?.id,
                    replicationMode: 'DAILY',
                  },
                ];

          const params = {
            ...rest,
            widgets: widgetsData,
            languages: [...languages],
          };
          if (!studyFeaturesConfig.haveCustomParticipantCode) {
            delete params.customParticipantCode;
          }
          await updateFeatures(clientId, id, params);
        }
      }
    },
    languageNameFromCode(languageCode) {
      const filtered = this.languagesList.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },
    getLanguagesCodesFromSelection(languagesSelection) {
      return this.languagesList
        .filter((language) => languagesSelection[language.name])
        .map((language) => language.code);
    },
    async addCoordinatorFromNewUser(clientId, studyId, rep) {
      const { roles, ...info } = rep;
      info.clientId = clientId;
      const { data } = await createExpert(info);
      if (data.id) {
        await createCoordinator(clientId, studyId, {
          expertId: data.id,
          roles: roles.map((id) => ({ id })),
        });
      } else {
        console.log("expert id doesn't exist");
      }
    },
    async addCoordinatorFromExistingUser(clientId, id, coordinator) {
      const { id: expertId, roles } = coordinator;
      await createCoordinator(clientId, id, {
        expertId,
        roles: roles.map((id) => ({ id })),
      });
    },
  },
};
</script>

<style></style>
