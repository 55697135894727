<template>
  <div class="mt-4">
    <div v-if="displayAddProtocolButton" class="d-flex flex-row-reverse my-4">
      <base-button type="primary" rounded @click="addProtocol">
        {{ $t('protocol_new') }}
      </base-button>
    </div>
    <ProtocolList :filterData="filterProtocolsData" @goToDetail="goToDetail" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProtocolList from '@/components/Protocol/List/ProtocolList.vue';

export default {
  name: 'QuickProtocolListContainerView',
  components: { ProtocolList },
  computed: {
    ...mapState({
      client: ({ clients }) => clients.selectedClient,
      study: ({ studies }) => studies.selectedStudy,
    }),
    displayAddProtocolButton() {
      return this.canCreateProtocol && this.study?.status?.name === 'ACTIVE';
    },
    filterProtocolsData() {
      const { id: studyId } = this.study;
      return { studyId };
    },
  },
  created() {
    this.setBreadCrumbData({
      'client-protocols': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.client?.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          active: true,
        },
      ],
      'study-protocols': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },
        {
          text: this.study.name,
          active: true,
        },
      ],
    });
  },
  methods: {
    addProtocol() {
      this.$router.push({
        name: `${this.$route.meta.parent}-create-protocol`,
      });
    },
    goToDetail() {
      this.$router.push({ name: `${this.$route.meta.parent}-view-protocol` });
    },
  },
};
</script>
