<template>
  <div>
    <b-modal
      @change="closeModal"
      :visible="modalVisible"
      hide-header-close
      header-class="justify-content-center"
      hide-footer
      no-close-on-backdrop
      id="modal-screen-out"
      size="xl"
    >
      <template slot="modal-title">
        <h2>
          {{ $t('screen_out_participant') }}
        </h2></template
      >
      <div class="mt-3 ml-4">
        <label for="dropout_reason">{{
          $t('protocol_reject_enter_reason')
        }}</label>
        <b-form-textarea
          id="dropout_reason"
          :placeholder="this.$t('enter_a_reason')"
          style="padding-top: 5px"
          rows="2"
          v-model="screenOutReason"
        ></b-form-textarea>
      </div>

      <b-row class="mt-5 mb-5 ml-4">
        <b-col>
          <InfoPanel
            :inputNum="1"
            :title="this.$t('screenout_infobox_header')"
            :message="this.$t('screenout_infobox_detail')"
            :radioText="this.$t('i_understand_continue')"
            backgroundColor="#D15259"
            @check-confirm="checkConfirm"
          ></InfoPanel>
        </b-col>
      </b-row>

      <b-row class="mt-3 mb-4 ml-4">
        <base-button
          type="secondary"
          :disabled="disableCancelButton"
          @click="closeModal"
          >{{ $t('cancel') }}</base-button
        >
        <base-button
          type="primary"
          :disabled="disableScreenOutButton && !sendingDataToAPI"
          @click="screenOutCurrentParticipant"
          >{{ $t('screen_out_participant') }}</base-button
        >
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { screenOutParticipant } from '@/api/participant';
import { mapState } from 'vuex';
import InfoPanel from '@/components/InfoPanel.vue';
export default {
  name: 'ModalScreenOutParticipant',
  components: { InfoPanel },

  data() {
    return {
      acceptCheckbox: false,
      screenOutReason: '',
      sendingDataToAPI: false,
      disableCancelButton: false,
    };
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    warningColor: {
      type: String,
    },
    warningTitle: {
      type: String,
    },
    warningMessage: {
      type: String,
    },
    buttonText: {
      type: String,
    },
  },

  computed: {
    ...mapState({
      selectedParticipant: ({ participants }) => {
        const { clientId, studyId, protocolId, siteId, id } =
          participants.selectedParticipant;
        return { clientId, studyId, protocolId, siteId, id };
      },
    }),

    disableScreenOutButton() {
      return !(this.screenOutReason.length && this.acceptCheckbox);
    },
  },

  methods: {
    closeModal() {
      this.reasonText = '';
      this.$emit('close-modal');
    },

    checkConfirm(event) {
      !event.target.checked
        ? (this.acceptCheckbox = false)
        : (this.acceptCheckbox = true);
    },

    screenOutCurrentParticipant() {
      this.sendingDataToAPI = true;
      this.disableCancelButton = true;
      const reason = this.screenOutReason;
      const { clientId, studyId, protocolId, siteId, id } =
        this.selectedParticipant;
      screenOutParticipant({
        clientId,
        studyId,
        protocolId,
        siteId,
        id,
        reason,
      })
        .then((res) => {
          if (res.code === 201) {
            this.$emit('reload-data');
            this.$toast.info(this.$t('toast_participant_screenout'));
          }
        })
        .catch((err) => {
          this.$toast.error(
            this.$t(err?.data?.msg ?? this.$t('error_something_went_wrong'))
          );
        })
        .finally(() => {
          this.sendingDataToAPI = false;
          this.disableCancelButton = false;
          this.closeModal();
        });
    },
  },
};
</script>

<style scoped lang="scss">
#modal-screen-out {
  .modal-header {
    justify-content: center !important;
    & h5 {
      font-size: 24px; // TODO: turn to variable
    }
  }
  .modal-body {
    padding: 40px 58px 0;
  }
  .alert-danger {
    margin: 2rem 0 0;
    padding: 24px 45px;
    background-color: #d15259;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 30px;
    .info-icon {
      width: 53px;
      height: 53px;
    }
    .header {
      margin: 0 0 8px;
      font-size: 18px;
      font-weight: 800;
    }
    .custom-checkbox {
      padding-left: 40px;
      .custom-control-label {
        display: flex;
        height: 24px;
        align-items: center;
        margin-top: 0;
        font-size: 1rem;
        &:before,
        &:after {
          top: 0;
          left: -40px;
          width: 24px;
          height: 24px;
          background-color: transparent;
          border-radius: 50%;
          border-color: white;
        }
      }
    }
  }
  .modal-footer {
    padding: 40px 58px 2rem;
    justify-content: start;
    button {
      min-width: 250px;
      font-size: 1rem;
    }
    .btn-secondary {
      margin-right: 20px;
    }
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: #a1a8c0 !important;
    border-color: #e8e9ec !important;
  }
}
</style>
