<template>
  <WebUICard titleTextKey="relapses">
    <v-client-table
      :data="relapsesData"
      :columns="columns"
      :options="options"
      data-testid="relapses-table"
      striped
    >
      <template slot="reportedDate" slot-scope="props">
        {{ moment(props.row.reportedDate).format('DD/MM/YYYY') }}
      </template>
      <template slot="status" slot-scope="props">
        {{ props.row.scheduleStartedAt ? $t('scheduled') : $t('REPORTED') }}
      </template>
      <template slot="actions" slot-scope="props">
        <div
          class="d-flex flex-row align-items-center justify-content-end cursor-pointer"
          @click="goToRelapseDetails(props.row)"
        >
          <span data-testid="view-relapse-button" class="view-details">
            {{ $t('view') }}
          </span>
          <i class="fas fa-chevron-right ml-2"></i>
        </div>
      </template>
    </v-client-table>
  </WebUICard>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import WebUICard from '@/shared/components/WebUICard.vue';

export default {
  name: 'RelapseTableComponent.vue',
  components: { WebUICard },
  props: {
    relapsesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      relapses: [],
      relapseSchedule: {},
      participantChangesRecords: [],
      columns: ['reportedDate', 'displayId', 'status', 'actions'],
      options: {
        headings: {
          reportedDate: this.$t('date_report'),
          displayId: this.$t('relapse_id'),
          status: this.$t('status'),
          actions: '',
        },
        skin: 'table-striped ',
        sortable: ['reportedDate'],
      },
    };
  },
  computed: {
    ...mapGetters({ dropped: 'participants/isParticipantDroppedOut' }),
    ...mapState({
      participantParams: (state) => state.participants.participantParams,
      relapseId: (state) => state.relapses.list[0].displayId,
    }),
    defaultFromDate() {
      return this.getCorrectDateFormat(this.participant.dateStart);
    },
  },
  methods: {
    goToRelapseDetails(relapse) {
      this.$store.commit('relapses/SET_RELAPSE_PARAMS', {
        ...this.participantParams,
        relapseId: relapse.id,
      });
      this.$store.commit('relapses/SET_SELECTED_RELAPSE', relapse);
      this.$router.push({
        name:
          this.$route.name === 'view-participant'
            ? 'view-relapse'
            : `${this.$route.meta.parent}-view-relapse`,
        params: { id: relapse.id },
      });
    },
  },
};
</script>
