<template>
  <div>
    <b-row class="pt-3 pl-3 pr-3 pb-3">
      <b-col>
        <b-form-textarea
          id="textarea-auto-height"
          v-model="noteText"
          size="lg"
          rows="3"
          max-rows="8"
          :placeholder="this.$t('add_a_note') + '...'"
        ></b-form-textarea>
        <div
          v-if="showAddNoteButton"
          class="d-flex justify-content-between align-items-center"
        >
          <b-button pill variant="primary" @click="addParticipantNote">
            <span class="avenir-bold mt-3">{{ $t('add_note') }}</span>
          </b-button>
          <span class="noteText opacity-6">{{
            $t('notes_will_be_visible')
          }}</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'NoteCreateComponent',
  data() {
    return {
      noteText: '',
      showAddNoteButton: false,
    };
  },
  watch: {
    noteText: {
      handler(value) {
        this.showAddNoteButton = value.length > 3;
      },
    },
  },

  methods: {
    addParticipantNote() {
      this.$emit('note-data', this.noteText);
      this.noteText = '';
    },
  },
};
</script>

<style scoped lang="scss">
#textarea-auto-height {
  overflow-y: unset !important;
  border-bottom: none;
}

.noteText {
  font-size: 14px;
}
</style>
