<template>
  <WebUICard titleTextKey="notes">
    <div class="bred">
      <note-create-component
        v-if="displayCreateNote"
        @note-data="postNoteData"
      ></note-create-component>
      <HorizontalDivider
        v-if="
          participantNotes && participantNotes.length > 0 && displayCreateNote
        "
        colorLine="e8e9ec"
        colsWidth="12"
      ></HorizontalDivider>
      <note-display-component
        :participantNoteList="participantNotes"
      ></note-display-component>
    </div>
  </WebUICard>
</template>

<script>
import NoteCreateComponent from '@/components/Participants/NoteWidgetComponent/NoteCreateComponent.vue';
import NoteDisplayComponent from '@/components/Participants/NoteWidgetComponent/NoteDisplayComponent.vue';
import { mapState } from 'vuex';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import WebUICard from '@/shared/components/WebUICard.vue';
export default {
  name: 'NoteWidgetComponent',
  props: {
    displayCreateNote: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NoteCreateComponent,
    NoteDisplayComponent,
    HorizontalDivider,
    WebUICard,
  },
  computed: {
    ...mapState({
      participantNotes: (state) => state.participants.participantNotes,
      selectedParticipant: ({ participants }) => {
        const { clientId, studyId, protocolId, siteId, id } =
          participants.selectedParticipant;
        return { clientId, studyId, protocolId, siteId, id };
      },
    }),
  },
  created() {
    this.getParticipantNotes();
  },
  methods: {
    getParticipantNotes() {
      const { clientId, studyId, protocolId, siteId, id } =
        this.selectedParticipant;
      const payload = { clientId, studyId, protocolId, siteId, id };
      this.$store.dispatch('participants/getParticipantNote', payload);
    },
    postNoteData(data) {
      let payload = {
        ...this.selectedParticipant,
      };
      payload = { ...payload, textNote: data };
      this.$store
        .dispatch('participants/sendParticipantNote', payload)
        .then((resp) => {
          if (resp.code === 200) {
            this.getParticipantNotes();
            this.$toast.info(this.$t('toast_note_added'));
          } else {
            this.$toast.error(this.$t('error_something_went_wrong'));
          }
        });
    },
  },
};
</script>
