<template>
  <div>
    <WebUICard titleTextKey="update_participant_status">
      <b-row class="pt-5 pb-5 justify-content-center">
        <b-col md="5">
          <b-button
            pill
            variant="outline-primary"
            class="w-100"
            @click="openActivateParticipantModal"
          >
            <span class="avenir-bold">{{ $t('activate_participant') }}</span>
          </b-button></b-col
        >
        <b-col md="1" class="d-inline-flex align-items-center">{{
          $t('or')
        }}</b-col>
        <b-col md="5">
          <b-button
            pill
            variant="outline-danger"
            class="w-100"
            @click="openScreenOutParticipantModal"
          >
            <span class="avenir-bold">{{ $t('screen_out_participant') }}</span>
          </b-button></b-col
        >
      </b-row>
    </WebUICard>
    <ModalActivateParticipant
      :modal-visible="showActivateParticipantModal"
      @close-modal="showActivateParticipantModal = false"
      :modalTitle="$t('activate_participant')"
      @activate-components="activateParticipantView"
    ></ModalActivateParticipant>
    <ModalScreenOutParticipant
      :modal-visible="showScreenOutParticipantModal"
      @close-modal="showScreenOutParticipantModal = false"
      :modalTitle="$t('screen_out_participant')"
      @reload-data="reloadParticipantData"
    ></ModalScreenOutParticipant>
  </div>
</template>

<script>
import WebUICard from '@/shared/components/WebUICard.vue';
import ModalScreenOutParticipant from '@/components/Participants/ModalScreenOutParticipant.vue';
import ModalActivateParticipant from '@/components/Participants/ModalActivateParticipant.vue';
export default {
  name: 'DraftUpdateParticipantStatusComponent.vue',
  components: {
    WebUICard,
    ModalActivateParticipant,
    ModalScreenOutParticipant,
  },

  data() {
    return {
      showActivateParticipantModal: false,
      showScreenOutParticipantModal: false,
    };
  },
  props: {
    info: Object,
  },

  methods: {
    openScreenOutParticipantModal() {
      this.showScreenOutParticipantModal = true;
    },
    openActivateParticipantModal() {
      this.showActivateParticipantModal = true;
    },
    reloadParticipantData() {
      this.$emit('reload-parent');
    },
    activateParticipantView() {
      this.$emit('activate-parent');
    },
  },
};
</script>

<style scoped lang="scss"></style>
