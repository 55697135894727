<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27842 12.6571L6.4145 10.7254C6.31415 10.6212 6.1779 10.5626 6.03581 10.5626C5.89372 10.5626 5.75747 10.6212 5.65712 10.7254C5.44763 10.9425 5.44763 11.2932 5.65712 11.5103L7.90242 13.8372C8.11191 14.0543 8.45031 14.0543 8.6598 13.8372L14.3429 7.94771C14.5524 7.73061 14.5524 7.37991 14.3429 7.16282C14.2425 7.05858 14.1063 7 13.9642 7C13.8221 7 13.6859 7.05858 13.5855 7.16282L8.27842 12.6571Z"
      fill="#4DC185"
    />
    <circle cx="10" cy="10" r="7.5" stroke="#4DC185" />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
