<template>
  <div class="mt-4">
    <div v-if="displayAddSiteButton" class="d-flex flex-row-reverse my-4">
      <base-button type="primary" rounded @click="addSite">
        {{ $t('site_new') }}
      </base-button>
    </div>
    <SitesList
      v-if="canReadSite"
      :filterData="filterSitesData"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SitesList from '@/components/Sites/List/SitesList.vue';

export default {
  name: 'QuickSiteListContainerView',
  components: { SitesList },
  computed: {
    ...mapState({
      client: ({ clients }) => clients.selectedClient,
      study: ({ studies }) => studies.selectedStudy,
      protocol: ({ protocols }) => protocols.selectedProtocol,
      protocols: ({ protocols }) => protocols.protocols,
    }),
    displayAddSiteButton() {
      const routeName = this.$route?.name ?? '';
      return (
        this.canCreateSite &&
        this.studyIsActive(routeName) &&
        this.studyHasAppropriateProtocol(routeName)
      );
    },
    filterSitesData() {
      switch (this.$route.name) {
        case 'client-sites':
        case 'study-sites':
          return { studyId: this.study.id };
        case 'client-protocol-sites':
        case 'study-protocol-sites':
        case 'protocol-sites':
          return { protocolId: this.protocol.id };
        default:
          return undefined;
      }
    },
    isProtocolView() {
      return this.$route.name.includes('protocol-sites');
    },
  },
  created() {
    this.setIsProtocolView(this.isProtocolView);
    this.setBreadCrumbData({
      'client-sites': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.client.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          active: true,
        },
      ],
      'client-protocol-sites': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.client.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.protocol?.name,
          active: true,
        },
      ],
      'study-sites': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          active: true,
        },
      ],
      'study-protocol-sites': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.protocol?.name,
          active: true,
        },
      ],
      'protocol-sites': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },
        {
          text: this.protocol?.name,
          active: true,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      setIsProtocolView: 'sites/setIsProtocolView',
    }),
    goToDetail() {
      this.$router.push({
        name: `${this.$route.meta.parent}-view-site`,
      });
    },
    addSite() {
      this.$router.push({
        name: `${this.$route.meta.parent}-create-site`,
        params: { isProtocolView: this.isProtocolView },
      });
    },
    studyIsActive() {
      const activeString = 'ACTIVE';
      return this.isProtocolView
        ? this.protocol.study.status?.name === activeString
        : this.study?.status?.name === activeString;
    },
    studyHasAppropriateProtocol() {
      const protocols = this.isProtocolView ? [this.protocol] : this.protocols;

      if (protocols.length === 0) return false;

      return protocols.some((protocol) =>
        ['ACTIVE', 'WAITING FOR APPROVAL'].includes(protocol?.status?.name)
      );
    },
  },
};
</script>
