<template>
  <div>
    <b-row>
      <b-col>
        <h2>{{ $t('schedule_tailor') }}</h2>
        <p class="text-grey">{{ $t('schedule_tailor_detail') }}</p>
      </b-col>
    </b-row>

    <div
      class="accordion partners-title"
      role="tablist"
      v-for="(partner, index) in testPartnersObject"
      :key="index"
    >
      <b-row>
        <b-col cols="4">
          <h3 class="pt-3 pb-3" v-b-toggle="`collapse-${partner.id}`">
            <img
              src="@/assets/icons/plus-icon.svg"
              alt="expand-accordion"
              class="when-closed mr-2"
            />
            <img
              src="@/assets/icons/close-icon.svg"
              alt="expand-accordion"
              class="when-opened mr-2"
            />
            {{ partner.name }}
          </h3>
        </b-col>
      </b-row>
      <b-collapse :id="`collapse-${partner.id}`" class="mt-2 ml-4">
        <b-row v-for="(category, i) in partner.categories" :key="i">
          <b-col cols="4">
            <h4
              v-b-toggle="`collapse-${category.id}${i}-inner`"
              size="sm"
              class="child-toggle"
            >
              <img
                src="@/assets/icons/plus-icon.svg"
                alt="expand-accordion"
                class="when-closed mr-2"
              />
              <img
                src="@/assets/icons/close-icon.svg"
                alt="expand-accordion"
                class="when-opened mr-2"
              />
              {{ $t(category.name) }}
            </h4>
          </b-col>
          <b-col v-if="allowSelected" cols="4"
            ><p class="text-blue">
              <small
                >{{
                  enabledNumberOfTests(
                    category,
                    partner.name,
                    selected,
                    partner.id
                  )
                }}
                {{ $t('/') }}
                {{ totalNumberOfTests(category.tests) }}
                {{ $t('enabled') }}</small
              >
            </p></b-col
          >
          <b-col v-if="allowSelected" cols="4" class="text-blue clickable">
            <div
              :ref="`${partner.id}${category.name}`"
              :id="`${partner.id}${category.name}`"
            >
              <small
                @click="
                  disableAllCategoryTests(
                    category,
                    partner,
                    enabledNumberOfTests(
                      category,
                      partner.name,
                      selected,
                      partner.id
                    )
                  )
                "
                ><u>{{
                  enabledNumberOfTests(
                    category,
                    partner.name,
                    selected,
                    partner.id
                  ) > 0
                    ? $t('disable_all_tasks_from_this_category')
                    : $t('enable_all_tasks_from_this_category')
                }}</u></small
              >
            </div>
          </b-col>
          <b-row v-if="allowSelected">
            <b-col class="mb-3 tasks-list">
              <b-collapse
                v-for="test in category.tests"
                :key="test.id"
                :id="`collapse-${category.id}${i}-inner`"
              >
                <b-row class="ml-5 mb-3">
                  <b-form-checkbox
                    name="check-button-step2"
                    switch
                    :disabled="!test.availableForSelectedLanguage"
                    :class="
                      !test.availableForSelectedLanguage
                        ? 'checkboxDisabled'
                        : 'checkboxEnabled'
                    "
                    v-model="selected[partner.name][test.id].isEnabled"
                  >
                    <span class="d-block disabled-text">{{
                      $t(test.name)
                    }}</span>
                    <p
                      v-if="!test.availableForSelectedLanguage"
                      class="warning-language-text"
                    >
                      <check-ko height="15px" />{{
                        $t('partipant_task_lang_na')
                      }}
                    </p>
                  </b-form-checkbox>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
        </b-row>
      </b-collapse>
    </div>

    <b-row class="my-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          :disabled="disableButton"
          type="primary"
          @click="$emit('nextStep', selected)"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CheckKo from '@/components/Icons/CheckKo.vue';

export default {
  components: { CheckKo },

  props: {
    rochePartner: Object,
    participant: Object,
  },

  data() {
    return {
      allowSelected: false,
      isOpen: false,
      disableButton: false,
      totalNum: 0,
      selected: {},
      addedByClientUser: this.$route.name === 'add-participant',
    };
  },
  computed: {
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      testPartnersObject: ({ resources }) => resources.tests,
    }),
  },
  mounted() {
    this.fetchMovementTests();
  },
  watch: {
    rochePartner: {
      handler() {
        this.fetchMovementTests();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.allowNextStep();
      },
      deep: true,
    },
  },

  methods: {
    allowNextStep() {
      let testsListByPartner = Object.values(this.selected);
      this.totalNum = 0;
      for (let i = 0; i < testsListByPartner.length; i++) {
        const element = Object.values(testsListByPartner[i]);
        let count = element.filter((obj) => obj.isEnabled === true).length;
        this.totalNum += count;
      }
      this.totalNum === 0
        ? (this.disableButton = true)
        : (this.disableButton = false);
    },

    disableAllCategoryTests(category, partner, enabledNumberOfTests) {
      let elements = Object.keys(category.tests).length;
      let elementNum = [];

      for (let index = 0; index < elements; index++) {
        let value = category.tests[index].id;
        elementNum.push(value);
      }

      for (let index = 0; index < elements; index++) {
        this.selected[partner.name][elementNum[index]].isEnabled =
          enabledNumberOfTests === 0;
        if (
          this.selected[partner.name][elementNum[index]]
            .availableForSelectedLanguage === false
        ) {
          this.selected[partner.name][elementNum[index]].isEnabled = false;
        }
      }

      this.allowNextStep();
    },

    totalNumberOfTests(category) {
      return Object.keys(category).length;
    },

    enabledNumberOfTests(category, partner, selected) {
      let testName = category.tests.map(({ name }) => name);
      let testsListByPartner = Object.values(selected[partner]);
      let numbers = 0;
      for (let index = 0; index < testName.length; index++) {
        let findEnabledTest = testsListByPartner.find(
          (e) => e.name === testName[index]
        )?.isEnabled;
        if (findEnabledTest) {
          numbers += 1;
        }
      }

      return numbers;
    },

    refactorMovementsList(res) {
      let obj = {};
      let defaultValues = {};

      res.forEach((partner) => {
        obj[partner.name] = [];
        partner.categories.forEach((category) => {
          if (category.name) {
            obj[partner.name].push(...category.tests);
            category.tests.forEach((test) => {
              defaultValues[partner.name] = {
                ...defaultValues[partner.name],
                [test.id]: {
                  name: test.name,
                  isEnabled: test.availableForSelectedLanguage,
                  versionId: test.versions[0].id,
                  languages: test.languages,
                  availableForSelectedLanguage:
                    test.availableForSelectedLanguage,
                },
              };
            });
          }
        });
      });
      this.selected = defaultValues;
      this.allowSelected = true;
    },

    async fetchMovementTests() {
      try {
        this.$store
          .dispatch('resources/dispatchTestGames', {
            language: this.participant.language,
            'filter[protocol]': this.addedByClientUser
              ? this.siteProtocol.id
              : this.site.basic.protocolId,
            'filter[exclude]': !this?.rochePartner?.isEnabled ? 'roche' : null,
            'filter[game_mode]': true,
          })
          .then(() => {
            const data = this.$store.state.resources.tests;
            this.refactorMovementsList(data);
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}

.partners-title {
  border-bottom: 1px solid #c0c4cc;
}

.text-blue {
  color: #046db3;
}

.text-grey {
  color: #a1a8c0;
}

.tasks-list {
  display: grid;
  grid-template-rows: repeat(5, min-content);
  grid-auto-flow: column;
  column-gap: 35px;
}

.child-toggle {
  img {
    width: 11px;
  }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.warning-language-text {
  color: #f89a9a;
  font-size: 10px;
  font-weight: normal !important;
  position: relative;
  top: -7px;
}

.checkbox-content {
  display: inline-block;
}
.checkbox-content-absolut {
  position: relative;
  right: -31px;
  top: -33px;
}
.custom-checkbox {
  margin-top: 8px;
  .custom-control-inline {
    margin-right: -15px !important;
  }
}
.custom-checkbox-warning {
  height: 50px;
  margin-top: 8px;
}
.checkboxEnabled {
  span {
    font-size: 15px;
  }
}
.checkboxDisabled {
  ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #e9ecef !important;
  }
  ::v-deep .custom-control-label::before {
    border: 1px solid #e9ecef !important;
  }
}

.checkboxDisabled {
  .disabled-text {
    color: #c7cbd9;
    font-size: 15px;
    position: relative;
    top: -10px;
  }

  ::v-deep .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: #ffffff !important;
    border: 1px solid #e9ecef !important;
  }
}
</style>
