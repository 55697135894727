<template>
  <div>
    <h2 class="mb-5" v-if="!isView">{{ $t('overview') }}</h2>

    <study-information v-if="isSiteDetailsView && !loadingStudy" />

    <div class="overview-card">
      <p class="text-lg">{{ $t('protocol_information') }}</p>
      <b-spinner v-if="loadingProtocol" small class="text-center"></b-spinner>
      <b-row v-else>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6"> {{ $t('protocol_name') }} </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">
              {{
                isView || isEditRoute ? siteProtocol.name : protocol.name
              }}</b-col
            >
          </b-row>
        </b-col>
        <b-col cols="3" v-if="selectedProtocolApprover">
          <b-row>
            <b-col class="opacity-6">{{ $t('protocol_approver') }} </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ selectedProtocolApprover }}</b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6">
              {{ $t('protocol_study_duration') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              isView || isEditRoute
                ? siteProtocol.studyDurationPerParticipant
                : protocol.studyDurationPerParticipant
            }}</b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="opacity-6">
              {{ $t('protocol_planned_num_participants') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              isView || isEditRoute
                ? siteProtocol.plannedNumberOfParticipants
                : protocol.plannedNumberOfParticipants
            }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <p class="opacity-6 mb-2 mt-4">
            {{ $t('description') }}
          </p>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              isView || isEdit ? siteProtocol.description : protocol.description
            }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="text-lg">{{ $t('site_information') }}</p>
      <template v-if="site.basic">
        <div class="opacity-6 mt-4 mb-2">{{ $t('site_name') }}</div>
        <div class="avenir-bold">{{ site.basic.name }}</div>

        <div class="opacity-6 mt-4 mb-2">{{ $t('languages') }}</div>
        <p
          v-for="(languageCode, index) in site.basic.languages"
          :key="index"
          class="avenir-bold mb-1"
        >
          <i class="ni ni-check-bold text-primary"></i>
          {{ languageNameFromCode(languageCode) }} -
          {{ languageCode.toUpperCase() }}
        </p>

        <div class="opacity-6 mt-4 mb-2">{{ $t('description') }}</div>
        <div class="avenir-bold">{{ site.basic.description }}</div>
      </template>
    </div>

    <div class="overview-card">
      <p class="text-lg">{{ $t('site_address') }}</p>
      <address-overview :contact="site.siteAddress" />
    </div>

    <b-row class="my-5" v-if="showCreateButtons">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          :disabled="addingSite"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          :disabled="addingSite || !isUpdated"
          type="primary"
          @click="$emit(isEditRoute ? 'updateSite' : 'createSite')"
          data-testid="submit-button"
        >
          {{ !isEditRoute ? $t('site_create') : $t('site_update') }}
          <b-spinner small v-if="addingSite"></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AddressOverview from '../Overviews/Address.vue';
import StudyInformation from '../Overviews/StudyInformation.vue';
import { languagesDefault } from '@/constants/languages-constants';

export default {
  components: { StudyInformation, AddressOverview },
  data() {
    return {
      showCreateButtons: false,
      isView: false,
      isEdit: false,
      isProtocolRoute: false,
      loadingStudy: true,
      loadingProtocol: true,
      isSiteDetailsView: this.$route.name === 'view-site',
      selectedProtocolApprover: '',
    };
  },
  props: {
    site: Object,
    addingSite: Boolean,
    isProtocol: Boolean,
    isUpdated: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      countries: (state) => state.resources.countries,
      study: (state) => state.studies.selectedStudy,
      protocol: (state) => state.protocols.selectedProtocol,
      protocolApprover: (state) => state.protocols.selectedApprover,
      siteParams: (state) => state.sites.siteParams,
      siteProtocol: (state) => state.sites.siteProtocol,
    }),
    ...mapGetters({ options: 'protocols/protocolOptions' }),
    country() {
      return (
        this.countries.find(
          (country) => country.id === this.site.siteAddress.countryId
        ).shortName || ''
      );
    },
    getRouteName() {
      return this.$route.name;
    },
    isEditRoute() {
      return this.$route.name.includes('edit');
    },
  },
  created() {
    this.setRouteName();
    this.fetchProtocolDetails();
    this.fetchStudyDetails();
    this.setProtocolApprover();
  },
  methods: {
    ...mapActions({
      getProtocolDetails: 'sites/getProtocolDetails',
      getStudyDetails: 'studies/getStudyDetails',
    }),
    setRouteName() {
      if (
        this.getRouteName === 'protocol-create-site' ||
        this.getRouteName === 'protocol-edit-site' ||
        this.getRouteName === 'protocol-view-site'
      ) {
        this.isProtocolRoute = true;
      }
      if (
        this.getRouteName === 'client-view-site' ||
        this.getRouteName === 'study-view-site' ||
        this.getRouteName === 'protocol-view-site' ||
        this.getRouteName === 'view-site'
      ) {
        this.isView = true;
      }
      if (
        this.getRouteName !== 'client-view-site' &&
        this.getRouteName !== 'study-view-site' &&
        this.getRouteName !== 'protocol-view-site' &&
        this.getRouteName !== 'view-site'
      ) {
        this.showCreateButtons = true;
      }
      if (
        this.getRouteName === 'protocol-edit-site' ||
        this.getRouteName === 'edit-site'
      ) {
        this.isEdit = true;
      }
    },
    async fetchProtocolDetails() {
      this.loadingProtocol = true;
      this.$store.commit('sites/SHOW_SITE_BUTTONS', false);

      try {
        const { clientId, studyId, protocolId } = this.siteParams;
        await this.getProtocolDetails({
          clientId,
          studyId,
          protocolId,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingProtocol = false;
      }
    },
    async fetchStudyDetails() {
      if (this.isSiteDetailsView) {
        this.loadingStudy = true;

        try {
          const payload = {
            clientId: this.siteParams.clientId,
            studyId: this.siteParams.studyId,
          };
          await this.getStudyDetails(payload);
        } catch (error) {
          console.log(error);
        } finally {
          this.loadingStudy = false;
        }
      }
    },
    setProtocolApprover() {
      if (this.protocolApprover?.user) {
        this.selectedProtocolApprover = `${this.protocolApprover.user.firstName} ${this.protocolApprover.user.lastName}`;
      } else if (this.siteProtocol?.user) {
        this.selectedProtocolApprover = `${this.siteProtocol.approver.user.firstName} ${this.siteProtocol.approver.user.lastName}`;
      }
    },
    languageNameFromCode(languageCode) {
      const filtered = languagesDefault.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },
  },
};
</script>

<style></style>
