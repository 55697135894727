<template>
  <div
    class="text-center"
    v-if="
      (isEdit && !client.representatives.length) ||
      (isView && !representatives.length)
    "
  >
    <b-spinner></b-spinner>
  </div>
  <div v-else>
    <h2 class="mb-3" v-if="!isView">{{ $t('overview') }}</h2>
    <div class="overview-card">
      <p class="text-lg">{{ $t('client_information') }}</p>
      <b-row>
        <b-col>
          <b-row>
            <b-col class="opacity-6">
              {{ $t('client_name_legal') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ client.basic.legalName }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="opacity-6">
              {{ $t('client_type') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ $t(this.clientTypeText) }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="opacity-6">
              {{ $t('program_manager') }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              class="avenir-bold"
              v-html="
                managers.find(
                  (manager) => manager.id === client.basic.programManagerId
                ).label
              "
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
      <p class="opacity-6 mt-4">
        {{ $t('description') }}
      </p>
      <div class="row">
        <p class="avenir-bold col">{{ client.basic.description }}</p>
      </div>
    </div>

    <div v-if="isEdit">
      <client-assigned-users-table
        :sectionTitle="this.$t('client_representatives')"
        :representatives="
          editingClientRepresentatives.length
            ? editingClientRepresentatives
            : client.representatives
        "
      />
    </div>
    <div v-else>
      <client-assigned-users-table
        :sectionTitle="this.$t('client_representatives')"
        :representatives="isView ? representatives : client.representatives"
      />
    </div>

    <div class="overview-card">
      <p class="text-lg">{{ $t('client_address_official_contact') }}</p>
      <b-row>
        <b-col cols="3">
          <div class="opacity-6">
            {{ $t('website') }}
          </div>

          <div class="avenir-bold text-break">
            {{ client.contact.website }}
          </div>
        </b-col>
        <b-col cols="3">
          <div class="opacity-6">
            {{ $t('email') }}
          </div>

          <div class="avenir-bold text-break">
            {{ client.contact.email }}
          </div>
        </b-col>
        <b-col cols="3">
          <div class="opacity-6">
            {{ $t('phone') }}
          </div>

          <div class="avenir-bold">{{ client.contact.phone }}</div>
        </b-col>
      </b-row>

      <address-overview :contact="client.contact" />
    </div>

    <div class="overview-card" v-if="!isInvoiceEmpty">
      <p class="text-lg">
        {{ $t('client_address_invoice') }}
      </p>
      <address-overview :contact="client.invoice" />
    </div>
    <b-row class="my-5" v-if="!isView">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          :disabled="loading"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="
            $route.name === 'create-client' ? postClient() : updateClient()
          "
          :disabled="loading"
          data-testid="submit-button"
          >{{
            $route.name === 'create-client' ? 'Create Client' : 'Update Client'
          }}
          <b-spinner v-if="loading" small></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AddressOverview from '@/components/Overviews/Address';
import ClientAssignedUsersTable from '@/components/Overviews/AssignedUsersTableClient.vue';
import {
  createClient,
  updateClientBasicInfo,
  createRepresentative,
  updateClientContact,
  deleteClientRepresentative,
  updateClientRepresentative,
} from '@/api/client';
import { postContact } from '@/api/contacts';
import { createExpert, updateExpert } from '@/api/experts';
import { reduceKeys, hasKeysLength } from '@/utils';
import { mapState } from 'vuex';
import { fetchExperts } from '@/api/experts';

export default {
  components: {
    AddressOverview,
    ClientAssignedUsersTable,
  },
  data() {
    return {
      loading: false,
      isView: this.$route.name === 'client-details',
      isEdit: this.$route.name.includes('edit'),
      clientTypeText: '',
      expertStatus: '',
      representatives: [],
      editingClientRepresentatives: [],
    };
  },
  props: {
    client: Object,
    delete: Array,
  },
  created() {
    this.getClientType();
    this.addStatusToRepresentatives();
  },
  beforeDestroy() {
    this.$store.commit('experts/UPDATING_REPRESENTATIVE_DATA', []);
  },
  computed: {
    ...mapState({
      countries: (state) => state.resources.countries,
      managers: (state) => state.experts.managers,
      clientTypes: (state) => state.resources.clientTypes,
      selectedClient: (state) => state.clients.selectedClient,
    }),
    contactCountry() {
      return this.countries.find(
        (country) => country.id === this.client.contact.countryId
      ).shortName;
    },
    invoiceCountry() {
      return this.countries.find(
        (country) => country.id === this.client.invoice.countryId
      ).shortName;
    },
    isInvoiceEmpty() {
      const { address, city, countryId, postalCode } = this.client.invoice;
      return !address && !city && !countryId && !postalCode;
    },
  },
  methods: {
    async postClient() {
      this.loading = true;
      try {
        const basic = { ...this.client.basic };
        if (!basic.description.trim()) delete basic.description;

        const { data } = await createClient(basic);
        data.id
          ? await this.completeClientSubmit(data.id)
          : console.log("client id doesn't exist");
      } catch (error) {
        this.displayError('Error create client', error);
      } finally {
        this.loading = false;
      }
    },
    async completeClientSubmit(clientId) {
      this.client.representatives.forEach((rep) =>
        this.addRepresentativeFromNewUser(clientId, rep)
      );
      try {
        await Promise.all([
          postContact(clientId, this.client.contact),
          postContact(clientId, this.client.invoice),
        ]);
        this.$toast.info(this.$t('toast_client_new_created'));
        this.$router.push({ name: 'clients' });
      } catch (error) {
        this.displayError('Error complete client create', error);
      }
    },
    async updateClient() {
      this.loading = true;

      try {
        await this.updateRepresentatives();

        const promises = [];
        const clientId = this.$route.params.id;
        /**
         * Check if any key value has changed in
         * basic object with store object
         */
        const basic = reduceKeys(this.selectedClient.basic, this.client.basic);

        let { description } = this.client.basic;
        description =
          description && !description.trim() // return null if there are empty spaces "   " or emypt string""
            ? null
            : !description // if the description is null keep it null, otherwise return the description
            ? null
            : description;

        hasKeysLength(basic) &&
          promises.push(
            updateClientBasicInfo(clientId, {
              ...this.client.basic,
              description,
            })
          );

        /**
         * Check if any key has changed in
         * contact object with store object
         */
        const contact = reduceKeys(
          this.selectedClient.contact,
          this.client.contact
        );
        hasKeysLength(contact) &&
          promises.push(
            updateClientContact(
              clientId,
              this.selectedClient.contact.id,
              this.client.contact
            )
          );

        /**
         * Check if any key has changed in
         * invoice object with store object
         */

        const invoice = reduceKeys(
          this.selectedClient.invoice,
          this.client.invoice
        );
        hasKeysLength(invoice) &&
          promises.push(
            updateClientContact(
              clientId,
              this.selectedClient.invoice.id,
              this.client.invoice
            )
          );

        /**
         * make the request here to the backend
         */
        await Promise.all(promises);
        this.$router.push({ name: 'client-details' });
        this.$toast.info(this.$t('toast_client_updated'));
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.loading = false;
      }
    },
    async updateRepresentatives() {
      const clientId = this.$route.params.id;
      const { representatives } = this.client;

      const newUsersToAdd = representatives.filter(
        (representative) => representative.type === 'new'
      );
      const existingUsersToAdd = representatives.filter(
        (representative) => representative.type === 'existing'
      );
      const repsWithFieldsChanged = representatives.filter(
        (rep) => !['new'].includes(rep.type) && rep.fieldsChanged
      );
      const repsWithRolesChanged = representatives.filter(
        (rep) => !['new', 'existing'].includes(rep.type) && rep.rolesChanged
      );

      await this.deleteRepresentatives(clientId);
      await this.addNewRepresentatives(newUsersToAdd, clientId);
      await this.addExistingRepresentatives(existingUsersToAdd, clientId);
      await this.updateRepresentativesExpert(repsWithFieldsChanged);
      await this.updateRepresentativesRoles(repsWithRolesChanged, clientId);
    },
    async addNewRepresentatives(newUsersToAdd, clientId) {
      let promises = [];
      newUsersToAdd.forEach((user) => {
        promises.push(this.addRepresentativeFromNewUser(clientId, user));
      });
      await Promise.all(promises);
    },
    async addExistingRepresentatives(existingUsersToAdd, clientId) {
      let promises = [];
      existingUsersToAdd.forEach((representative) => {
        const { id: expertId, roles } = representative;
        promises.push(
          createRepresentative(clientId, {
            expertId,
            roles: roles.map((id) => ({ id })),
          })
        );
      });
      await Promise.all(promises);
    },
    async updateRepresentativesExpert(repsWithFieldsChanged) {
      let promises = [];
      repsWithFieldsChanged.forEach((representative) => {
        // eslint-disable-next-line no-unused-vars
        const { expertId, roles, fieldsChanged, rolesChanged, ...user } =
          representative;
        promises.push(updateExpert(expertId, user));
      });
      await Promise.all(promises);
    },
    async updateRepresentativesRoles(repsWithRolesChanged, clientId) {
      let promises = [];
      repsWithRolesChanged.forEach((representative) => {
        // eslint-disable-next-line no-unused-vars
        const { roles, id } = representative;
        promises.push(
          updateClientRepresentative({
            clientId,
            representativeId: id,
            payload: { roles: this.selectedUserRoles },
          })
        );
      });
      await Promise.all(promises);
    },
    async deleteRepresentatives(clientId) {
      let promises = [];
      this.delete.forEach((representativeId) => {
        promises.push(
          deleteClientRepresentative({ clientId, representativeId })
        );
      });
      await Promise.all(promises);
    },
    async addStatusToRepresentatives() {
      const { data } = await fetchExperts({
        'filter[client_id]': this.client.basic.id,
      });
      const experts = data.map((expert) => ({
        id: expert.id,
        status: expert.user?.status,
      }));

      this.representatives = this.client.representatives.map(
        (representative) => {
          const expert = experts.find(
            (expert) => expert.id === representative.expertId
          );
          return {
            ...representative,
            status: expert?.status,
          };
        }
      );
    },
    getClientType() {
      this.clientTypeText = this.clientTypes.find(
        (type) => type.id === this.client.basic.clientTypeId
      ).name;
    },
    async addRepresentativeFromNewUser(clientId, rep) {
      try {
        const { roles, ...info } = rep;
        info.clientId = clientId;
        const {
          data: { id: expertId },
        } = await createExpert(info);

        expertId
          ? await createRepresentative(clientId, {
              expertId,
              roles: roles.map((id) => ({ id })),
            })
          : console.log('expert id does not exist');
      } catch (error) {
        this.displayError('Error add representative', error);
      }
    },
  },
};
</script>

<style></style>
