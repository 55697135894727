<template>
  <div>
    <h2 class="mb-3">{{ $t('overview') }}</h2>
    <div class="overview-card">
      <p class="mt-2 mb-4">{{ $t('study_information') }}</p>
      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('study') }}</span>
          <p class="avenir-bold mt-2">
            {{ selectedParticipant.studyName }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('protocol') }}</span>
          <p class="avenir-bold mt-2">
            {{ selectedParticipant.protocolName }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('site') }}</span>
          <p class="avenir-bold mt-2">
            {{ selectedParticipant.siteName }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('study_start_date')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ moment(selectedParticipant.dateStart).format('DD/MM/YYYY') }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('study_end_date_planned')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ moment(selectedParticipant.endDate).format('DD/MM/YYYY') }}
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="mt-2 mb-4">{{ $t('participant_information') }}</p>
      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('phone_number_mobile')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ selectedParticipant.phone }}
          </p>
        </b-col>

        <b-col cols="4">
          <div v-if="updatedParticipantData.subjectCode">
            <span class="editing mr-2"
              >{{ $t('participant_id_subject') }}
            </span>
            <edit-icon class="align-self-stretch"></edit-icon>
            <p class="mt-2">
              <span class="opacity-6"
                ><s>{{ selectedParticipant.subjectCode }}</s></span
              >
              <arrow-right
                svgColor="#565F77"
                class="align-self-stretch"
              ></arrow-right>
              <span class="avenir-bold">
                {{ updatedParticipantData.subjectCode }}
              </span>
            </p>
          </div>
          <div v-else>
            <span class="opacity-6 normal-label">{{
              $t('participant_id_subject')
            }}</span>
            <p class="avenir-bold mt-2">
              {{ selectedParticipant.subjectCode }}
            </p>
          </div>
        </b-col>

        <b-col cols="4">
          <div
            v-if="
              checkType(
                selectedParticipant.tags,
                updatedParticipantData.tags[0]
              )
            "
          >
            <span class="opacity-6 normal-label">{{ $t('type') }}</span>
            <p class="avenir-bold mt-2">
              {{
                selectedParticipant.tags[0] === 'healthy_control'
                  ? $t('healthy_control')
                  : $t('patient')
              }}
            </p>
          </div>
          <div v-else>
            <span class="editing mr-2">{{ $t('type') }} </span>
            <edit-icon class="align-self-stretch"></edit-icon>
            <p class="mt-2">
              <span class="opacity-6"
                ><s
                  >{{
                    selectedParticipant.tags[0] === 'healthy_control'
                      ? $t('healthy_control')
                      : $t('patient')
                  }}
                </s></span
              >
              <arrow-right
                svgColor="#565F77"
                class="align-self-stretch"
              ></arrow-right>
              <span class="avenir-bold">
                {{
                  updatedParticipantData.tags[0] === 'healthy_control'
                    ? $t('healthy_control')
                    : $t('patient')
                }}</span
              >
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <div
            v-if="
              updatedParticipantData.gender === selectedParticipant.gender.id ||
              updatedParticipantData.gender === null
            "
          >
            <span class="opacity-6 normal-label">{{ $t('gender') }}</span>
            <p class="avenir-bold mt-2">
              {{
                selectedParticipant.gender.id === 1
                  ? $t('gender_male')
                  : $t('gender_female')
              }}
            </p>
          </div>
          <div v-else>
            <span class="editing mr-2">{{ $t('gender') }} </span>
            <edit-icon class="align-self-stretch"></edit-icon>
            <p class="mt-2">
              <span class="opacity-6"
                ><s
                  >{{
                    selectedParticipant.gender.id === 1
                      ? $t('gender_male')
                      : $t('gender_female')
                  }}
                </s></span
              >
              <arrow-right
                svgColor="#565F77"
                class="align-self-stretch"
              ></arrow-right>
              <span class="avenir-bold">
                {{
                  updatedParticipantData.gender === 1
                    ? $t('gender_male')
                    : $t('gender_female')
                }}
              </span>
            </p>
          </div>
        </b-col>

        <b-col cols="4">
          <div v-if="updatedParticipantData.height">
            <span class="editing mr-2">{{ $t('height') }} </span>
            <edit-icon class="align-self-stretch"></edit-icon>
            <p class="mt-2">
              <span class="opacity-6"
                ><s
                  >{{ selectedParticipant.height }} {{ $t('unit_cm') }}</s
                ></span
              >
              <arrow-right
                svgColor="#565F77"
                class="align-self-stretch"
              ></arrow-right>
              <span class="avenir-bold">
                {{ updatedParticipantData.height }} {{ $t('unit_cm') }}
              </span>
            </p>
          </div>
          <div v-else>
            <span class="opacity-6 normal-label">{{ $t('height') }}</span>
            <p class="avenir-bold mt-2">
              {{ selectedParticipant.height }} {{ $t('unit_cm') }}
            </p>
          </div>
        </b-col>

        <b-col cols="4">
          <div v-if="updatedParticipantData.weight">
            <span class="editing mr-2">{{ $t('weight') }} </span>
            <edit-icon class="align-self-stretch"></edit-icon>
            <p class="mt-2">
              <span class="opacity-6"
                ><s
                  >{{ selectedParticipant.weight }} {{ $t('unit_kg') }}</s
                ></span
              >
              <arrow-right
                svgColor="#565F77"
                class="align-self-stretch"
              ></arrow-right>
              <span class="avenir-bold">
                {{ updatedParticipantData.weight }} {{ $t('unit_kg') }}</span
              >
            </p>
          </div>
          <div v-else>
            <span class="opacity-6 normal-label">{{ $t('weight') }}</span>
            <p class="avenir-bold mt-2">
              {{ selectedParticipant.weight }} {{ $t('unit_kg') }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('icf_signed') }}</span>
          <p class="avenir-bold mt-2">
            {{ $t('yes') }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <span class="opacity-6 normal-label">{{
            $t('devices_third_party')
          }}</span>
          <p
            v-for="partner in participant.partners"
            :key="partner.id"
            class="avenir-bold mt-2"
          >
            <i class="ni ni-check-bold text-primary mr-2"></i>{{ partner.name }}
          </p>
        </b-col>
      </b-row>

      <b-row v-if="updatedParticipantData.reason" class="mt-4">
        <b-col cols="4">
          <span class="editing mr-2">{{ $t('change_reason') }} </span>
          <edit-icon class="align-self-stretch"></edit-icon>
          <p class="avenir-bold mt-2">
            {{
              updatedParticipantData.reason === 'change'
                ? $t('change_reason_change_short')
                : $t('change_reason_correction_short')
            }}
          </p>
        </b-col>
      </b-row>
    </div>

    <HorizontalDivider
      colorLine="E8E9EC"
      lineWidth="1"
      colsWidth="12"
    ></HorizontalDivider>

    <b-row class="pl-5 pr-5">
      <b-col>
        <info-panel
          class="mt-2"
          :title="alertTitle"
          :inputNum="2"
          :radioText="inputText"
          :backgroundColor="alertColor"
          :key="componentKey"
          @check-confirm2="checkConfirmation"
        ></info-panel>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          v-if="updatedParticipantData.reason"
          type="primary"
          :disabled="disabledButton"
          @click="handleNextClick('update')"
          rounded
          data-testid="next-button"
        >
          {{ $t('participant_update') }}
          <!-- <b-spinner></b-spinner> -->
        </base-button>
        <base-button
          v-else
          type="primary"
          :disabled="disabledButton"
          @click="handleNextClick('exit')"
          rounded
          data-testid="next-button"
        >
          {{ $t('exit') }}
          <!-- <b-spinner></b-spinner> -->
        </base-button>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
import InfoPanel from '@/components/InfoPanel';
import EditIcon from '@/components/Icons/EditIcon';

import { mapGetters, mapState } from 'vuex';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import ArrowRight from '@/components/Icons/ArrowRight.vue';
import { updateParticipant } from '@/api/participant';

export default {
  components: {
    InfoPanel,
    ArrowRight,
    HorizontalDivider,
    EditIcon,
    //updateParticipant,
  },

  data() {
    return {
      alertTitle: '',
      alertMessage: '',
      inputText: '',
      alertColor: '',
      buttonExit: true,
      buttonUpdate: true,
      componentKey: 0,
      disabledButton: true,
    };
  },
  props: {
    participant: Object,
  },

  mounted() {
    this.setAlertText();
  },

  computed: {
    updatedParticipantData() {
      return this.participant;
    },

    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      study: ({ studies }) => studies.selectedStudy,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      association: ({ participants }) => participants.association,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),
  },
  methods: {
    setAlertText() {
      this.componentKey += 1;
      if (this.updatedParticipantData.reason === null) {
        this.alertTitle = this.$t('change_box_no_changes');
        this.alertColor = '#778CA2';
        this.inputText = this.$t('i_understand_continue');
      } else {
        this.alertTitle = this.$t('change_box_info_correction_header');
        this.alertColor = '#046DB3';
        this.inputText = this.$t('i_understand_continue');
      }
    },

    checkType(selected, updated) {
      if (
        (selected.length === 0 && updated === 'patient') ||
        selected[0] === updated
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkConfirmation(event) {
      !event.target.checked
        ? (this.disabledButton = true)
        : (this.disabledButton = false);
    },
    handleNextClick(param) {
      const obj = this.updatedParticipantData;
      delete obj.partners;
      if (obj.gender === this.selectedParticipant.gender.id) {
        delete obj.gender;
      }
      if (obj.subjectCode === '') {
        delete obj.subjectCode;
      }

      let position = null;

      for (
        let index = 0;
        index < this.selectedParticipant.tags.length;
        index++
      ) {
        if (this.selectedParticipant.tags[index] === 'healthy_control') {
          position = index;
          break;
        }
      }

      if (this.selectedParticipant.tags.length === 0) {
        position = 0;
      }

      if (this.selectedParticipant.tags[position] === obj.tags[0]) {
        delete obj.tags;
      }

      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || '') {
          delete obj[key];
        }
      });
      const participantPayload = { ...obj };
      param === 'exit'
        ? this.$router.push({ name: 'view-participant' })
        : updateParticipant({
            clientId: this.selectedParticipant.clientId,
            studyId: this.selectedParticipant.studyId,
            protocolId: this.selectedParticipant.protocolId,
            siteId: this.selectedParticipant.siteId,
            participantId: this.selectedParticipant.id,
            payload: {
              ...participantPayload,
            },
          })
            .then(() => {
              this.$toast.info(this.$t('toast_participant_updated'));
              this.$router.push({ name: 'view-participant' });
            })
            .catch(() => {
              this.$toast.info(this.$t('toast_participant_updated'));
              this.$router.push({ name: 'view-participant' });
            });
    },
  },
};
</script>

<style scoped>
.input-group-text {
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
  color: inherit;
  border-radius: 0px;
  font-family: 'Avenir-Bold';
}

.text-lg {
  font-size: 1.125rem !important;
}

.editing {
  color: #3331e0;
}

.divider {
  border-bottom: 1px solid #c0c4cc;
}
</style>
