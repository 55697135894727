<template>
  <div :class="isTopLevelParticipant && 'p-5'">
    <wizard-header
      title="Create new Relapse Schedule"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard :currentStep="step" wizardType="relapses"></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <basic-information
        :relapse="relapse"
        v-show="step === 0"
        @nextStep="step++"
      />
      <test-games
        v-show="step === 1"
        :relapse="relapse"
        @nextStep="handleScheduleClick"
        @back="step--"
      />
      <overview
        :relapse="relapse"
        :games="schedule"
        v-if="step === 2"
        @back="step--"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      ok-title="No, Continue"
      cancel-title="Yes, cancel"
      title="Create new Relapse Schedule"
      title-tag="h3"
      header-class="justify-content-center"
      footer-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center">
        {{ $t('exit_create_relapse_schedule') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name:
                $route.name === 'create-relapse-schedule'
                  ? 'view-relapse'
                  : `${$route.meta.parent}-view-relapse`,
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Overview from '@/components/Relapse/Overview';
import TestGames from '@/components/Relapse/TestGames';
import WizardHeader from '@/components/WizardHeader';
import BasicInformation from '@/components/Relapse/BasicInformation';

export default {
  components: {
    BasicInformation,
    TestGames,
    Overview,
    WizardHeader,
  },
  data() {
    return {
      step: 0,
      showConfirmationModal: false,
      schedule: [],
      isTopLevelParticipant: this.$route.name === 'create-relapse-schedule',
    };
  },

  computed: {
    ...mapState({
      relapse: ({ relapses }) => relapses.selectedRelapse,
      study: (state) => state.studies.selectedStudy,
      selectedClient: (state) => state.clients.selectedClient,
      selectedSite: (state) => state.sites.selectedSite,
      selectedProtocol: (state) => state.protocols.selectedProtocol,
    }),
  },
  created() {
    this.setBreadCrumbData({
      'client-create-relapse-schedule': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.relapse.participant.participantDisplayId,
          to: { name: 'client-view-relapse' },
        },
        {
          text: this.$t('relapse_schedule_create_new'),
          active: true,
        },
      ],
      'study-create-relapse-schedule': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },
        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.relapse.participant.participantDisplayId,
          to: { name: 'study-view-relapse' },
        },
        {
          text: this.$t('relapse_schedule_create_new'),
          active: true,
        },
      ],
      'protocol-create-relapse-schedule': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },

        {
          text: this.selectedProtocol?.name,
          to: { name: 'view-protocol' },
        },

        {
          text: this.relapse.participant.participantDisplayId,
          to: { name: 'protocol-view-relapse' },
        },
        {
          text: this.$t('relapse_schedule_create_new'),
          active: true,
        },
      ],
      'site-create-relapse-schedule': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },

        {
          text: this.selectedSite.basic?.name,
          to: { name: 'view-site' },
        },
        {
          text: this.relapse.participant.participantDisplayId,
          to: { name: 'site-view-relapse' },
        },
        {
          text: this.$t('relapse_schedule_create_new'),
          active: true,
        },
      ],
      'create-relapse-schedule': [
        {
          text: this.$t('participants'),
          to: { name: 'participants' },
        },

        {
          text: this.relapse.participant.participantDisplayId,
          to: { name: 'view-relapse' },
        },
        {
          text: this.$t('relapse_schedule_create_new'),
          active: true,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      setBreadcrumb: 'breadcrumb/set',
    }),
    handleScheduleClick(payload) {
      this.schedule = payload;
      this.step++;
    },
  },
};
</script>

<style></style>
