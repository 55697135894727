<template>
  <div>
    <h2>{{ $t('tests_games') }}</h2>
    <p class="opacity-6">
      {{ $t('relapse_schedule_info') }}
    </p>
    <hr />
    <b-row>
      <b-col v-for="category in categoricalTests" :key="category.id">
        <h3>{{ $t(category.name) }}</h3>
        <b-form-group
          v-for="test in category.tests"
          :key="test.id"
          class="m-0 checkbox-group"
          :disabled="!test.availableForSelectedLanguage"
        >
          <b-form-checkbox-group
            :class="
              test.availableForSelectedLanguage
                ? 'custom-checkbox'
                : 'custom-checkbox-warning'
            "
            v-model="selected"
            v-for="version in test.versions"
            :key="version.id"
          >
            <b-form-checkbox
              :value="{
                id: version.id,
                name: `${test.name}`,
                version: `${version.version}`,
                language: `${test.languages}`,
              }"
            >
            </b-form-checkbox>
            <div
              :class="
                test.availableForSelectedLanguage
                  ? 'checkbox-content'
                  : 'checkbox-content-absolut'
              "
            >
              <span
                class="text-name"
                :class="test.availableForSelectedLanguage ? '' : 'grey-text'"
              >
                {{ $t(`${test.name}`) }} {{ `${version.version}` }}
              </span>
              <span
                v-if="!test.availableForSelectedLanguage"
                class="warning-language-text"
              >
                <check-ko height="15px" />{{ $t('partipant_task_lang_na') }}
              </span>
            </div>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="$emit('nextStep', selected)"
          :disabled="!selected.length || selected.length > 3"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CheckKo from '@/components/Icons/CheckKo.vue';
export default {
  components: { CheckKo },
  props: {
    schedule: Array,
    relapse: Object,
  },
  data() {
    return {
      selected: [],
      tokens: {
        '#': { pattern: /\d/ },
      },
      isEdit: this.$route.name === 'edit-protocol',
    };
  },
  computed: {
    ...mapState({
      participant: ({ participants }) => participants.selectedParticipant,
      tests: ({ resources }) =>
        resources.tests.reduce((acc, val) => acc.concat(val.categories), []),
    }),
    categoricalTests() {
      const categoryMap = this.tests
        .filter((test) => test.name !== 'task_category_survey')
        .reduce((acc, test) => {
          acc[test.name] = [...(acc[test.name] || []), ...test.tests];
          return acc;
        }, {});

      return Object.entries(categoryMap).map(([name, tests]) => ({
        name,
        tests,
      }));
    },
  },
  created() {
    this.getTestGames();
  },
  methods: {
    getTestGames() {
      let payload = {
        language: this.relapse.participant.language,
        'filter[game_mode]': 'true',
      };
      if (
        !this.participant.partners.filter(
          (i) => i.partnerId === 3 && i.isEnabled
        )
      ) {
        payload['filter[exclude]'] = 'roche';
      }
      this.$store.dispatch('resources/dispatchTestGames', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-language-text {
  color: #f35656;
  font-size: 11px;
}
.grey-text {
  color: #a1a8c0;
}
.text-name {
  font-size: 12px;
  display: block;
}
.checkbox-content {
  display: inline-block;
  position: relative;
  right: 0px;
  top: 7px;
}
.checkbox-content-absolut {
  position: relative;
  right: -31px;
  top: -23px;
}
.custom-checkbox {
  margin-top: 10px;
  .custom-control-inline {
    margin-right: -15px !important;
  }
  .custom-control-label::before {
    top: 9px !important;
  }
  &--warning {
    height: 50px;
    margin-top: 8px;
  }
}
.custom-checkbox-warning {
  height: 50px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
