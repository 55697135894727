<template>
  <WebUICard>
    <div class="upper-container shadow">
      <div class="schedule-adherence">
        <div class="avenir-bold collapse-title">
          <h3>
            {{ $t('adherence_to_schedules') }}
          </h3>
        </div>
        <div
          class="schedule-adherence-content d-flex justify-content-center align-items-center"
        >
          <statistics-report
            :statistics="statisticsData"
            :statisticsSection="statisticsColors"
          />
        </div>
      </div>
      <div class="select-period">
        <div class="avenir-bold collapse-title">
          <h3>
            {{ $t('select_period') }}
          </h3>
        </div>
        <div class="content">
          <WeeksPicker
            :range="range"
            :dateStart="participantDateStart"
            :dateEnd="participantDateEnd"
            :placeholder="$t('select_date')"
            @sendDate="handleRangeDateClick"
            @clearDate="clearDate"
          />
        </div>
      </div>
    </div>
  </WebUICard>
</template>

<script>
import StatisticsReport from '@/components/Participants/StatisticsReport.vue';
import WeeksPicker from '@/components/WeeksPicker.vue';
import WebUICard from '@/shared/components/WebUICard.vue';
export default {
  name: 'ParticipantChartWeekPickerComponent.vue',
  components: {
    StatisticsReport,
    WeeksPicker,
    WebUICard,
  },
  props: {
    statisticsData: {
      type: Object,
    },
    statisticsColors: {
      type: Array,
    },
    participantDateStart: {
      type: String,
    },
    participantDateEnd: {
      type: String,
    },
  },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    getCorrectDateFormat(date) {
      return this.moment(date).format('YYYY-MM-DD');
    },
    getEndRangeDateFormat(date) {
      return this.moment(date).format('YYYY-MM-DD');
    },
    getSundayOfCurrentWeek() {
      const today = new Date();
      const first = today.getDate() - today.getDay() + 7;

      const sunday = new Date(today.setDate(first));
      return this.moment(sunday).format('YYYY-MM-DD');
    },
    getMondayOfCurrentWeek() {
      const today = new Date();
      const first = today.getDate() - today.getDay() + 1;

      const monday = new Date(today.setDate(first));
      return this.moment(monday).format('YYYY-MM-DD');
    },
    clearDate() {
      this.range = {
        start: null,
        end: null,
      };
      this.$emit(
        'get-participant-statistics',
        this.getMondayOfCurrentWeek(),
        this.getSundayOfCurrentWeek()
      );
    },

    handleRangeDateClick() {
      const start = this.getCorrectDateFormat(this.range.start);
      const rangeEnd = this.getEndRangeDateFormat(this.range.end);
      if (this.range.end === null) {
        let startDatePlus6 = this.moment(this.range.start, 'YYYY-MM-DD').add(
          6,
          'days'
        );
        let endRange = this.getEndRangeDateFormat(startDatePlus6);
        this.$emit('get-participant-statistics', start, endRange);
      } else {
        this.$emit('get-participant-statistics', start, rangeEnd);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.upper-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  @include desktop-up {
    flex-direction: row;
  }

  h3 {
    padding: 20px;
    color: #525f7f;
    border-bottom: 1px solid #e9ecef;
    margin-bottom: 0;
  }

  .select-period {
    h3 {
      @include desktop-up {
        border-left: 1px solid #e9ecef;
      }
    }

    .content {
      padding: 10px 20px 20px;
      @include desktop-up {
        border-left: 1px solid #e9ecef;
        height: 276px;
      }
    }
  }

  .schedule-adherence {
    @include desktop-up {
      width: 65%;
    }

    .schedule-adherence-content {
      height: 276px;
    }
  }
}
</style>
