<template>
  <div class="shadow bg-white mb-5">
    <div v-if="titleTextKey || title" class="border-bottom pt-3 pb-3 pl-3">
      <slot name="header">
        <p class="avenir-bold collapse-title mb-0 pb-0">
          {{ titleTextKey.length ? $t(titleTextKey) : title }}
        </p>
      </slot>
    </div>
    <div class="control-class">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebUICard',
  props: {
    titleTextKey: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>
