var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('tests_games')))]),_c('p',{staticClass:"opacity-6"},[_vm._v(" "+_vm._s(_vm.$t('relapse_schedule_info'))+" ")]),_c('hr'),_c('b-row',_vm._l((_vm.categoricalTests),function(category){return _c('b-col',{key:category.id},[_c('h3',[_vm._v(_vm._s(_vm.$t(category.name)))]),_vm._l((category.tests),function(test){return _c('b-form-group',{key:test.id,staticClass:"m-0 checkbox-group",attrs:{"disabled":!test.availableForSelectedLanguage}},_vm._l((test.versions),function(version){return _c('b-form-checkbox-group',{key:version.id,class:test.availableForSelectedLanguage
              ? 'custom-checkbox'
              : 'custom-checkbox-warning',model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('b-form-checkbox',{attrs:{"value":{
              id: version.id,
              name: ("" + (test.name)),
              version: ("" + (version.version)),
              language: ("" + (test.languages)),
            }}}),_c('div',{class:test.availableForSelectedLanguage
                ? 'checkbox-content'
                : 'checkbox-content-absolut'},[_c('span',{staticClass:"text-name",class:test.availableForSelectedLanguage ? '' : 'grey-text'},[_vm._v(" "+_vm._s(_vm.$t(("" + (test.name))))+" "+_vm._s(("" + (version.version)))+" ")]),(!test.availableForSelectedLanguage)?_c('span',{staticClass:"warning-language-text"},[_c('check-ko',{attrs:{"height":"15px"}}),_vm._v(_vm._s(_vm.$t('partipant_task_lang_na'))+" ")],1):_vm._e()])],1)}),1)})],2)}),1),_c('b-row',{staticClass:"my-5"},[_c('b-col',[_c('base-button',{attrs:{"type":"secondary","data-testid":"back-button"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(_vm._s(_vm.$t('back'))+" ")]),_c('base-button',{attrs:{"type":"primary","disabled":!_vm.selected.length || _vm.selected.length > 3,"data-testid":"next-button"},on:{"click":function($event){return _vm.$emit('nextStep', _vm.selected)}}},[_vm._v(_vm._s(_vm.$t('next'))+" "),_c('arrow-right')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }