<template>
  <div>
    <template v-if="participantNoteList.length">
      <b-row v-for="note in participantNoteList" :key="note.id" class="pb-3">
        <b-col>
          <b-row class="pl-3 pr-3">
            <b-col class="d-flex justify-content-between pt-3">
              <span class="opacity-8"
                >{{ note.createdBy.firstName }}
                {{ note.createdBy.lastName }}
              </span>
              <span class="opacity-7">{{
                getDatafromTimestamp(note.createdAt)
              }}</span>
            </b-col>
          </b-row>
          <b-row class="pl-3 pr-3">
            <b-col class="d-flex justify-content-between pt-2">
              <p class="opacity-8">{{ note.note }}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="border-bottom pb-3">
        <b-col class="d-flex justify-content-center pt-3">{{
          $t('no_notes')
        }}</b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NoteDisplayComponent',
  created() {},
  props: {
    participantNoteList: {
      type: Array,
      require: false,
    },
  },
  methods: {
    getDatafromTimestamp(date) {
      return this.moment.unix(date).format('DD MMMM YYYY, hh:mm');
    },
  },
};
</script>

<style scoped></style>
