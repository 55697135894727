<template>
  <div :class="{ 'p-5': $route.name === 'update-participant' }">
    <wizard-header
      :title="this.$t('participant_update')"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard
            :currentStep="step"
            :wizardType="
              participantStatusIsDRAFT
                ? 'updateParticipantDraft'
                : 'updateParticipants'
            "
          ></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <step-one-draft
        v-if="participantStatusIsDRAFT"
        v-show="step === 0"
        :participantData="participantDraftData"
        @nextStep="handleNext"
        @participantData="stepOneParticipantUpdatedData"
      ></step-one-draft>
      <step-one
        v-else
        :info="participant"
        v-show="step === 0"
        @nextStep="handleNext"
      />

      <step-two-draft
        v-if="participantStatusIsDRAFT && step === 1"
        :participant="participantDraftData"
        v-show="step === 1"
        @nextStep="handleNext"
        @tailorData="stepTwoParticipantUpdatedData"
        @back="step--"
      />
      <step-two
        v-if="!participantStatusIsDRAFT && step === 1"
        :participant="participant"
        v-show="step === 1"
        @back="step--"
      />

      <step-three-draft
        v-if="participantStatusIsDRAFT && step === 2"
        :participantInfoUpdatedData="participantInfoUpdatedData"
        :tailorScheduleUpdatedData="tailorScheduleUpdatedData"
        v-show="step === 2"
        @back="step--"
      />
    </div>

    <b-modal
      v-model="showConfirmationModal"
      :title="$t('participant_update')"
      footer-class="justify-content-center"
      header-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center">
        {{ $t('exit_update_participant') }}
      </p>
      <template #modal-footer="{ hide }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="$router.push({ name: 'view-participant' })"
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="hide()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import StepOne from '@/components/Participants/StepWizard/UpdateParticipant/StepOne';
import StepTwo from '@/components/Participants/StepWizard/UpdateParticipant/StepTwo';
import StepOneDraft from '@/components/Participants/StepWizard/UpdateParticipantDraft/StepOne';
import StepTwoDraft from '@/components/Participants/StepWizard/UpdateParticipantDraft/StepTwo';
import StepThreeDraft from '@/components/Participants/StepWizard/UpdateParticipantDraft/StepThree';
import WizardHeader from '@/components/WizardHeader';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    StepOne,
    StepTwo,
    StepOneDraft,
    StepTwoDraft,
    StepThreeDraft,
    WizardHeader,
  },
  data() {
    return {
      showConfirmationModal: false,
      step: 0,
      participant: {
        subjectCode: '',
        gender: null,
        height: null,
        weight: null,
        partners: [],
        tags: [],
        reason: null,
      },
      participantDraftData: {},
      participantInfoUpdatedData: {},
      tailorScheduleUpdatedData: [],
    };
  },
  computed: {
    ...mapState({
      site: (state) => state.sites.selectedSite,
      study: (state) => state.studies.selectedStudy,
      selectedClient: (state) => state.clients.selectedClient,
      protocol: ({ protocols }) => protocols.selectedProtocol,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),
    participantStatusIsDRAFT() {
      return this.$route.params.status === 'DRAFT';
    },
  },

  created() {
    this.fetchProtocolDetails();
    this.participantDraftObject();

    this.setBreadCrumbData({
      'client-create-participant': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'client-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'study-create-participant': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'study-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'protocol-create-participant': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },

        {
          text: this.protocol?.name,
          to: { name: 'view-protocol' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'protocol-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'site-create-participant': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },

        {
          text: this.site?.basic?.name,
          to: { name: 'view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'add-participant': [
        {
          text: this.$t('participants'),
          to: { name: 'participants' },
        },

        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      fetchPartners: 'resources/dispatchFetchPartners',
    }),
    handleNext() {
      this.step++;
    },
    handleTailorScheduleNextClick(games) {
      this.participant.games = games;
      this.step++;
    },
    participantDraftObject() {
      const object = {
        subjectCode: this.selectedParticipant.subjectCode,
        gender: this.selectedParticipant.gender.id,
        height: this.selectedParticipant.height,
        weight: this.selectedParticipant.weight,
        partners: this.selectedParticipant.partners,
        phone: this.selectedParticipant.phone,
        tags: this.selectedParticipant.tags.length === 0 ? 0 : 1,
        reason: null,
        language: this.selectedParticipant.language,
        protocolId: this.selectedParticipant.protocolId,
      };
      this.participantDraftData = object;
    },
    stepOneParticipantUpdatedData(data) {
      this.participantInfoUpdatedData = data;
    },
    stepTwoParticipantUpdatedData(data) {
      this.tailorScheduleUpdatedData = data;
    },
    async fetchProtocolDetails() {
      const { clientId, studyId, protocolId } = this.selectedParticipant;
      const payload = {
        clientId,
        studyId,
        protocolId,
      };
      try {
        this.$store
          .dispatch('sites/getProtocolDetails', payload)
          .then(() => {});
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
