<template>
  <div>
    <div class="clearfix mb-4" v-if="showUpdateSite">
      <router-link
        :to="{
          name: isTopLevelSite
            ? 'edit-site'
            : `${$route.meta.parent}-edit-site`,
        }"
      >
        <base-button
          v-if="canWriteSite(selectedSite.basic.id, protocol)"
          type="primary"
          class="float-right action-button"
          >{{ $t('site_update') }}</base-button
        >
      </router-link>
    </div>

    <div class="text-center" v-if="loading"><b-spinner></b-spinner></div>
    <template v-else>
      <overview
        :site="selectedSite"
        class="mt-4"
        :isProtocolView="isProtocolView"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Overview from '@/components/Sites/Overview';

export default {
  components: {
    Overview,
  },
  data() {
    return {
      loading: true,
      isTopLevelSite: this.$route.name === 'view-site',
      siteParams3: {},
      protocol3: {},
      siteData: {},
      siteContact: {},
    };
  },
  computed: {
    ...mapState({
      selectedClient: (state) => state.clients.selectedClient,
      study: (state) => state.studies.selectedStudy,
      selectedSite: (state) => state.sites.selectedSite,
      siteProtocol: (state) => state.sites.siteProtocol,
      siteParams: (state) => state.sites.siteParams,
      protocol: (state) => state.protocols.selectedProtocol,
      isProtocolView: (state) => state.sites.isProtocolView,
    }),
    showUpdateSite() {
      return (
        this.siteProtocol?.study?.status?.name === 'ACTIVE' &&
        this.siteProtocol?.status?.name !== 'INACTIVE'
      );
    },
  },
  created() {
    this.getSiteParams();
  },
  methods: {
    getSiteParams() {
      const payload = {
        clientId: this.siteParams.clientId,
        studyId: this.siteParams.studyId,
        protocolId: this.siteParams.protocolId,
        siteId: this.siteParams.siteId,
      };
      this.getSiteDetails(payload);
    },

    async getSiteDetails(params) {
      this.loading = true;
      try {
        await this.$store
          .dispatch('sites/GET_SITE_DATA', params)
          .then((resp) => {
            this.siteData = resp;
          });
        await this.$store
          .dispatch('sites/GET_SITE_CONTACT', params)
          .then((resp) => {
            this.siteContact = resp;
          })
          .then(() => {
            this.selectedSiteData();
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
        this.$router.push({
          name: 'sites',
        });
      } finally {
        this.setBreadCrums();
      }
    },

    selectedSiteData() {
      const { site, protocol } = this.siteData;
      const {
        address,
        country: { id: countryId },
        city,
        postalCode,
        id,
      } = this.siteContact.contact;
      this.$store.commit('sites/SET_SELECTED_SITE', {
        basic: { ...site, protocolId: protocol.id },
        siteAddress: {
          address,
          countryId,
          city,
          postalCode,
          id,
        },
      });
    },

    setBreadCrums() {
      this.setBreadCrumbData({
        'client-view-site': [
          {
            text: this.$t('clients'),
            to: { name: 'clients' },
          },
          {
            text: this.selectedClient.basic?.legalName,
            to: { name: 'client-details' },
          },
          {
            text: this.study?.name,
            to: { name: 'client-study-details' },
          },
          {
            text: this.selectedSite.basic.name,
            active: true,
          },
        ],
        'study-view-site': [
          {
            text: this.$t('studies'),
            to: { name: 'studies' },
          },

          {
            text: this.study?.name,
            to: { name: 'study-details' },
          },
          {
            text: this.selectedSite.basic.name,
            active: true,
          },
        ],
        'protocol-view-site': [
          {
            text: this.$t('protocols'),
            to: { name: 'protocols' },
          },
          {
            text: this.protocol?.name,
            to: { name: 'view-protocol' },
          },
          {
            text: this.selectedSite.basic.name,
            active: true,
          },
        ],
        'view-site': [
          {
            text: this.$t('sites'),
            to: { name: 'sites' },
          },
          {
            text: this.selectedSite.basic.name,
            active: true,
          },
        ],
      });
      this.loading = false;
    },
  },
};
</script>
