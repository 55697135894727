<template>
  <div>
    <h2 class="">{{ $t('general_information') }}</h2>
    <b-row>
      <b-col cols="4">
        <label>{{ $t('participant_id') }}</label>
        <p>{{ relapse.participant.participantDisplayId }}</p>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('participant_id_subject') }}</label>
        <p>{{ relapse.participant.subjectCode }}</p>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('language') }}</label>
        <p>
          {{
            languageNameFromCode(
              relapse.participant.language ? relapse.participant.language : ''
            )
          }}
          - {{ relapse.participant.language.toUpperCase() }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <label>{{ $t('relapse_id') }}</label>
        <p>{{ relapse.displayId }}</p>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('relapse_report_date') }}</label>
        <p>{{ moment.unix(relapse.reportedAt).format('DD/MM/YYYY') }}</p>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('duration') }}</label>
        <p>{{ $t('six_weeks') }}</p>
      </b-col>
    </b-row>

    <div class="mt-5">
      <base-button
        type="primary"
        @click="$emit('nextStep')"
        rounded
        data-testid="next-button"
      >
        {{ $t('next') }}
        <arrow-right />
      </base-button>
    </div>
  </div>
</template>

<script>
import { languagesDefault } from '@/constants/languages-constants';
export default {
  props: {
    relapse: Object,
  },
  data() {
    return {
      languagesList: [...languagesDefault],
    };
  },

  methods: {
    languageNameFromCode(languageCode) {
      const filtered = this.languagesList.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },
  },
};
</script>

<style></style>
