<template>
  <div class="overview-card">
    <p class="text-lg">{{ $t('client_information') }}</p>
    <b-row>
      <b-col>
        <b-row>
          <b-col class="opacity-6">
            {{ $t('client_name_legal') }}
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="avenir-bold">{{ client.legalName }}</b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col class="opacity-6">
            {{ $t('client_type') }}
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="client.clientType">
          <b-col
            class="avenir-bold"
            v-if="
              clientTypes.find((type) => type.id === client.clientType.id).name
            "
            >{{ $t(client.clientType.name) }}</b-col
          >
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col class="opacity-6">
            {{ $t('program_manager') }}
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="client.programManager">
          <b-col
            class="avenir-bold"
            v-html="
              managers.find(
                (manager) => manager.id === client.programManager.id
              ).label
            "
          ></b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col class="opacity-6">
            {{ $t('description') }}
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="avenir-bold">{{ client.description }}</b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fetchClientBasicInfo } from '@/api/client';

export default {
  props: {
    clientId: String,
  },
  data() {
    return {
      client: {},
      clientTypeText: '',
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.resources.countries,
      managers: (state) => state.experts.managers,
      clientTypes: (state) => state.resources.clientTypes,
    }),
  },

  created() {
    this.getClientBasicInfo();
  },

  methods: {
    async getClientBasicInfo() {
      try {
        const { data } = await fetchClientBasicInfo(this.clientId);
        this.client = data;
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
  },
};
</script>

<style></style>
