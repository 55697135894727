<template>
  <div>
    <h2 class="mb-3">{{ $t('overview') }}</h2>
    <div class="overview-card">
      <p class="mt-2 mb-4">{{ $t('study_information') }}</p>
      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('study') }}</span>
          <p class="avenir-bold mt-2">
            {{ selectedParticipant.studyName }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('protocol') }}</span>
          <p class="avenir-bold mt-2">
            {{ selectedParticipant.protocolName }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('site') }}</span>
          <p class="avenir-bold mt-2">
            {{ selectedParticipant.siteName }}
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="mt-2 mb-4">{{ $t('participant_information') }}</p>
      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('phone_number_mobile')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ participantInfoUpdatedData.phone }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('participant_id_subject')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ participantInfoUpdatedData.protocolId }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('gender') }}</span>
          <p class="avenir-bold mt-2">
            {{
              $t(
                participantInfoUpdatedData.gender === 2
                  ? 'gender_female'
                  : 'gender_male'
              )
            }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('height') }}</span>
          <p class="avenir-bold mt-2">
            {{ participantInfoUpdatedData.height }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('weight') }}</span>
          <p class="avenir-bold mt-2">
            {{ participantInfoUpdatedData.weight }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('type') }}</span>
          <p class="avenir-bold mt-2">
            {{
              $t(
                participantInfoUpdatedData.tags[0] === 'patient'
                  ? 'patient'
                  : 'healthy_control'
              )
            }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('study_duration') }}</span>
          <p class="avenir-bold mt-2">
            {{ siteProtocol.studyDurationPerParticipant }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('study_start_date')
          }}</span>
          <p class="avenir-bold mt-2">{{ calculateStartDate }}</p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('study_end_date_planned')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ moment(calculateEndDate).format('DD/MM/YYYY') }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('icf_signed') }}</span>
          <p class="avenir-bold mt-2">{{ $t('yes') }}</p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('language') }}</span>
          <p
            v-if="participantInfoUpdatedData.language"
            class="avenir-bold mt-2"
          >
            {{ languageNameFromCode(participantInfoUpdatedData.language) }} -
            {{ participantInfoUpdatedData.language.toUpperCase() }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('devices_third_party')
          }}</span>
          <div
            v-for="(partner, i) in participantInfoUpdatedData.partners"
            :key="i"
            class="avenir-bold mt-2"
          >
            <p>
              <span v-if="participantInfoUpdatedData.partners[i].isEnabled"
                ><i class="ni ni-check-bold text-primary mr-2"></i></span
              ><span v-else><clear-icon class="mr-2" /></span>
              {{ partner.name }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="mt-2 mb-4">{{ $t('schedule_tailor') }}</p>

      <div v-for="(partner, pi) in tailorScheduleUpdatedData" :key="partner.id">
        <b-row>
          <b-col cols="4">
            <h3>
              <span>
                {{ partner.name }}
              </span>
            </h3>
          </b-col>
        </b-row>
        <div
          v-for="(category, i) in partner.categories"
          :key="i"
          class="mt-3 pb-4 ml-4"
        >
          <b-row>
            <b-col cols="4">
              <h4 size="sm">
                <span>
                  {{ $t(category.name) }}
                </span>
              </h4>
            </b-col>
            <b-col cols="4" class="d-flex align-items-center">
              <p class="text-blue">
                <small
                  >{{
                    enabledNumberOfTests(
                      tailorScheduleUpdatedData[pi].categories[i]
                    )
                  }}
                  {{ '/' }}
                  {{ totalNumberOfTests(category.tests) }}
                  {{ $t('enabled') }}</small
                >
              </p>
            </b-col>
          </b-row>
          <b-row v-for="test in category.tests" :key="test.id">
            <b-col>
              <p class="ml-4">
                <span v-if="test.versions.enabledDraft"
                  ><checked-icon class="mr-2" /></span
                ><span v-else><clear-icon class="mr-2" /></span
                >{{ $t(test.name) }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <b-row>
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          :disabled="loading"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="updateParticipantDraft"
          :disabled="loading"
          rounded
          data-testid="next-button"
        >
          {{ $t('participant_update') }}
          <b-spinner v-if="loading" small></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { languagesDefault } from '@/constants/languages-constants';
import CheckedIcon from '@/components/Icons/CheckedIcon.vue';
import ClearIcon from '@/components/Icons/ClearIcon.vue';
import { sendExcludedTasks, updateParticipant } from '@/api/participant';
import { sendConsent } from '@/api/participantsChanges';

export default {
  name: 'StepThreeDraft',
  components: { CheckedIcon, ClearIcon },

  props: {
    participantInfoUpdatedData: Object,
    tailorScheduleUpdatedData: Array,
  },

  created() {
    this.partnersConsent.push(
      this.participantInfoUpdatedData.partners[0].partnerId
    );
  },

  data() {
    return {
      languagesList: [...languagesDefault],
      partnersConsent: [],
      loading: false,
    };
  },

  computed: {
    ...mapState({
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      changedTest: ({ participants }) => participants.changedTestsPayload,
      changeReason: ({ participants }) => participants.changeReason,
      participantTests: (state) => state.participants.participantTests,
    }),
    calculateStartDate() {
      return this.moment().startOf('isoWeek').format('DD/MM/YYYY');
    },
    calculateEndDate() {
      const { studyDurationPerParticipant } = this.siteProtocol;
      let now = new Date();
      return now.setDate(now.getDate() + studyDurationPerParticipant * 7);
    },
  },

  methods: {
    languageNameFromCode(languageCode) {
      const filtered = this.languagesList.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },

    totalNumberOfTests(category) {
      return Object.keys(category).length;
    },

    enabledNumberOfTests(category) {
      let numbers = 0;
      for (let index = 0; index < Object.keys(category.tests).length; index++) {
        if (category.tests[index].versions.enabledDraft) {
          numbers += 1;
        }
      }
      return numbers;
    },

    updateParticipantDraft() {
      this.loading = true;
      const promises = [];
      if (this.changedTest.length > 0) {
        promises.push(
          sendExcludedTasks({
            clientId: this.selectedParticipant.clientId,
            studyId: this.selectedParticipant.studyId,
            protocolId: this.selectedParticipant.protocolId,
            siteId: this.selectedParticipant.siteId,
            id: this.selectedParticipant.id,
            payload: { tests: this.changedTest, reason: 'correction' },
          })
        );
      }
      if (
        this.participantInfoUpdatedData.partners[0].isEnabled !==
        this.participantInfoUpdatedData.originalPartnerData[0].isEnabled
      ) {
        promises.push(
          sendConsent({
            clientId: this.selectedParticipant.clientId,
            studyId: this.selectedParticipant.studyId,
            protocolId: this.selectedParticipant.protocolId,
            siteId: this.selectedParticipant.siteId,
            id: this.selectedParticipant.id,
            payload: {
              partners: this.partnersConsent,
              reason: 'correction',
            },
          })
        );
      }
      promises.push(
        updateParticipant({
          clientId: this.selectedParticipant.clientId,
          studyId: this.selectedParticipant.studyId,
          protocolId: this.selectedParticipant.protocolId,
          siteId: this.selectedParticipant.siteId,
          participantId: this.selectedParticipant.id,
          payload: this.participantInfoUpdatedData,
        })
      );
      return new Promise((resolve, reject) => {
        Promise.allSettled(promises)
          .then(() => {
            this.$toast.info(this.$t('toast_participant_updated'));
            this.$router.push({ name: 'view-participant' });
            this.loading = false;
            resolve();
          })
          .catch(() => {
            this.$toast.error(this.$t('error_something_went_wrong'));
            this.$router.push({ name: 'view-participant' });
            this.loading = false;
            reject();
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.text-blue {
  color: #046db3;
}
</style>
