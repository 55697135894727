<template>
  <div class="overview-card">
    <p class="text-lg">
      {{ sectionTitle }}
    </p>
    <b-row>
      <b-col>
        <b-row class="opacity-6">
          <b-col>{{ $t('name') }}</b-col>
          <b-col>{{ $t('email') }}</b-col>
          <b-col>{{ $t('phone') }}</b-col>
          <b-col>{{ $t('title') }}</b-col>
          <b-col>{{ $t('status') }}</b-col>
        </b-row>
        <b-row
          class="avenir-bold my-2"
          v-for="representative in representatives"
          :key="representative.email"
        >
          <b-col
            >{{ representative.firstName }} {{ representative.lastName }}</b-col
          >
          <b-col class="text-break">{{ representative.email }}</b-col>
          <b-col>{{ representative.phone }}</b-col>
          <b-col>{{ representative.title }}</b-col>
          <b-col>
            <app-badge
              :variant="
                representative.status.name.toLowerCase() === 'active'
                  ? 'success'
                  : 'secondary'
              "
            >
              {{
                representative.status.name.toLowerCase() === 'active'
                  ? $t('ACTIVE')
                  : $t('INACTIVE')
              }}
            </app-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ClientAssignedUsersTable.vue',
  props: {
    sectionTitle: String,
    representatives: Array,
  },
};
</script>

<style></style>
