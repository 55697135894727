<template>
  <div>
    <div
      v-for="(partner, pi) in participantTasks"
      :key="partner.id"
      class="accordion partners-title"
      role="tablist"
    >
      <b-row>
        <b-col cols="4">
          <h3 class="pt-3 pb-2" v-b-toggle="`collapse-${partner.id}`">
            <img
              src="@/assets/icons/plus-icon.svg"
              alt="expand-accordion"
              class="when-closed mr-2"
            />

            {{ partner.name }}
          </h3>
        </b-col>
      </b-row>
      <b-collapse :id="`collapse-${partner.id}`" class="ml-4" visible>
        <b-row
          v-for="(category, i) in partner.categories"
          :key="i"
          class="mt-3 pb-4"
        >
          <b-col cols="4" class="pl-3 d-flex align-items-center">
            <h4
              v-b-toggle="`collapse-${category.id}${i}-inner`"
              size="sm"
              class="child-toggle"
              visible
            >
              <img
                src="@/assets/icons/plus-icon.svg"
                alt="expand-accordion"
                class="when-closed mr-2"
              />
              {{ $t(category.name) }}
            </h4>
          </b-col>
          <b-col cols="4" class="d-flex align-items-center">
            <p class="text-blue">
              <small
                >{{ enabledNumberOfTests(selected[pi].categories[i]) }}
                {{ '/' }}
                {{ totalNumberOfTests(category.tests) }}
                {{ $t('enabled') }}</small
              >
            </p>
          </b-col>
          <b-col cols="4" class="text-blue clickable d-flex align-items-center">
            <small
              v-if="enabledNumberOfTests(selected[pi].categories[i]) >= 1"
              @click="
                disable_enable_AllCategoryTests(
                  parseInt(pi),
                  parseInt(i),
                  (child = 0),
                  partner.id,
                  category.name
                )
              "
              ><u>{{ $t('disable_all_tasks_from_this_category') }}</u></small
            >
            <small
              v-if="enabledNumberOfTests(selected[pi].categories[i]) === 0"
              @click="
                disable_enable_AllCategoryTests(
                  parseInt(pi),
                  parseInt(i),
                  (child = 1),
                  partner.id,
                  category.name
                )
              "
              ><u>{{ $t('enable_all_tasks_from_this_category') }}</u></small
            >
          </b-col>
          <b-row>
            <b-collapse :id="`collapse-${category.id}${i}-inner`" visible>
              <b-col v-for="(test, a) in category.tests" :key="test.id">
                <b-form-checkbox
                  class="ml-4"
                  name="check-button-step2"
                  :disabled="
                    !selected[pi].categories[i].tests[a].versions
                      .availableForSelectedLanguage
                  "
                  :class="
                    !selected[pi].categories[i].tests[a].versions
                      .availableForSelectedLanguage
                      ? 'notLanguageAvailable'
                      : 'checkboxEnabled'
                  "
                  switch
                  v-model="
                    selected[pi].categories[i].tests[a].versions.enabledDraft
                  "
                  @change="
                    detectTestChange(selected[pi].categories[i].tests[a])
                  "
                  ><div class="d-block">
                    <span
                      :class="
                        selected[pi].categories[i].tests[a].versions
                          .enabledDraft
                          ? ''
                          : 'text-grey'
                      "
                    >
                      {{ $t(test.name) }}
                    </span>
                    <p
                      v-if="
                        !selected[pi].categories[i].tests[a].versions
                          .availableForSelectedLanguage
                      "
                      class="warning-language-text"
                    >
                      <check-ko height="15px" />{{
                        $t('partipant_task_lang_na')
                      }}
                    </p>
                  </div></b-form-checkbox
                >
              </b-col>
            </b-collapse>
          </b-row>
        </b-row>
      </b-collapse>
    </div>

    <b-row class="mt-5">
      <b-col class="mt-4">
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="handleNextClick"
          rounded
          data-testid="next-button"
        >
          {{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
      <b-col> </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CheckKo from '@/components/Icons/CheckKo.vue';

export default {
  name: 'StepTwo',
  components: { CheckKo },
  props: {
    participant: Object,
  },

  data() {
    return {
      allowSelected: false,
      selected: {},
      differentTasks: [],
    };
  },

  created() {
    this.cloneObject(this.participantTests);
  },

  computed: {
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      testPartnersObject: ({ resources }) => resources.tests,
      participantTests: (state) => state.participants.participantTests,
      participantTasks: (state) => state.resources.tests,
      excludedTask: (state) => state.participants.excluded_games,
    }),
  },

  methods: {
    async handleNextClick() {
      this.$emit('nextStep');
      this.$emit('tailorData', this.selected);
      this.$store.commit('participants/SET_CHANGED_TESTS', this.differentTasks);
    },

    cloneObject(object) {
      this.selected = structuredClone(object);
      this.selectedOriginalValues = structuredClone(object);
    },

    totalNumberOfTests(category) {
      return Object.keys(category).length;
    },

    enabledNumberOfTests(category) {
      let numbers = 0;
      for (let index = 0; index < Object.keys(category.tests).length; index++) {
        if (category.tests[index].versions.enabledDraft) {
          numbers += 1;
        }
      }
      return numbers;
    },

    disable_enable_AllCategoryTests(pi, i, child) {
      let categoryTests = this.selected[pi].categories[i].tests;
      let elements = Object.keys(categoryTests).length;
      for (let index = 0; index < elements; index++) {
        categoryTests[index].versions.enabledDraft = child !== 0;
      }
    },
    detectTestChange(test) {
      if (test.versions.enabledDraft === test.versions.disabled) {
        this.addString(test.versions.id);
      } else {
        this.differentTasks.filter((e) => e !== test.versions.id);
      }
    },
    addString(newString) {
      this.differentTasks = [...this.differentTasks, newString];
    },
  },
};
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}
.partners-title {
  border-bottom: 1px solid #c0c4cc;
}

.text-blue {
  color: #046db3;
}

.text-grey {
  color: #a1a8c0;
}

.child-toggle {
  img {
    width: 11px;
  }
}

.tasks-list {
  display: grid;
  grid-template-rows: repeat(5, min-content);
  grid-auto-flow: column;
  column-gap: 35px;
}

:not(.collapsed) > .when-closed {
  transform: rotate(45deg);
  transition: transform 0.5s;
}
.warning-language-text {
  color: #f89a9a;
  font-size: 10px;
  font-weight: normal !important;
  position: relative;
  top: -7px;
}

.checkbox-content {
  display: inline-block;
}
.checkbox-content-absolut {
  position: relative;
  right: -31px;
  top: -33px;
}
.custom-checkbox {
  margin-top: 8px;
  .custom-control-inline {
    margin-right: -15px !important;
  }
}
.custom-checkbox-warning {
  height: 50px;
  margin-top: 8px;
}
.checkboxEnabled {
  span {
    font-size: 15px;
  }
}
.checkboxDisabled {
  ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #e9ecef !important;
  }
  ::v-deep .custom-control-label::before {
    border: 1px solid #e9ecef !important;
  }
  ::v-deep.custom-control-label span {
    position: relative;
    top: -7px !important;
  }
  ::v-deep .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: #ffffff !important;
    border: 1px solid #e9ecef !important;
  }
}
.notLanguageAvailable {
  ::v-deep .text-grey {
    color: #a1a8c0;
    position: relative;
    top: -7px;
  }
  ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ced3d7 !important;
  }
  ::v-deep .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: #ffffff !important;
  }
}
</style>
