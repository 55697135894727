<template>
  <div class="overview-card">
    <p class="text-lg">
      {{ sectionTitle }}
    </p>
    <b-row>
      <b-col>
        <b-row class="opacity-6">
          <b-col>{{ $t('name') }}</b-col>
          <b-col>{{ $t('email') }}</b-col>
          <b-col>{{ $t('phone') }}</b-col>
          <b-col>{{ $t('title') }}</b-col>
          <b-col>{{ $t('status') }}</b-col>
        </b-row>
        <b-row
          class="avenir-bold my-2"
          v-for="rep in coordinators"
          :key="rep.email"
        >
          <b-col>{{ rep.firstName }} {{ rep.lastName }}</b-col>
          <b-col class="text-break">{{ rep.email }}</b-col>
          <b-col>{{ rep.phone }}</b-col>
          <b-col>{{ rep.title }}</b-col>
          <b-col>
            <app-badge
              :variant="rep.status.name === 'ACTIVE' ? 'success' : 'secondary'"
            >
              {{ rep.status.name === 'ACTIVE' ? $t('ACTIVE') : $t('INACTIVE') }}
            </app-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    sectionTitle: String,
    coordinators: Array,
  },
};
</script>

<style></style>
