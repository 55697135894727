var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isTopLevelView && 'm-5'},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner')],1):_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[(_vm.participantIsDraft)?_c('DraftUpdateParticipantStatusComponent',{on:{"reload-parent":_vm.getParticipantData,"activate-parent":_vm.activateParticipant}}):_vm._e(),(_vm.statistics && !_vm.participantIsScreenedOut && !_vm.participantIsDraft)?_c('ParticipantChartWeekPickerComponent',{attrs:{"statisticsData":_vm.statistics,"statisticsColors":_vm.taskStatusColors,"participantDateStart":_vm.participant.dateStart,"participantDateEnd":_vm.participant.dateEnd},on:{"get-participant-statistics":_vm.getStatistics}}):_vm._e(),(
          _vm.showRelapsesTable &&
          !_vm.participantStatusDiscontinue &&
          !_vm.participantIsScreenedOut &&
          !_vm.participantIsDraft
        )?_c('RelapseTableComponent',{attrs:{"relapsesData":_vm.relapsesList}}):_vm._e(),(
          !_vm.participantStatusDiscontinue &&
          !_vm.participantIsScreenedOut &&
          !_vm.participantIsDraft &&
          Object.keys(_vm.relapseSchedule).length > 0
        )?_c('ParticipantRelapseScheduleStatusComponent',{attrs:{"relapseScheduleData":_vm.relapseSchedule}}):_vm._e(),(!_vm.participantStatusDiscontinue && !_vm.participantIsScreenedOut)?_c('ParticipantDetailStudySchedule',{key:_vm.reloadTasks,attrs:{"participantParams":_vm.participantParams,"participantIsDraft":_vm.participantIsDraft}}):_vm._e(),_c('NoteWidgetComponent',{attrs:{"displayCreateNote":!_vm.participantIsScreenedOut}}),(_vm.showChangesTable && !_vm.participantStatusDiscontinue)?_c('change-history-table',{key:_vm.updateChangesTable,staticStyle:{"padding-bottom":"20px"},attrs:{"view":"view-participant","tableData":_vm.participantChangesList,"initial-pagination-values":_vm.paginationValues}}):_vm._e()],1),_c('b-col',[_c('participant-sideBar',{key:_vm.reloadKey,attrs:{"participant":_vm.participant,"discontinuedParticipant":_vm.participantStatusDiscontinue,"draftParticipant":_vm.participantIsDraft},on:{"get-participantData":_vm.getParticipantData,"open-modal":function($event){_vm.isDropOutModalOn = true}}})],1),(_vm.isDropOutAvailable)?_c('ModalDiscontinuedParticipant',{attrs:{"modal-visible":_vm.isDropOutModalOn,"participantData":_vm.participant},on:{"close-modal":function($event){_vm.isDropOutModalOn = false},"reload-data":_vm.reloadParticipantData}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }