<template>
  <div>
    <h2 class="mb-3">{{ $t('overview') }}</h2>
    <div class="overview-card">
      <p class="mt-2 mb-4">{{ $t('study_information') }}</p>
      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('study') }}</span>
          <p class="avenir-bold mt-2">
            {{ addedByClientUser ? association.studyName : study.name }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('protocol') }}</span>
          <p class="avenir-bold mt-2">
            {{
              addedByClientUser ? association.protocolName : siteProtocol.name
            }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('site') }}</span>
          <p class="avenir-bold mt-2">
            {{ addedByClientUser ? association.siteName : site.basic.name }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('study_start_date')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ this.moment().startOf('isoWeek').format('DD/MM/YYYY') }}
          </p>
        </b-col>
        <b-col cols="6">
          <span class="opacity-6 normal-label">{{
            $t('study_end_date_planned')
          }}</span>
          <p class="avenir-bold mt-2">
            {{ moment(participant.endDate).format('DD/MM/YYYY') }}
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="mt-2 mb-4">{{ $t('participant_information') }}</p>
      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('phone_number_mobile')
          }}</span>
          <p class="avenir-bold mt-2">{{ participant.phone }}</p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{
            $t('participant_id_subject')
          }}</span>
          <p class="avenir-bold mt-2">{{ participant.subjectCode }}</p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('type') }}</span>
          <p class="avenir-bold mt-2">
            {{ participant.tags === 1 ? $t('patient') : $t('healthy_control') }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('gender') }}</span>
          <p class="avenir-bold mt-2">
            {{
              participant.gender === 2 ? $t('gender_female') : $t('gender_male')
            }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('height') }}</span>
          <p class="avenir-bold mt-2">
            {{ participant.height }} {{ $t('unit_cm') }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('weight') }}</span>
          <p class="avenir-bold mt-2">
            {{ participant.weight }} {{ $t('unit_kg') }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('icf_signed') }}</span>
          <p class="avenir-bold mt-2">
            {{ participant.icfSigned ? $t('yes') : $t('no') }}
          </p>
        </b-col>
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('language') }}</span>
          <p class="avenir-bold mt-2">
            {{ languageNameFromCode(participant.language) }} -
            {{ participant.language.toUpperCase() }}
          </p>
        </b-col>
      </b-row>

      <b-row cols="4" class="mt-2">
        <b-col cols="12">
          <span class="opacity-6">{{ $t('devices_third_party') }}</span>
          <p
            class="avenir-bold mt-2 d-flex align-items-center"
            v-for="partner in participant.partners"
            :key="partner.id"
          >
            <span v-if="partner.isEnabled" class="d-flex align-items-center"
              ><i class="ni ni-check-bold text-primary mr-2"></i>
            </span>
            <span v-if="!partner.isEnabled" class="d-flex align-items-center"
              ><i class="fa fa-times text-danger mr-2"></i>
            </span>
            <span> {{ partner.name }}</span>
          </p>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card">
      <p class="my-2">{{ $t('schedule_tailor') }}</p>
      <div v-for="(partner, index) in testPartnersObject" :key="index">
        <p class="avenir-bold mt-5">{{ partner.name }}</p>
        <div v-for="(category, i) in partner.categories" :key="i">
          <b-row class="ml-5 mb-3">
            <b-col cols="6"
              ><span class="avenir-bold mb-4 mt-4">
                {{ $t(category.name) }}
              </span></b-col
            >
            <b-col cols="6">
              <span>
                <small class="text-blue"
                  >{{ enabledNumberOfTests(category, partner.name) }}
                  {{ $t('/') }} {{ totalNumberOfTests(category.tests) }}
                  {{ $t('enabled') }}</small
                >
              </span>
            </b-col>
          </b-row>
          <div class="testList mt-4 mb-4 ml-8">
            <b-row v-for="test in category.tests" :key="test.id">
              <b-col class="avenir-bold testList">
                <b-row v-if="checkIfEnabled(test.name, category, partner)">
                  <p>
                    <i class="ni ni-check-bold text-primary icon"></i
                    ><span class="ml-2">{{ $t(test.name) }}</span>
                  </p>
                </b-row>
                <b-row v-else>
                  <p class="opacity-6">
                    <i class="fa fa-times text-danger icon"></i
                    ><span class="ml-2">{{ $t(test.name) }}</span>
                  </p>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>

    <b-row class="my-5">
      <b-col>
        <base-button type="secondary" @click="$emit('back')" :disabled="loading"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="transformParticipantData"
          :disabled="loading"
          data-testid="submit-button"
          >{{ $t('participant_create') }}
          <b-spinner v-if="loading" small></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createParticipant } from '@/api/participant';
import { mapState, mapGetters } from 'vuex';
import { isNil } from 'lodash/lang';
import { languagesDefault } from '@/constants/languages-constants';

export default {
  data() {
    return {
      loading: false,
      languagesList: [...languagesDefault],
      isProtocolView:
        this.$route.params.fromRouteName === 'protocol-site-participants',
      addedByClientUser: this.$route.name === 'add-participant',
      addParticipantFromNavMenu:
        isNil(this.$route.params) || isNil(this.$route.params.fromRouteName),
      excluded: {},
    };
  },
  props: {
    participant: Object,
  },
  computed: {
    ...mapState({
      study: ({ studies }) => studies.selectedStudy,
      site: ({ sites }) => sites.selectedSite,
      protocol: ({ sites }) => sites.siteProtocol,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      association: ({ participants }) => participants.association,
      testPartnersObject: ({ resources }) => resources.tests,
    }),
    ...mapGetters({ options: 'protocols/protocolOptions' }),
  },
  methods: {
    checkIfEnabled(testNamed, category, partner) {
      const selected = this.participant.games;
      let testName = category.tests.map(({ name }) => name);
      let testsListByPartner = Object.values(selected?.[partner.name]);

      for (let index = 0; index < testName.length; index++) {
        let findEnabledTest = testsListByPartner.find(
          (e) => e.name === testNamed
        )?.isEnabled;
        let findDisabledTest = testsListByPartner.find(
          (e) => e.name === testNamed
        )?.availableForSelectedLanguage;
        if (findEnabledTest && findDisabledTest) {
          return true;
        }
      }
    },
    totalNumberOfTests(category) {
      return Object.keys(category).length;
    },

    enabledNumberOfTests(category, partner) {
      const selected = this.participant.games;
      let testName = category.tests.map(({ name }) => name);
      let testsListByPartner = Object.values(selected[partner]);
      let numbers = 0;
      for (let index = 0; index < testName.length; index++) {
        let findEnabledTest = testsListByPartner.find(
          (e) => e.name === testName[index]
        )?.isEnabled;
        if (findEnabledTest) {
          numbers += 1;
        }
      }
      return numbers;
    },
    transformParticipantData() {
      this.loading = true;
      const participantPayload = { ...this.participant };
      if (!participantPayload.subjectCode.trim()) {
        delete participantPayload.subjectCode;
      }
      if (participantPayload.tags === 2) {
        participantPayload.tags = ['healthy_control'];
      } else {
        participantPayload.tags = [];
      }
      let payload = {
        clientId: this.addParticipantFromNavMenu
          ? this.association.clientId
          : this.isProtocolView
          ? this.selectedProtocol.study.clientId
          : this.study.clientId,
        studyId: this.addParticipantFromNavMenu
          ? this.association.studyId
          : this.isProtocolView
          ? this.selectedProtocol.study.id
          : this.study.id,
        siteId: this.addParticipantFromNavMenu
          ? this.association.siteId
          : this.site.basic.id,
        protocolId: this.addParticipantFromNavMenu
          ? this.association.protocolId
          : this.site.basic.protocolId,
        body: {
          ...participantPayload,
        },
      };

      /* Check If there are any disabled games */
      let excludedGames = [];
      Object.values(this.participant.games).forEach((partner) => {
        Object.values(partner).forEach((game) => {
          if (!game.isEnabled) excludedGames.push(game.versionId);
        });
      });
      if (excludedGames.length > 0) {
        payload.body.excludedGames = excludedGames;
        this.excluded = { ...excludedGames };
      }

      this.createParticipantApi(payload);
    },
    async createParticipantApi(payload) {
      try {
        const response = await createParticipant(payload);
        if (response.code === 201) {
          this.$toast.info(this.$t('toast_participant_registered'));
          this.$router.push({ name: 'participants' });
        }
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    languageNameFromCode(languageCode) {
      const filtered = this.languagesList.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },
  },
};
</script>

<style scoped lang="scss">
.text-blue {
  color: #046db3;
}
.testList {
  display: grid;
  grid-template-rows: repeat(5, min-content);
  grid-auto-flow: column;
  column-gap: 0px;
}

.icon {
  width: 10px;
  height: 10px;
  position: relative;
  margin-right: 10px;
}
</style>
