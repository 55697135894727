import { render, staticRenderFns } from "./NoteDisplayComponent.vue?vue&type=template&id=bc7e496e&scoped=true&"
import script from "./NoteDisplayComponent.vue?vue&type=script&lang=js&"
export * from "./NoteDisplayComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc7e496e",
  null
  
)

export default component.exports