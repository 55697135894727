<template>
  <div>
    <b-spinner v-if="loading" class="mx-auto d-block"></b-spinner>
    <WebUICard v-else titleTextKey="change_history">
      <div class="table-overflow">
        <v-client-table
          :data="filterTableData"
          :columns="columns"
          :options="options"
          data-testid="changes-table"
          striped
        >
          <template v-slot:createdAt="props">
            <div class="dateField">
              <span>
                {{ momentTz.unix(props.row.createdAt).format('DD/MM/YYYY') }}
              </span>
              <span class="secondary-data">
                {{
                  momentTz.unix(props.row.createdAt).format('HH:mm:ss (z Z)')
                }}
              </span>
            </div>
          </template>
          <template v-slot:effectiveAt="props">
            <div class="dateField">
              <span>
                {{ momentTz.unix(props.row.effectiveAt).format('DD/MM/YYYY') }}
              </span>
              <span class="secondary-data">
                {{
                  momentTz.unix(props.row.effectiveAt).format('HH:mm:ss (z Z)')
                }}
              </span>
            </div>
          </template>
          <template v-slot:changedBy="props">
            <div class="dateField">
              <span>
                {{ props.row.changedBy.firstName }}
              </span>
              <span>{{ props.row.changedBy.lastName }}</span>
            </div>
          </template>
          <template v-slot:details="props">
            {{
              props.row.reason === 'change'
                ? $t('change_reason_change_short')
                : $t('change_reason_correction_short')
            }}
            <br />
            <div v-for="(item, index) in props.row.details" :key="index">
              <div>
                <!-- Height field -->
                <div v-if="index === 'height'" class="dateField">
                  <span class="pl-1 change-field-title">{{ $t(index) }}:</span>
                  <span class="pl-3">
                    {{ $t(item.newValue) }}{{ $t('unit_kg') }}
                    <span>
                      <s> {{ $t(item.oldValue) }}{{ $t('unit_kg') }}</s>
                    </span>
                  </span>
                </div>
                <!-- Weight field -->
                <div v-if="index === 'weight'" class="dateField">
                  <span class="pl-1 change-field-title">{{ $t(index) }}:</span>
                  <span class="pl-3">
                    {{ $t(item.newValue) }}{{ $t('unit_cm') }}
                    <span>
                      <s> {{ $t(item.oldValue) }}{{ $t('unit_cm') }}</s>
                    </span>
                  </span>
                </div>
                <!-- Gender field -->
                <div v-if="index === 'gender'" class="dateField">
                  <span class="pl-1 change-field-title">{{ $t(index) }}:</span>
                  <span class="pl-3">
                    {{ $t(item.newValue) }}
                    <span>
                      <s> {{ $t(item.oldValue) }}</s>
                    </span>
                  </span>
                </div>
                <!-- Subject code field -->
                <div v-if="index === 'subjectCode'" class="dateField">
                  <span class="pl-1 change-field-title"
                    >{{ $t('participant_id_subject') }}:</span
                  >
                  <span class="pl-3">
                    {{ item.newValue }}
                    <span>
                      <s> {{ item.oldValue }}</s>
                    </span>
                  </span>
                </div>

                <!-- status field -->
                <div v-if="index === 'status'" class="dateField">
                  <span class="pl-1 change-field-title">{{ $t(index) }}:</span>
                  <span class="pl-3">
                    {{ $t(item.newValue) }}
                    <span>
                      <s> {{ $t(item.oldValue) }}</s>
                    </span>
                  </span>
                </div>

                <!-- Visits field -->
                <div v-if="index === 'visit'" class="dateField">
                  <span class="pl-1 change-field-title"
                    >{{ $t('reschedule_visit') }}:</span
                  >
                  <span class="pl-3">{{
                    item.type === 'ANNUAL' ? 'Annual visit' : 'End of study'
                  }}</span>
                  <span class="pl-3">
                    {{
                      moment(item.newValue, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    }}
                  </span>
                  <span class="pl-3">
                    <s>
                      {{
                        moment(item.oldValue, 'YYYY-MM-DD').format('DD/MM/YYYY')
                      }}</s
                    >
                  </span>
                </div>

                <!-- Partners field -->
                <div v-if="index === 'partners'">
                  <div v-for="(value, index) in item" :key="index">
                    <div v-if="item[index].newValue" class="dateField">
                      <span class="change-field-title">
                        {{ $t('enable_3pd') }}:
                      </span>
                      <span class="pl-3">{{ item[index].partnerName }}</span>
                    </div>
                    <div v-else class="dateField">
                      <span class="change-field-title">
                        {{ $t('disable_3pd') }}:
                      </span>
                      <span class="pl-3"
                        ><s>{{ item[index].partnerName }}</s></span
                      >
                    </div>
                  </div>
                </div>

                <!-- tasks field -->
                <div v-if="index === 'tasks'" class="dateField">
                  <div v-for="(value, index) in item" :key="index">
                    <div v-if="item[index].newValue">
                      <span class="change-field-title">
                        {{ $t('enable_task') }}:
                      </span>
                      <span>{{ $t(item[index].taskName) }}</span>
                    </div>
                    <div v-else>
                      <span class="change-field-title">
                        {{ $t('disable_task') }}:
                      </span>
                      <span
                        ><s>{{ $t(item[index].taskName) }}</s></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </v-client-table>
      </div>
      <div>
        <b-col
          v-if="displaySeeAllButton && view === 'view-participant'"
          class="link d-flex justify-content-center align-items-center mt-3"
        >
          <p class="changes-link" @click="redirectToChangesHistoryView">
            {{ $t('see_all') }}
          </p>
        </b-col>
      </div>
    </WebUICard>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import WebUICard from '@/shared/components/WebUICard.vue';

export default {
  components: { WebUICard },
  props: {
    tableData: Array,
    view: String,
    initialPaginationValues: Object || null,
  },
  data() {
    return {
      loading: false,
      startPage: 1,
      totalRows: 0,
      limit: 5,
      offset: 1,
      paginationValues: null,
      participantChangesRecords: [],
      columns: ['createdAt', 'effectiveAt', 'changedBy', 'details'],
      options: {
        headings: {
          createdAt: this.$t('change_date'),
          effectiveAt: this.$t('effective_date'),
          changedBy: this.$t('user'),
          details: this.$t('changes'),
        },
        skin: 'table-striped ',
        sortable: [],
      },
      showRedirect: true,
      showPagination: false,
      filterTableData: [],
      displaySeeAllButton: false,
      isParticipantView: true,
    };
  },
  mounted() {
    this.showRedirect = true;
    this.showPagination = this.view !== 'view-participant';
    this.filterFields();
  },
  computed: {
    ...mapState({
      participantParams: (state) => state.participants.participantParams,
    }),
  },

  methods: {
    filterFields() {
      const newArray = this.tableData.map((object) => {
        const { changedBy, createdAt, effectiveAt, reason, details } = object;
        //remove nulls and empty arrays
        const filteredPairs = Object.entries(details).filter(([, valor]) => {
          return (
            valor !== null && !(Array.isArray(valor) && valor.length === 0)
          );
        });
        const filteredObject = Object.fromEntries(filteredPairs);

        return {
          changedBy,
          createdAt,
          effectiveAt,
          reason,
          details: filteredObject,
        };
      });
      this.filterTableData = newArray;
      if (newArray.length >= 5) this.displaySeeAllButton = true;
    },

    redirectToChangesHistoryView() {
      this.limit = 10;
      this.isParticipantView = false;
      this.$router.push({ name: 'changes-history' });
    },
  },
};
</script>

<style scoped lang="scss">
.changes-link {
  color: #4e84d0;
  cursor: pointer;
}
.change-field-title::before {
  content: '•';
  padding-right: 5px;
}
.VueTables table.app-table td:nth-child(2) {
  width: 150px !important;
}

.table-overflow {
  overflow-x: auto;
}
.main-ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0 !important;
}

.sub-ul {
  margin-bottom: 0 !important;
}

li {
  list-style-type: disc;
}

.link a {
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  padding-bottom: 18px;
}

.small-letter {
  font-size: 12px;
  padding: 0;
}

::v-deep .page-link {
  border: 0 !important;
  margin: 10px !important;
}
.dateField {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .secondary-data {
    font-size: 12px;
  }
}
</style>
