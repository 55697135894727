var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.participantTasks),function(partner,pi){return _c('div',{key:partner.id,staticClass:"accordion partners-title",attrs:{"role":"tablist"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('h3',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("collapse-" + (partner.id))),expression:"`collapse-${partner.id}`"}],staticClass:"pt-3 pb-2"},[_c('img',{staticClass:"when-closed mr-2",attrs:{"src":require("@/assets/icons/plus-icon.svg"),"alt":"expand-accordion"}}),_vm._v(" "+_vm._s(partner.name)+" ")])])],1),_c('b-collapse',{staticClass:"ml-4",attrs:{"id":("collapse-" + (partner.id)),"visible":""}},_vm._l((partner.categories),function(category,i){return _c('b-row',{key:i,staticClass:"mt-3 pb-4"},[_c('b-col',{staticClass:"pl-3 d-flex align-items-center",attrs:{"cols":"4"}},[_c('h4',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("collapse-" + (category.id) + i + "-inner")),expression:"`collapse-${category.id}${i}-inner`"}],staticClass:"child-toggle",attrs:{"size":"sm","visible":""}},[_c('img',{staticClass:"when-closed mr-2",attrs:{"src":require("@/assets/icons/plus-icon.svg"),"alt":"expand-accordion"}}),_vm._v(" "+_vm._s(_vm.$t(category.name))+" ")])]),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"4"}},[_c('p',{staticClass:"text-blue"},[_c('small',[_vm._v(_vm._s(_vm.enabledNumberOfTests(_vm.selected[pi].categories[i]))+" "+_vm._s('/')+" "+_vm._s(_vm.totalNumberOfTests(category.tests))+" "+_vm._s(_vm.$t('enabled')))])])]),_c('b-col',{staticClass:"text-blue clickable d-flex align-items-center",attrs:{"cols":"4"}},[(_vm.enabledNumberOfTests(_vm.selected[pi].categories[i]) >= 1)?_c('small',{on:{"click":function($event){_vm.disable_enable_AllCategoryTests(
                parseInt(pi),
                parseInt(i),
                (_vm.child = 0),
                partner.id,
                category.name
              )}}},[_c('u',[_vm._v(_vm._s(_vm.$t('disable_all_tasks_from_this_category')))])]):_vm._e(),(_vm.enabledNumberOfTests(_vm.selected[pi].categories[i]) === 0)?_c('small',{on:{"click":function($event){_vm.disable_enable_AllCategoryTests(
                parseInt(pi),
                parseInt(i),
                (_vm.child = 1),
                partner.id,
                category.name
              )}}},[_c('u',[_vm._v(_vm._s(_vm.$t('enable_all_tasks_from_this_category')))])]):_vm._e()]),_c('b-row',[_c('b-collapse',{attrs:{"id":("collapse-" + (category.id) + i + "-inner"),"visible":""}},_vm._l((category.tests),function(test,a){return _c('b-col',{key:test.id},[_c('b-form-checkbox',{staticClass:"ml-4",class:!_vm.selected[pi].categories[i].tests[a].versions
                    .availableForSelectedLanguage
                    ? 'notLanguageAvailable'
                    : 'checkboxEnabled',attrs:{"name":"check-button-step2","disabled":!_vm.selected[pi].categories[i].tests[a].versions
                    .availableForSelectedLanguage,"switch":""},on:{"change":function($event){return _vm.detectTestChange(_vm.selected[pi].categories[i].tests[a])}},model:{value:(
                  _vm.selected[pi].categories[i].tests[a].versions.enabledDraft
                ),callback:function ($$v) {_vm.$set(_vm.selected[pi].categories[i].tests[a].versions, "enabledDraft", $$v)},expression:"\n                  selected[pi].categories[i].tests[a].versions.enabledDraft\n                "}},[_c('div',{staticClass:"d-block"},[_c('span',{class:_vm.selected[pi].categories[i].tests[a].versions
                        .enabledDraft
                        ? ''
                        : 'text-grey'},[_vm._v(" "+_vm._s(_vm.$t(test.name))+" ")]),(
                      !_vm.selected[pi].categories[i].tests[a].versions
                        .availableForSelectedLanguage
                    )?_c('p',{staticClass:"warning-language-text"},[_c('check-ko',{attrs:{"height":"15px"}}),_vm._v(_vm._s(_vm.$t('partipant_task_lang_na'))+" ")],1):_vm._e()])])],1)}),1)],1)],1)}),1)],1)}),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"mt-4"},[_c('base-button',{attrs:{"type":"secondary","data-testid":"back-button"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(_vm._s(_vm.$t('back'))+" ")]),_c('base-button',{attrs:{"type":"primary","rounded":"","data-testid":"next-button"},on:{"click":_vm.handleNextClick}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" "),_c('arrow-right')],1)],1),_c('b-col')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }