<template>
  <div class="icon-styles d-flex justify-content-start align-items-center">
    <img v-if="phone" :src="option.img" alt="country flag" />
    <span v-if="showNumber" class="opacity-6 pl-2"> {{ phone }}</span>
  </div>
</template>

<script>
export default {
  name: 'FlagIcons',
  status: 'ready',

  props: {
    phone: {
      type: String,
      required: String,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      option: {
        img: '',
      },
    };
  },

  mounted() {
    this.setCountryFlag(this.phone);
  },
  methods: {
    setCountryFlag(value) {
      const ret = value.replace('+', '').split(' ').shift();
      this.getCountryName(ret);
    },
    getCountryName(type) {
      switch (type) {
        case '33':
          this.option.img = '/img/icons/france.png';
          break;
        case '41':
          this.option.img = '/img/icons/switzerland.png';
          break;
        case '1':
          this.option.img = '/img/icons/united_states.png';
          break;
        case '31':
          this.option.img = '/img/icons/netherlands.png';
          break;
        case '34':
          this.option.img = '/img/icons/spain.png';
          break;
        case '49':
          this.option.img = '/img/icons/germany.png';
          break;
        default:
          this.option.img = '';
          break;
      }
    },
  },
};
</script>
        
<style lang="scss" scoped >
.icon-styles {
  img {
    width: 25px;
  }
  span {
    font-size: 15px;
  }
}
</style>

        