<template>
  <!--  <ValidationObserver v-slot="{ valid }">-->
  <ValidationObserver>
    <h3>{{ $t('study_information') }}</h3>
    <b-row>
      <b-col cols="4">
        <label>{{ $t('study') }}</label>
        <p class="opacity-6">
          {{ selectedParticipant.studyName }}
        </p>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('protocol') }}</label>
        <p class="opacity-6">
          {{ selectedParticipant.protocolName }}
        </p>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('site') }}</label>
        <p class="opacity-6">
          {{ selectedParticipant.siteName }}
        </p>
      </b-col>
    </b-row>

    <b-row class="d-none">
      <b-col cols="4">
        <label>{{ $t('unit_of_measurement') }}</label>
        <p class="opacity-6"></p>
      </b-col>
    </b-row>

    <HorizontalDivider
      colorLine="e8e9ec"
      lineWidth="1"
      colsWidth="12"
    ></HorizontalDivider>

    <h3 class="mt-3">{{ $t('participant_information') }}</h3>

    <b-row class="mt-3">
      <b-col cols="4">
        <label>{{ $t('phone_number_mobile') }}</label>
        <p class="opacity-6">
          {{ selectedParticipant.phone }}
        </p>
      </b-col>

      <b-col cols="4">
        <label>{{ $t('participant_id_subject') }}</label>
        <b-form-input
          v-model="participantData.subjectCode"
          :placeholder="selectedParticipant.subjectCode"
          maxLength="100"
          id="subject-id"
          @focus="labeFocus('subject')"
          @focusout="labelFocusOut('subject')"
          data-testid="subject-input"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="4">
        <label>{{ $t('gender') }}</label>
        <b-form-radio-group
          v-model="participantData.gender"
          data-testid="gender-inputs"
        >
          <b-form-radio class="mr-5" :value="1" data-testid="male-radio">
            <span class="opacity-6 avenir">{{ $t('gender_male') }}</span>
          </b-form-radio>
          <b-form-radio :value="2" data-testid="female-radio" checked>
            <span class="opacity-6 avenir">{{ $t('gender_female') }}</span>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>

      <b-col cols="4">
        <label>{{ $t('height') }}</label>
        <ValidationProvider
          name="height"
          rules="between:100,300"
          v-slot="{ errors }"
        >
          <b-input-group class="w-50">
            <template #append>
              <b-input-group-text class="w-50"
                ><span class="opacity-6">{{
                  $t('unit_cm')
                }}</span></b-input-group-text
              >
            </template>
            <b-form-input
              type="number"
              v-model.number="participantData.height"
              :placeholder="participantData.height.toString()"
              @focus="labeFocus('height')"
              @focusout="labelFocusOut('height')"
              data-testid="height-input"
            ></b-form-input>
          </b-input-group>
          <span class="text-danger text-sm">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-col>

      <b-col cols="4">
        <label>
          <span>{{ $t('weight') }} </span>
        </label>
        <ValidationProvider
          name="weight"
          rules="between:10,999"
          v-slot="{ errors }"
        >
          <b-input-group class="w-50">
            <template #append>
              <b-input-group-text
                ><span class="opacity-6">{{
                  $t('unit_kg')
                }}</span></b-input-group-text
              >
            </template>
            <b-form-input
              type="number"
              :placeholder="participantData.weight.toString()"
              v-model.number="participantData.weight"
              @focus="labeFocus('weight')"
              @focusout="labelFocusOut('weight')"
              data-testid="weight-input"
            ></b-form-input>
          </b-input-group>
          <span class="text-danger text-sm">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="4">
        <label>{{ $t('type') }}</label>
        <b-form-radio-group
          v-model="participantData.tags"
          data-testid="tags-inputs"
        >
          <b-form-radio class="mr-5" :value="0" data-testid="patient-radio">
            <span class="opacity-6 avenir">{{ $t('patient') }}</span>
          </b-form-radio>
          <b-form-radio :value="1" data-testid="healthy-radio" checked>
            <span class="opacity-6 avenir">{{ $t('healthy_control') }}</span>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('study_duration') }}</label>
        <p class="opacity-6">
          {{ siteProtocol.studyDurationPerParticipant }}
        </p>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="4">
        <label>{{ $t('study_start_date') }}</label>
        <p class="opacity-6">
          {{ calculateStartDate }}
        </p>
      </b-col>
      <b-col cols="4">
        <label>{{ $t('study_end_date_planned') }}</label>
        <p class="opacity-6">
          {{ moment(calculateEndDate).format('DD/MM/YYYY') }}
        </p>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="4">
        <label> {{ $t('icf_signed') }}</label>
        <b-form-radio-group v-model="icfSigned" data-testid="icf-inputs">
          <b-form-radio class="mr-5" :value="true" data-testid="icf-true-value">
            <span class="opacity-6">{{ $t('yes') }}</span>
          </b-form-radio>
          <b-form-radio :value="false" data-testid="icf-false-value">
            <span class="opacity-6">{{ $t('no') }}</span>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="4">
        <label>{{ $t('language') }}</label>
        <b-form-radio-group
          v-model="participantData.language"
          data-testid="gender-inputs"
          stacked
        >
          <b-form-radio
            v-for="(language, i) in siteLanguages"
            :key="i"
            :value="language"
            data-testid="male-radio"
          >
            <span class="opacity-6"
              >{{ languageNameFromCode(language) }} -
              {{ language.toUpperCase() }}</span
            >
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <HorizontalDivider
      colorLine="e8e9ec"
      lineWidth="1"
      colsWidth="12"
    ></HorizontalDivider>

    <h3>{{ $t('devices_third_party') }}</h3>

    <b-row class="mt-4">
      <b-col v-for="(partner, i) in participantData.partners" :key="i">
        <div>
          <!--<div v-if="partners[i].isEnabled === partnersCopy[i].isEnabled">-->
          <p>
            <b-form-checkbox
              class="ml-3"
              name="check-button-step2"
              switch
              v-model="participantData.partners[i].isEnabled"
              >{{ partner.name }}</b-form-checkbox
            >
          </p>
        </div>
      </b-col>
    </b-row>

    <HorizontalDivider
      colorLine="e8e9ec"
      lineWidth="1"
      colsWidth="12"
    ></HorizontalDivider>

    <b-row class="mt-3">
      <b-col>
        <base-button
          type="primary"
          @click="handleNextClick"
          rounded
          :disabled="!allowNextStep || !icfSigned"
          data-testid="next-button-draft"
        >
          {{ $t('next') }}
          <b-spinner v-if="!allowNextStep" small></b-spinner>
          <arrow-right v-else />
        </base-button>
      </b-col>
      <b-col> </b-col>
    </b-row>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { languagesDefault } from '@/constants/languages-constants';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import participantDraftUpdateTestMixin from '@/mixins/participantDraftUpdateTestMixin';
import { fetchParticipants } from '@/api/participant';

export default {
  name: 'StepOneDraft',
  components: { ValidationObserver, HorizontalDivider },
  props: {
    participantData: Object,
  },
  mixins: [participantDraftUpdateTestMixin],
  data() {
    return {
      loading: false,
      icfSigned: true,
      siteLanguages: [],
      languagesList: [...languagesDefault],
      selected: {},
      partnersArray: [],
      partnersName: [],
      allowNextStep: false,
      originalPartnerData: {},
    };
  },
  watch: {
    'participantData.language'() {
      this.allowNextStep = false;
      this.getParticipantTestList();
    },
  },
  created() {
    this.getSiteParams();
    this.cloneObject(this.selectedParticipant.partners);
  },
  computed: {
    ...mapState({
      siteProtocol: ({ sites }) => sites.siteProtocol,
      participantParams: (state) => state.participants.participantParams,
      participantTasks: (state) => state.resources.tests,
      excludedTask: (state) => state.participants.excluded_games,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),
    calculateEndDate() {
      const { studyDurationPerParticipant } = this.siteProtocol;
      let now = new Date();
      return now.setDate(now.getDate() + studyDurationPerParticipant * 7);
    },

    calculateStartDate() {
      return this.moment().startOf('isoWeek').format('DD/MM/YYYY');
    },
  },

  methods: {
    getSiteParams() {
      const payload = {
        clientId: this.selectedParticipant.clientId,
        studyId: this.selectedParticipant.studyId,
        protocolId: this.selectedParticipant.protocolId,
        siteId: this.selectedParticipant.siteId,
      };
      this.getSiteDetails(payload);
    },

    cloneObject(object) {
      this.originalPartnerData = structuredClone(object);
    },

    async getSiteDetails(params) {
      try {
        await this.$store
          .dispatch('sites/GET_SITE_DATA', params)
          .then((resp) => {
            this.siteLanguages = resp.site.languages;
            this.getParticipantTestList();
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },

    languageNameFromCode(languageCode) {
      const filtered = this.languagesList.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },

    async handleNextClick() {
      this.loading = true;
      let isValidSubject = true;
      if (
        this.participantData.subjectCode !==
        this.selectedParticipant.subjectCode
      ) {
        isValidSubject = await this.validateSubjectCode();
      }
      if (isValidSubject) this.$emit('nextStep');
      this.loading = false;
      this.participantDataObjectForStepThree();
    },

    async validateSubjectCode() {
      try {
        const { data } = await fetchParticipants({
          'filter[subject_code]': this.participantData.subjectCode,
        });
        if (data?.length === 0) {
          return true;
        } else {
          this.$toast.error(this.$t('error_subject_id_not_valid'));
        }
      } catch (error) {
        this.$toast.error(this.$t(error.msg));
      }
    },

    participantDataObjectForStepThree() {
      const object = {
        reason: 'correction',
        subjectCode: this.participantData.subjectCode,
        gender: this.participantData.gender,
        height: this.participantData.height,
        weight: this.participantData.weight,
        language: this.participantData.language,
        tags:
          this.participantData.tags === 1 ? ['healthy_control'] : ['patient'],
        partners: this.participantData.partners,
        originalPartnerData: this.originalPartnerData,
      };
      this.$emit('participantData', object);
    },

    /* Prepare object for step 2  */
    async getParticipantTestList() {
      try {
        this.$store
          .dispatch('resources/dispatchTestGames', {
            language: this.participantData.language,
            'filter[protocol]': this.selectedParticipant.protocolId,
            'filter[exclude]': !this.participantData.partners[0].isEnabled
              ? 'roche'
              : null,
            'filter[game_mode]': true,
          })
          .then(() => {
            this.getExcludedGames();
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    async getExcludedGames() {
      const payload = this.participantParams;
      try {
        this.$store
          .dispatch('participants/tasksEnabled', payload)
          .then((resp) => {
            if (resp.code === 200) {
              this.createTestsObjectForStepTwo();
              this.allowNextStep = true;
            } else {
              this.$toast.error(this.$t('error_something_went_wrong'));
            }
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.msg) || this.$t('error_something_went_wrong')
        );
      }
    },

    createTestsObjectForStepTwo() {
      this.refactor_partner_category_test_list(
        this.participantTasks,
        this.excludedTask
      );
    },
  },
};
</script>

<style scoped>
.input-group-text {
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
  color: inherit;
  border-radius: 0px;
  font-family: 'Avenir-Bold';
}
</style>
