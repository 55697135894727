<template>
  <b-row class="mt-4" v-if="contact">
    <b-col cols="3">
      <p class="opacity-6 mb-2">
        {{ $t('address') }}
      </p>
      <div class="avenir-bold text-break">{{ contact.address }}</div>
    </b-col>
    <b-col cols="3">
      <p class="opacity-6 mb-2">
        {{ $t('city') }}
      </p>

      <div class="avenir-bold">{{ contact.city }}</div>
    </b-col>
    <b-col cols="3">
      <p class="opacity-6 mb-2">
        {{ $t('country') }}
      </p>

      <div class="avenir-bold">{{ country }}</div>
    </b-col>
    <b-col cols="3">
      <p class="opacity-6 mb-2">
        {{ $t('postal_code') }}
      </p>

      <div class="avenir-bold">{{ contact.postalCode }}</div>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    contact: Object,
  },
  computed: {
    ...mapState({
      countries: (state) => state.resources.countries,
    }),
    country() {
      return this.countries.find(
        (country) => country.id === this.contact.countryId
      )?.shortName;
    },
  },
};
</script>

<style></style>
