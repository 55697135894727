<template>
  <div>
    <h2 class="">{{ $t('site_information') }}</h2>
    <b-row>
      <b-col cols="6">
        <label>{{ $t('study_name') }}</label>
        <b-form-input
          :value="studyName"
          disabled
          data-testid="studyName-input"
        ></b-form-input>
      </b-col>
      <b-col cols="6">
        <label>{{ $t('protocol_name') }}</label>
        <v-select
          v-model="basic.protocolId"
          :placeholder="$t('protocol_select')"
          :options="protocolsFilterData"
          label="text"
          :reduce="(protocol) => protocol.value"
          :disabled="isProtocol || (isEdit && site.basic.hasParticipants)"
          :selectable="() => !loadingProtocolsFilterData"
          id="protocol-name"
        />
      </b-col>
      <b-col cols="12 mt-2">
        <label>{{ $t('site_name') }}</label>
        <b-form-input
          v-model="basic.name"
          placeholder="Enter site name (unique)"
          maxLength="100"
          data-testid="siteName-input"
          :disabled="isEdit && site.basic.hasParticipants"
        ></b-form-input>
      </b-col>
      <b-col cols="12 mt-2 mb-2">
        <label>{{ $t('languages') }}</label>
        <p class="description text-muted mb-1">{{ $t('site_lang_info') }}</p>
        <div v-for="(language, index) in languagesList" :key="index">
          <base-checkbox
            v-if="
              !isEdit ||
              !hasParticipantsNotScreenedOut ||
              !initialSelectedLanguages.includes(language.code)
            "
            :checked="initialSelectedLanguages.includes(language.code)"
            @input="
              (value) => {
                updateLanguages(value, language.code);
              }
            "
          >
            <span class="language-name"
              >{{ language.name }} - {{ language.code.toUpperCase() }}</span
            >
          </base-checkbox>
          <div v-else class="mt-3">
            <i class="ni ni-check-bold text-primary language-check mr-2"></i>
            <span class="language-name"
              >{{ language.name }} - {{ language.code.toUpperCase() }}</span
            >
          </div>
        </div>
      </b-col>
      <b-col cols="12 mt-2">
        <label>{{ $t('description') }}</label>
        <textarea
          rows="4"
          v-model="basic.description"
          class="form-control form-control"
          :placeholder="$t('description_tip')"
          data-testid="description-input"
        />
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col>
        <base-button
          type="primary"
          :disabled="!validForm || validating"
          @click="shouldValidate ? handleNextClick() : $emit('nextStep')"
          data-testid="next-button"
        >
          {{ $t('next') }}
          <b-spinner v-if="validating" small></b-spinner>
          <arrow-right v-else />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { fetchSites } from '@/api/site';
import { languagesDefault } from '@/constants/languages-constants';

export default {
  data() {
    return {
      initialSelectedLanguages: [],
      languageSelected: false,
      loadingProtocolsFilterData: false,
      validating: false,
      isEdit: this.$route.name.includes('edit'),
      isTopLevelProtocol: [
        'protocol-create-site',
        'protocol-edit-site',
      ].includes(this.$route.name),
      protocolsFilterData: [],
    };
  },
  props: {
    isProtocol: Boolean,
    hasParticipantsNotScreenedOut: {
      type: Boolean,
      default: () => false,
    },
    basic: {
      description: String,
      protocolId: String,
      name: String,
    },
  },
  computed: {
    ...mapState({
      study: (state) => state.studies.selectedStudy,
      site: (state) => state.sites.selectedSite,
      protocol: (state) => state.protocols.selectedProtocol,
    }),
    ...mapGetters({
      options: 'protocols/protocolOptions',
    }),
    validForm() {
      const { name, protocolId } = this.basic;
      const validData = !!name && !!protocolId;
      return validData && this.languageSelected;
    },
    shouldValidate() {
      return !this.isEdit || this.site.basic.name !== this.basic.name;
    },
    studyName() {
      return this.isProtocol ? this.protocol.study.name : this.study.name;
    },
    languagesList() {
      const studyLanguages = this.isProtocol
        ? this.protocol?.study?.studyFeaturesConfig?.languages ?? []
        : this.study?.studyFeaturesConfig?.languages ?? [];
      return languagesDefault.filter((language) =>
        studyLanguages.includes(language.code)
      );
    },
  },
  created() {
    this.initSelectedLanguages();
    this.getFilterData();
  },
  methods: {
    initSelectedLanguages() {
      const selectedLanguages = this.isEdit ? this.site.basic.languages : [];
      this.languageSelected = selectedLanguages.length > 0;
      this.basic.languages = [...selectedLanguages];
      this.initialSelectedLanguages = [...selectedLanguages];
    },
    async getFilterData() {
      this.loadingProtocolsFilterData = true;
      this.protocolsFilterData = [this.$t('loading')];
      try {
        const clientIdFilter = this.isProtocol
          ? this.protocol.study.clientId
          : this.study.clientId;
        const studyIdFilter = this.isProtocol
          ? this.protocol.study.id
          : this.study.id;
        const payload = {
          [`filter[client_id]`]: clientIdFilter,
          [`filter[study_id]`]: studyIdFilter,
        };

        await this.$store.dispatch('protocols/dispatchFetchProtocols', payload);
        this.protocolsFilterData = this.options(this.isTopLevelProtocol).filter(
          (i) => i.status === 'ACTIVE' || i.status === 'WAITING FOR APPROVAL'
        );
      } catch (error) {
        this.protocolsFilterData = [];
        console.error(error);
      } finally {
        this.loadingProtocolsFilterData = false;
      }
    },
    async handleNextClick() {
      this.validating = true;
      try {
        const { data } = await fetchSites({
          'filter[study_id]': this.isProtocol
            ? this.protocol.study.id
            : this.study.id,
          'filter[name]': this.basic.name,
        });

        data.length === 0
          ? this.$emit('nextStep')
          : this.$toast.error(this.$t('error_site_name_in_use'));
      } catch (error) {
        this.$toast.error(
          this.$t(error.message) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.validating = false;
      }
    },
    updateLanguages(value, languageCode) {
      const { languages } = this.basic;
      const index = languages.indexOf(languageCode);
      if (value && index < 0) {
        languages.push(languageCode);
      } else if (!value && index >= 0 && index < languages.length) {
        languages.splice(index, 1);
      }
      this.languageSelected = languages.length > 0;
    },
  },
};
</script>

<style></style>
