export default {
  // We transform response in objects array to use in step 2
  methods: {
    refactor_partner_category_test_list(participantTask, excludedGames) {
      const prepareArrayTasks = participantTask.map((task) => {
        const partner = {
          name: task.name,
          id: task.id,
          highlighted: false,
          categories: {},
        };

        task.categories.forEach((category, categoryIndex) => {
          partner.categories[categoryIndex] = {
            name: category.name,
            id: category.id,
            marked: false,
            tests: {},
          };

          category.tests.forEach((test, testIndex) => {
            partner.categories[categoryIndex].tests[testIndex] = {
              name: test.name,
              id: test.id,
              versions: {},
            };

            test.versions.forEach((version) => {
              const versionSelected = version.id;

              partner.categories[categoryIndex].tests[testIndex].versions = {
                id: version.id,
                testId: version.testId,
                availableForSelectedLanguage: test.availableForSelectedLanguage,
                disabled: excludedGames.includes(versionSelected),
                enabled: !excludedGames.includes(versionSelected),
                enabledDraft:
                  test.availableForSelectedLanguage === false
                    ? false
                    : !excludedGames.includes(versionSelected),
                version: version.version,
              };
            });
          });
        });

        return partner;
      });

      this.$store
        .dispatch('participants/saveTaskList', prepareArrayTasks)
        .then((resp) => {
          if (resp === 'ok') {
            this.loadCollapse = true;
          }
        });
    },
  },
};
