<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5455 11.6364V5.09091H4.36364V11.6364H14.5455ZM14.5455 1.45455C14.9312 1.45455 15.3012 1.60779 15.574 1.88057C15.8468 2.15335 16 2.52332 16 2.90909V11.6364C16 12.0221 15.8468 12.3921 15.574 12.6649C15.3012 12.9377 14.9312 13.0909 14.5455 13.0909H4.36364C3.97787 13.0909 3.6079 12.9377 3.33512 12.6649C3.06234 12.3921 2.90909 12.0221 2.90909 11.6364V2.90909C2.90909 2.52332 3.06234 2.15335 3.33512 1.88057C3.6079 1.60779 3.97787 1.45455 4.36364 1.45455H5.09091V0H6.54545V1.45455H12.3636V0H13.8182V1.45455H14.5455ZM1.45455 14.5455H11.6364V16H1.45455C1.06878 16 0.698807 15.8468 0.426027 15.574C0.153246 15.3012 0 14.9312 0 14.5455V5.81818H1.45455V14.5455ZM13.0909 10.1818H10.1818V7.27273H13.0909V10.1818Z"
      fill="#046DB3"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
