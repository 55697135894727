<template>
  <div class="mt-4">
    <div
      v-if="canCreateParticipant && displayAddParticipantButton"
      class="d-flex flex-row-reverse my-4"
    >
      <base-button type="primary" rounded @click="addParticipant">
        {{ $t('participant_add') }}
      </base-button>
    </div>
    <ParticipantList
      :filterData="filterParticipantsData"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ParticipantList from '@/components/Participants/List/ParticipantList.vue';

export default {
  name: 'QuickParticipantListContainerView',
  components: {
    ParticipantList,
  },
  computed: {
    ...mapState({
      study: ({ studies }) => studies.selectedStudy,
      protocol: ({ protocols }) => protocols.selectedProtocol,
      site: ({ sites }) => sites.selectedSite,
    }),
    displayAddParticipantButton() {
      return this.$route.name.includes('site-participants');
    },
    filterParticipantsData() {
      switch (this.$route.name) {
        case 'client-participants':
        case 'study-participants':
          return { studyId: this.study?.id };
        case 'client-protocol-participants':
        case 'study-protocol-participants':
        case 'protocol-participants':
          return { protocolId: this.protocol?.id };
        case 'client-site-participants':
        case 'study-site-participants':
        case 'protocol-site-participants':
        case 'site-participants':
          return { siteId: this.site?.basic?.id };
        default:
          return undefined;
      }
    },
  },
  methods: {
    addParticipant() {
      this.$router.push({
        name: 'add-participant',
        params: { fromRouteName: this.$route.name },
      });
    },
    goToDetail(participantDisplayId) {
      this.$router.push({
        name: `${this.$route.meta.parent}-view-participant`,
        params: { id: participantDisplayId },
      });
    },
  },
};
</script>
