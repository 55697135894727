<template>
  <div>
    <div :class="{ 'loading-view': !siteLanguages.length }">
      <ValidationObserver v-slot="{ valid }">
        <p class="opacity-6">{{ $t('study_information') }}</p>
        <b-row>
          <b-col cols="4">
            <label>{{ $t('study') }}</label>
            <p class="opacity-6">
              {{
                addParticipantFromSitesView ? study.name : association.studyName
              }}
            </p>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('protocol') }}</label>
            <p class="opacity-6">
              {{
                addParticipantFromSitesView
                  ? siteProtocol.name
                  : association.protocolName
              }}
            </p>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('site') }}</label>
            <p class="opacity-6">
              {{
                addParticipantFromSitesView
                  ? site.basic.name
                  : association.siteName
              }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <label>{{ $t('study_start_date') }}</label>
            <p class="opacity-6">{{ calculateStartDate }}</p>
          </b-col>
          <b-col cols="6">
            <label>{{ $t('study_end_date_planned') }}</label>
            <p class="opacity-6">
              {{ moment(calculateEndDate).format('DD/MM/YYYY') }}
            </p>
          </b-col>
        </b-row>

        <h2 class="mt-4">{{ $t('participant_information') }}</h2>
        <b-row>
          <b-col cols="4">
            <label>{{ $t('phone_number_mobile') }}</label>
            <phone-number-input v-model="phoneNumber" />
          </b-col>
          <b-col cols="4">
            <label>{{ $t('participant_id_subject') }}</label>
            <b-form-input
              v-model="info.subjectCode"
              placeholder="Subject ID (unique)"
              maxLength="100"
              id="subject-id"
              data-testid="subject-input"
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('type') }}</label>
            <b-form-radio-group v-model="info.tags" data-testid="tags-inputs">
              <b-form-radio class="mr-5" :value="1" data-testid="tags-patient">
                <span class="opacity-6">{{ $t('patient') }}</span>
              </b-form-radio>
              <b-form-radio :value="2" data-testid="tags-healthy">
                <span class="opacity-6">{{ $t('healthy_control') }}</span>
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="4">
            <label>{{ $t('gender') }}</label>
            <b-form-radio-group
              v-model="info.gender"
              data-testid="gender-inputs"
            >
              <b-form-radio class="mr-5" :value="1" data-testid="male-radio">
                <span class="opacity-6">{{ $t('gender_male') }}</span>
              </b-form-radio>
              <b-form-radio :value="2" data-testid="female-radio">
                <span class="opacity-6">{{ $t('gender_female') }}</span>
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('height') }}</label>
            <ValidationProvider
              name="height"
              rules="between:100,300"
              v-slot="{ errors }"
            >
              <b-input-group class="w-50">
                <template #append>
                  <b-input-group-text class="w-50"
                    ><span class="opacity-6">{{
                      $t('unit_cm')
                    }}</span></b-input-group-text
                  >
                </template>
                <b-form-input
                  type="number"
                  v-model.number="info.height"
                  data-testid="height-input"
                ></b-form-input>
              </b-input-group>
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('weight') }}</label>
            <ValidationProvider
              name="weight"
              rules="between:10,999"
              v-slot="{ errors }"
            >
              <b-input-group class="w-50">
                <template #append>
                  <b-input-group-text
                    ><span class="opacity-6">{{
                      $t('unit_kg')
                    }}</span></b-input-group-text
                  >
                </template>
                <b-form-input
                  type="number"
                  v-model.number="info.weight"
                  data-testid="weight-input"
                ></b-form-input>
              </b-input-group>
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="4">
            <label> {{ $t('icf_signed') }}</label>
            <b-form-radio-group
              v-model="info.icfSigned"
              data-testid="icf-inputs"
            >
              <b-form-radio
                class="mr-5"
                :value="true"
                data-testid="icf-true-value"
              >
                <span class="opacity-6">{{ $t('yes') }}</span>
              </b-form-radio>
              <b-form-radio :value="false" data-testid="icf-false-value">
                <span class="opacity-6">{{ $t('no') }}</span>
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <label> {{ $t('language') }}</label>
            <b-form-radio-group
              v-model="info.language"
              data-testid="language-inputs"
              class="lang-container"
            >
              <b-form-radio
                v-for="(language, i) in siteLanguages"
                :key="i"
                class="child"
                :value="language"
                data-testid="male-radio"
              >
                <span class="opacity-6"
                  >{{ languageNameFromCode(language) }} -
                  {{ language.toUpperCase() }}</span
                >
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <b-row class="mt-4" v-if="info.partners.length > 0">
          <b-col v-for="partner in partners" :key="partner.id">
            <h2 class="mt-4 text-lg">
              {{ $t('devices_third_party') }}
            </h2>
            <p class="opacity-6">{{ $t('third_party_info') }}</p>
            <b-form-checkbox
              v-model="
                info.partners.filter((p) => p.partnerId === partner.id)[0]
                  .isEnabled
              "
              name="check-button"
              switch
            >
              <span class="avenir-bold">{{ partner.name }}</span>
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col>
            <base-button
              type="primary"
              :disabled="!validForm || loading || !valid"
              @click="handleNextClick"
              rounded
              data-testid="next-button"
            >
              {{ $t('next') }}
              <b-spinner v-if="loading" small></b-spinner>
              <arrow-right v-else />
            </base-button>
          </b-col>
          <b-col> </b-col>
        </b-row>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import PhoneNumberInput from '../../PhoneNumberInput.vue';
import { fetchUsers } from '@/api/users';
import {
  fetchParticipants,
  checkAvailabilityForStudy,
} from '@/api/participant';
import { languagesDefault } from '@/constants/languages-constants';

import { mapGetters, mapState } from 'vuex';

export default {
  components: { PhoneNumberInput, ValidationObserver },

  data() {
    return {
      endDate: '',
      phoneNumber: '',
      tag: [],
      loading: false,
      siteData: {},
      siteLanguages: [],
      languagesList: [...languagesDefault],
      isProtocolView: this.$route.name === 'protocol-create-participant',
      participantParams: {},
      addedByClientUser: this.$route.name === 'add-participant',
      addParticipantFromSitesView:
        this.$route.params.fromRouteName === 'site-participants',
      participantSubjectId: '',
    };
  },
  props: {
    info: Object,
  },

  created() {
    if (this.addParticipantFromSitesView) {
      this.siteLanguages = this.site.basic.languages;
    } else {
      this.getSiteParams();
    }
  },

  computed: {
    validForm() {
      const { icfSigned, gender, height, weight, tags, language } = this.info;
      return (
        !!this.phoneNumber &&
        icfSigned &&
        !!gender &&
        !!height &&
        !!weight &&
        !!tags &&
        !!language
      );
    },
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      study: ({ studies }) => studies.selectedStudy,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      association: ({ participants }) => participants.association,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),

    calculateEndDate() {
      const { studyDurationPerParticipant } = this.siteProtocol;
      let now = new Date();
      return now.setDate(now.getDate() + studyDurationPerParticipant * 7);
    },

    calculateStartDate() {
      return this.moment().startOf('isoWeek').format('DD/MM/YYYY');
    },
  },

  methods: {
    getSiteParams() {
      const { clientId, studyId, protocolId, siteId } = this.association;
      const payload = {
        clientId,
        studyId,
        protocolId,
        siteId,
      };
      this.getSiteDetails(payload);

      this.participantParams = {
        ...payload,
        subjectId: this.info.subjectCode,
      };
    },

    async getSiteDetails(params) {
      try {
        await this.$store
          .dispatch('sites/GET_SITE_DATA', params)
          .then((resp) => {
            this.siteLanguages = resp.site.languages;
          });
      } catch (error) {
        this.$toast.error(
          this.$t(error?.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    languageNameFromCode(languageCode) {
      const filtered = this.languagesList.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },
    async handleNextClick() {
      try {
        this.loading = true;
        const isValidPhone = await this.validatePhone();
        let isValidSubject = true;
        if (this.info.subjectCode) {
          isValidSubject = await this.validateSubjectCode();
        }
        if (isValidPhone && isValidSubject) {
          this.$emit('nextStep', this.calculateEndDate);
        }
      } catch (error) {
        const errorMessage =
          error?.msg || error?.data?.msg || 'error_something_went_wrong';
        this.$toast.error(this.$t(errorMessage));
      } finally {
        this.loading = false;
      }
    },
    async validatePhone() {
      const response = await fetchUsers({
        'filter[phone]': this.phoneNumber,
      });
      if (response.code === 200) {
        this.participantSubjectId = response.data.subjectId;
        const { code } = await checkAvailabilityForStudy({
          ...this.participantParams,
          subjectId: this.participantSubjectId,
        });
        if (code === 201) {
          this.$emit('studyAvailability', this.participantSubjectId);
          return true;
        } else {
          this.$toast.error(this.$t('error_phone_already_in_use'));
          return false;
        }
      } else {
        this.info.phone = this.phoneNumber;
        return true;
      }
    },
    async validateSubjectCode() {
      try {
        const { data } = await fetchParticipants({
          'filter[subject_code]': this.info.subjectCode,
        });
        if (data?.length === 0) {
          return true;
        } else {
          this.$toast.error(this.$t('error_subject_id_not_valid'));
        }
      } catch (error) {
        this.$toast.error(this.$t(error.msg));
      }
    },
  },
};
</script>

<style scoped>
.input-group-text {
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
  color: inherit;
  border-radius: 0px;
  font-family: 'Avenir-Bold';
}
.lang-container {
  width: 380px;
  display: flex;
  flex-wrap: wrap;
}

.child {
  flex: 40%;
}
.text-lg {
  font-size: 1.125rem !important;
}
.loading-view {
  animation: loading-animation 1s infinite;
}
@keyframes loading-animation {
  0% {
    opacity: 40%;
  }
  100% {
    opacity: 20%;
  }
}
</style>
