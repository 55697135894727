<template>
  <div class="p-5">
    <div v-if="isSearchPage && searchText" class="d-flex flex-row">
      <p class="mb-0">{{ $t('search_results_for') }} “{{ searchText }}”</p>
      <button
        @click="cleanParticipantSearch()"
        class="clear-search-button ml-2"
      >
        <ClearIcon color-class="primary" />
      </button>
    </div>

    <ListFiltersComponent
      v-if="showListFilters"
      :filtersToDisplay="filtersToDisplay"
      @changeFilterEvent="handleChangeFilterEvent"
    />
    <ParticipantList
      :displayAddParticipantButton="false"
      :filterData="filterParticipantsData"
      @goToDetail="goToDetail"
    />
  </div>
</template>

<script>
import ClearIcon from '@/components/Icons/ClearIcon.vue';
import ParticipantList from '@/components/Participants/List/ParticipantList.vue';
import ListFiltersComponent from '@/shared/components/ListFiltersComponent.vue';
import { mapState } from 'vuex';
import isClientUserMixin from '@/mixins/isClientUserMixin';

export default {
  name: 'ParticipantListContainerView',
  components: {
    ListFiltersComponent,
    ParticipantList,
    ClearIcon,
  },
  mixins: [isClientUserMixin],
  data() {
    return {
      filterParticipantsData: {},
      filtersToDisplay: {
        client: false,
        study: true,
        protocol: true,
        site: true,
      },
      showListFilters: false,
    };
  },
  watch: {
    searchText: {
      handler(newSearchText) {
        this.filterParticipantsData = {
          ...this.filterParticipantsData,
          participantDisplayId: newSearchText,
        };
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      userType: ({ auth }) => auth.userType,
    }),
    isSearchPage() {
      return this.$route.name === 'participants-search';
    },
    searchText() {
      return this.$route.query.s;
    },
  },

  created() {
    this.setBreadCrumbData({
      'participants-search': [
        {
          text: this.$t('participants'),
          to: { name: 'participants' },
        },
        {
          text: this.$t('search_results'),
          active: true,
        },
      ],
      participants: [
        {
          text: this.$t('participants'),
          active: true,
        },
      ],
    });
  },
  methods: {
    goToDetail(participantDisplayId) {
      this.$router.push({
        name: 'view-participant',
        params: { id: participantDisplayId },
      });
    },
    handleChangeFilterEvent(newFilter) {
      this.filterParticipantsData = newFilter;
    },
    cleanParticipantSearch() {
      this.$router.push({ name: 'participants' });
    },
  },
};
</script>

<style scoped lang="scss">
.clear-search-button {
  background: none;
  border: none;
  height: 24px;
  margin: 0;
  padding: 0 3px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
