<template>
  <div>
    <b-row
      class="border-bottom mx-2 py-3"
      v-for="(test, testIndex) in tests"
      :key="testIndex"
    >
      <b-col cols="3">
        <div class="avenir-bold text-sm">{{ $t(test.name) }}</div>
      </b-col>

      <b-col cols="9">
        <b-row class="list-inline text-center">
          <b-col
            v-for="(week, weekIndex) in weeksToDisplay"
            :key="weekIndex"
            :set="(testStatus = testStatusForWeek(week, test.id))"
          >
            <scheduled-icon v-if="testStatus === testStatusToCheck.scheduled" />
            <check-ok v-else-if="testStatus === testStatusToCheck.completed" />
            <check-ko
              v-else-if="testStatus === testStatusToCheck.notCompleted"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CheckOk from '@/components/Icons/CheckOk.vue';
import CheckKo from '@/components/Icons/CheckKo.vue';
import ScheduledIcon from '@/components/Icons/ScheduledIcon.vue';

export default {
  name: 'ParticipantDetailStudyScheduleWeeksTasks',
  components: { CheckOk, CheckKo, ScheduledIcon },
  props: ['weeks', 'onlyActiveTasks', 'selectedWeekIndex'],
  computed: {
    weeksToDisplay() {
      const startOffset = this.selectedWeekIndex > 1 ? 2 : 1;
      const endOffset = 1;
      const weeks = this.selectedWeekIndex > 1 ? [] : [{ tests: [] }];
      this.weeks
        .slice(
          this.selectedWeekIndex - startOffset,
          this.selectedWeekIndex + endOffset
        )
        .forEach((week) => {
          weeks.push({ tests: week.tests });
        });
      return weeks;
    },
    tests() {
      const nonDuplicatedTests = [];
      const uniqueIds = [];

      this.weeks.map((week) => {
        const weekTests = week?.tests ? [...week.tests] : [];
        weekTests.map((test) => {
          const isDuplicate = uniqueIds.includes(test.testId);
          if (!isDuplicate) {
            uniqueIds.push(test.testId);
            if (!this.onlyActiveTasks || this.testIsActive(test.testId)) {
              nonDuplicatedTests.push({
                id: test.testId,
                name: test.testName,
              });
            }
          }
        });
      });

      return nonDuplicatedTests;
    },
  },
  data() {
    return {
      testStatusToCheck: {
        scheduled: 'scheduled',
        completed: 'completed',
        notCompleted: 'notCompleted',
      },
    };
  },
  methods: {
    testIsActive(testId) {
      return this.weeksToDisplay.reduce(
        (acc, week) =>
          acc || week.tests.findIndex((test) => test.testId === testId) > -1,
        false
      );
    },
    testStatusForWeek(week, testId) {
      const index = week.tests.findIndex((test) => test.testId === testId);
      return index > -1 ? week.tests[index].status : '';
    },
  },
};
</script>

<style scoped lang="scss">
.row.border-bottom:last-child {
  border: none !important;
}
</style>
