<template>
  <div :class="isTopLevelView && 'm-5'">
    <div class="text-center" v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <b-row v-else>
      <b-col cols="8">
        <DraftUpdateParticipantStatusComponent
          v-if="participantIsDraft"
          @reload-parent="getParticipantData"
          @activate-parent="activateParticipant"
        ></DraftUpdateParticipantStatusComponent>

        <ParticipantChartWeekPickerComponent
          v-if="statistics && !participantIsScreenedOut && !participantIsDraft"
          :statisticsData="statistics"
          :statisticsColors="taskStatusColors"
          :participantDateStart="participant.dateStart"
          :participantDateEnd="participant.dateEnd"
          @get-participant-statistics="getStatistics"
        ></ParticipantChartWeekPickerComponent>

        <RelapseTableComponent
          v-if="
            showRelapsesTable &&
            !participantStatusDiscontinue &&
            !participantIsScreenedOut &&
            !participantIsDraft
          "
          :relapsesData="relapsesList"
        ></RelapseTableComponent>

        <ParticipantRelapseScheduleStatusComponent
          v-if="
            !participantStatusDiscontinue &&
            !participantIsScreenedOut &&
            !participantIsDraft &&
            Object.keys(relapseSchedule).length > 0
          "
          :relapseScheduleData="relapseSchedule"
        ></ParticipantRelapseScheduleStatusComponent>

        <ParticipantDetailStudySchedule
          v-if="!participantStatusDiscontinue && !participantIsScreenedOut"
          :participantParams="participantParams"
          :participantIsDraft="participantIsDraft"
          :key="reloadTasks"
        />

        <NoteWidgetComponent
          :displayCreateNote="!participantIsScreenedOut"
        ></NoteWidgetComponent>

        <change-history-table
          v-if="showChangesTable && !participantStatusDiscontinue"
          view="view-participant"
          :tableData="participantChangesList"
          :initial-pagination-values="paginationValues"
          style="padding-bottom: 20px"
          :key="updateChangesTable"
        ></change-history-table>
      </b-col>
      <b-col>
        <participant-sideBar
          :participant="participant"
          :discontinuedParticipant="participantStatusDiscontinue"
          :draftParticipant="participantIsDraft"
          @get-participantData="getParticipantData"
          @open-modal="isDropOutModalOn = true"
          :key="reloadKey"
        />
      </b-col>

      <ModalDiscontinuedParticipant
        v-if="isDropOutAvailable"
        :modal-visible="isDropOutModalOn"
        @close-modal="isDropOutModalOn = false"
        @reload-data="reloadParticipantData"
        :participantData="participant"
      ></ModalDiscontinuedParticipant>
    </b-row>
  </div>
</template>

<script>
import ParticipantSideBar from '@/components/Participants/ParticipantSideBar';
import {
  fetchParticipantStatistics,
  fetchParticipantRelapseSchedule,
} from '@/api/participant';
import { mapState, mapGetters, mapActions } from 'vuex';
import DatePickerMixin from '@/mixins/DatePickerMixin';
import ChangeHistoryTable from '@/components/Tables/ChangeHistoryTable';
import NoteWidgetComponent from '@/components/Participants/NoteWidgetComponent/NoteWidgetComponent.vue';
import ParticipantChartWeekPickerComponent from '@/components/Participants/Details/ParticipantChartWeekPickerComponent.vue';
import ParticipantDetailStudySchedule from '@/components/Participants/Details/ParticipantDetailStudyScheduleComponent.vue';
import ParticipantRelapseScheduleStatusComponent from '@/components/Participants/ParticipantRelapseScheduleStatusComponent.vue';
import DraftUpdateParticipantStatusComponent from '@/components/Participants/DraftUpdateParticipantStatusComponent.vue';
import RelapseTableComponent from '@/components/Tables/RelapseTableComponent.vue';
import ModalDiscontinuedParticipant from '@/components/Participants/ModalDiscontinuedParticipant.vue';
import {
  participantStatusId,
  participantStatusName,
} from '@/constants/participantStatuses';

export default {
  name: 'view-participant',
  components: {
    ChangeHistoryTable,
    RelapseTableComponent,
    ParticipantDetailStudySchedule,
    ParticipantRelapseScheduleStatusComponent,
    ParticipantChartWeekPickerComponent,
    DraftUpdateParticipantStatusComponent,
    ParticipantSideBar,
    NoteWidgetComponent,
    ModalDiscontinuedParticipant,
  },
  mixins: [DatePickerMixin],

  data() {
    return {
      participantStatusId,
      participantStatusName,
      isDropOutModalOn: false,
      paginationValues: null,
      loading: true,
      statistics: undefined,
      taskStatusColors: [],
      studySchedule: {},
      relapseSchedule: {},
      isTopLevelView: this.$route.name === 'view-participant',
      showRelapsesTable: false,
      showChangesTable: false,
      participantIsActive: false,
      participantIsScreenedOut: false,
      participantIsDraft: false,
      reloadTasks: 1,
      reloadKey: 1,
      updateChangesTable: 1,
      showActivateParticipantModal: false,
      activatingParticipant: false,
    };
  },

  computed: {
    ...mapGetters({ dropped: 'participants/isParticipantDroppedOut' }),
    ...mapState({
      participant: ({ participants }) => participants.selectedParticipant,
      participantParams: ({ participants }) => participants.participantParams,
      participantChangesList: ({ participantsChanges }) =>
        participantsChanges.list,
      relapsesList: ({ relapses }) => relapses.list,
      selectedClient: ({ clients }) => clients.selectedClient,
      selectedStudy: ({ studies }) => studies.selectedStudy,
      selectedSite: ({ sites }) => sites.selectedSite,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
    }),
    participantStatusDiscontinue() {
      return (
        this.participant?.status?.id === participantStatusId.discontinuedReason
      );
    },
    isDropOutAvailable() {
      return (
        this.$store.getters['auth/canWriteParticipant'] &&
        (this.participant?.status?.name === 'ACTIVE' ||
          this.participant?.status?.name === 'PENDING')
      );
    },
  },

  created() {
    this.getParticipantData();
    this.getStatistics(
      this.getMondayOfCurrentWeek(),
      this.getSundayOfCurrentWeek()
    );
  },

  methods: {
    ...mapActions({
      fetchParticipant: 'participants/dispatchFetchParticipant',
      fetchParticipantParams: 'participants/fetchParticipantParams',
      fetchParticipantChangeRecords:
        'participantsChanges/dispatchFetchParticipantChangeRecords',
      fetchParticipantRelapses: 'relapses/dispatchFetchParticipantRelapses',
    }),
    reloadParticipantData() {
      this.getParticipantData();
    },
    activateParticipant() {
      this.participantIsDraft = false;
      this.activatingParticipant = true;
      this.getParticipantData();
    },
    async getParticipantData() {
      try {
        if (Object.keys(this.participantParams).length === 0) {
          await this.fetchParticipantParams({
            [`filter[participant_display_id]`]: this.$route.params.id,
          });
        }
        await this.fetchParticipant(this.participantParams);
        this.setParticipantStatus(this.participant.status.name);
        await this.getParticipantRelapses();
        await this.getParticipantRelapseSchedule();
        if (this.activatingParticipant === false)
          await this.getParticipantChanges();
        this.reloadKey++;
        this.setBreadCrumbData({
          'client-view-participant': [
            {
              text: this.$t('clients'),
              to: { name: 'clients' },
            },
            {
              text: this.selectedClient.basic?.legalName,
              to: { name: 'client-details' },
            },
            {
              text: this.selectedStudy?.name,
              to: { name: 'client-study-details' },
            },
            {
              text: this.participant.participantDisplayId,
              active: true,
            },
          ],
          'study-view-participant': [
            {
              text: this.$t('studies'),
              to: { name: 'studies' },
            },
            {
              text: this.selectedStudy?.name,
              to: { name: 'study-details' },
            },
            {
              text: this.participant.participantDisplayId,
              active: true,
            },
          ],
          'protocol-view-participant': [
            {
              text: this.$t('protocols'),
              to: { name: 'protocols' },
            },

            {
              text: this.selectedProtocol?.name,
              to: { name: 'view-protocol' },
            },

            {
              text: this.participant.participantDisplayId,
              active: true,
            },
          ],
          'site-view-participant': [
            {
              text: this.$t('sites'),
              to: { name: 'sites' },
            },

            {
              text: this.selectedSite.basic?.name,
              to: { name: 'view-site' },
            },
            {
              text: this.participant.participantDisplayId,
              active: true,
            },
          ],
          'view-participant': [
            {
              text: this.$t('participants'),
              to: { name: 'participants' },
            },

            {
              text: this.participant.participantDisplayId,
              active: true,
            },
          ],
        });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.loading = false;
        if (this.activatingParticipant) {
          let counter = 0;
          let interval = setInterval(() => {
            this.getParticipantChanges();
            counter++;
            const endCondition = this.participantChangesList.some(
              (item) =>
                item.details?.status?.oldValue ===
                this.participantStatusName.draft
            );
            if (endCondition) {
              this.activatingParticipant = false;
              clearInterval(interval);
            }
            if (counter === 5) {
              this.activatingParticipant = false;
              clearInterval(interval);
            }
          }, 5000);
        }
      }
    },
    setParticipantStatus(participantStatus) {
      switch (participantStatus) {
        case 'DRAFT':
          this.participantIsDraft = true;
          break;
        case 'SCREENED OUT':
          this.participantIsScreenedOut = true;
          this.participantIsDraft = false;
          break;
        case 'DISCONTINUED':
          this.participantStatusDiscontinue =
            this.participant.discontinuedReason.discontinuedReason ===
            'dropped-out';
          break;
        default:
          this.participantIsActive = true;
      }
    },
    async getStatistics(dateStart, dateEnd) {
      this.submitting = true;
      try {
        const payload = { ...this.participantParams, dateStart, dateEnd };
        const {
          data: { tests },
        } = await fetchParticipantStatistics(payload);
        this.statistics = { ...tests };
        this.taskStatusColors = this.getTaskStatusColors(tests);
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.submitting = false;
      }
    },
    async getParticipantRelapses() {
      try {
        const { participantId } = this.participantParams;
        const payload = { 'filter[participant_id]': participantId };
        await this.fetchParticipantRelapses(payload);
        this.showRelapsesTable = this.relapsesList.length > 0;
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    async getParticipantRelapseSchedule() {
      try {
        const payload = { ...this.participantParams };
        const { data } = await fetchParticipantRelapseSchedule(payload);
        this.relapseSchedule = data;
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    async getParticipantChanges() {
      const { participantId } = this.participantParams;

      try {
        const payload = {
          'filter[participant_id]': participantId,
          limit: 5,
          offset: 0,
          sort: '-created_at',
        };
        await this.fetchParticipantChangeRecords(payload);
        this.showChangesTable = this.participantChangesList.length > 0;
        this.updateChangesTable++;
      } catch (error) {
        this.$toast.error(
          this.$t(error.data?.msg) || this.$t('error_something_went_wrong')
        );
      }
    },
    getTaskStatusColors(tests) {
      return [
        {
          label: 'notCompleted',
          color: '#046db3',
          value: tests.notCompleted,
        },
        {
          label: 'completed',
          color: '#f58b44',
          value: tests.completed,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.border {
  border: 1px solid #e9ecef;

  &-left {
    border-left: 1px solid #e9ecef;
  }

  &-right {
    border-right: 1px solid #e9ecef;
  }

  &-top {
    border-top: 1px solid #e9ecef;
  }

  &-bottom {
    border-bottom: 1px solid #e9ecef;
  }
}

.upper-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  @include desktop-up {
    flex-direction: row;
  }

  h3 {
    padding: 20px;
    color: #525f7f;
    border-bottom: 1px solid #e9ecef;
    margin-bottom: 0;
  }

  .select-period {
    h3 {
      @include desktop-up {
        border-left: 1px solid #e9ecef;
      }
    }

    .content {
      padding: 10px 20px 20px;
      @include desktop-up {
        border-left: 1px solid #e9ecef;
        height: 276px;
      }
    }
  }

  .schedule-adherence {
    @include desktop-up {
      width: 65%;
    }

    .schedule-adherence-content {
      height: 276px;
    }
  }
}

.badge {
  text-transform: capitalize;
}

.badge-success {
  background-color: var(--green);
}

.badge-danger {
  background-color: #f35656;
}

.badge-orange {
  background-color: #f58b44;
}

.badge-purple {
  background-color: #41479b;
}

.collapse-body.collapsed {
  border-bottom: none !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.collapse-title {
  font-size: 1.125rem;
}

.table-card {
  padding: 0 !important;
}

.bg-off-white {
  background-color: #f7f7fa;
}
</style>
