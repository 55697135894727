<template>
  <div :class="{ 'p-5': $route.name === 'add-participant' }">
    <wizard-header
      :title="this.$t('participant_add_new')"
      @goBack="showConfirmationModal = true"
    />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard
            :currentStep="step"
            wizardType="createParticipants"
          ></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5">
      <step-one
        :info="participant"
        v-show="step === 0"
        @studyAvailability="handleSubjectId"
        @nextStep="handleNext"
      />
      <step-two
        v-if="step === 1"
        :rochePartner="participant.partners[0]"
        :participant="participant"
        v-show="step === 1"
        @nextStep="handleTailorScheduleNextClick"
        @back="step--"
      />
      <step-three :participant="participant" v-if="step === 2" @back="step--" />
    </div>

    <b-modal
      v-model="showConfirmationModal"
      title="Add new Participant"
      footer-class="justify-content-center"
      header-class="justify-content-center"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center">
        {{ $t('exit_add_participant') }}
      </p>
      <template #modal-footer="{ hide }">
        <b-button
          variant="secondary"
          class="rounded-pill"
          @click="
            $router.push({
              name:
                $route.name === 'add-participant'
                  ? 'participants'
                  : $route.name === 'site-create-participant'
                  ? 'site-participants'
                  : `${$route.meta.parent}-site-participants`,
            })
          "
        >
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="hide()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import StepOne from '@/components/Participants/StepWizard/StepOne';
import StepTwo from '@/components/Participants/StepWizard/StepTwo';
import StepThree from '@/components/Participants/StepWizard/StepThree';
import WizardHeader from '@/components/WizardHeader';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree,
    WizardHeader,
  },
  data() {
    return {
      showConfirmationModal: false,
      step: 0,
      subjectIdFromStepOne: '',
      participant: {
        subjectCode: '',
        phone: '',
        icfSigned: '',
        gender: null,
        height: null,
        weight: null,
        partners: [],
        tags: null,
        language: '',
        subjectId: null,
      },
    };
  },
  computed: {
    ...mapState({
      site: (state) => state.sites.selectedSite,
      study: (state) => state.studies.selectedStudy,
      selectedClient: (state) => state.clients.selectedClient,
      protocol: ({ protocols }) => protocols.selectedProtocol,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),
  },

  created() {
    this.fetchPartners().finally(() => {
      let existedPartners = [];
      if (this.partners) {
        this.partners.map((partner) =>
          existedPartners.push({
            partnerId: partner.id,
            isEnabled: true,
            name: partner.name,
          })
        );
        this.participant.partners = existedPartners;
      }
    });
    this.setBreadCrumbData({
      'client-create-participant': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.study?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'client-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'study-create-participant': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },

        {
          text: this.study?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'study-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'protocol-create-participant': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },

        {
          text: this.protocol?.name,
          to: { name: 'view-protocol' },
        },
        {
          text: this.site?.basic?.name,
          to: { name: 'protocol-view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],

      'site-create-participant': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },

        {
          text: this.site?.basic?.name,
          to: { name: 'view-site' },
        },
        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
      'add-participant': [
        {
          text: this.$t('participants'),
          to: { name: 'participants' },
        },

        {
          text: this.$t('participant_add_new'),
          active: true,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      fetchPartners: 'resources/dispatchFetchPartners',
    }),
    handleNext(endDate) {
      this.participant.endDate = endDate;
      this.step++;
    },
    handleTailorScheduleNextClick(games) {
      this.participant.games = games;
      this.step++;
    },
    handleSubjectId(value) {
      if (value) this.participant.subjectId = value;
    },
  },
};
</script>

<style></style>
