var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'p-5': _vm.$route.name === 'add-participant' }},[_c('wizard-header',{attrs:{"title":this.$t('participant_add_new')},on:{"goBack":function($event){_vm.showConfirmationModal = true}}}),_c('div',{staticClass:"bg-white border-bottom"},[_c('b-container',[_c('div',{staticClass:"py-4"},[_c('step-wizard',{attrs:{"currentStep":_vm.step,"wizardType":"createParticipants"}})],1)])],1),_c('div',{staticClass:"bg-white p-5"},[_c('step-one',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 0),expression:"step === 0"}],attrs:{"info":_vm.participant},on:{"studyAvailability":_vm.handleSubjectId,"nextStep":_vm.handleNext}}),(_vm.step === 1)?_c('step-two',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}],attrs:{"rochePartner":_vm.participant.partners[0],"participant":_vm.participant},on:{"nextStep":_vm.handleTailorScheduleNextClick,"back":function($event){_vm.step--}}}):_vm._e(),(_vm.step === 2)?_c('step-three',{attrs:{"participant":_vm.participant},on:{"back":function($event){_vm.step--}}}):_vm._e()],1),_c('b-modal',{attrs:{"title":"Add new Participant","footer-class":"justify-content-center","header-class":"justify-content-center","hide-header-close":"","no-close-on-backdrop":"","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var hide = ref.hide;
return [_c('b-button',{staticClass:"rounded-pill",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push({
            name:
              _vm.$route.name === 'add-participant'
                ? 'participants'
                : _vm.$route.name === 'site-create-participant'
                ? 'site-participants'
                : ((_vm.$route.meta.parent) + "-site-participants"),
          })}}},[_vm._v(" "+_vm._s(_vm.$t('yes_cancel'))+" ")]),_c('b-button',{staticClass:"rounded-pill",attrs:{"variant":"primary"},on:{"click":function($event){return hide()}}},[_vm._v(" "+_vm._s(_vm.$t('no_continue'))+" ")])]}}]),model:{value:(_vm.showConfirmationModal),callback:function ($$v) {_vm.showConfirmationModal=$$v},expression:"showConfirmationModal"}},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('exit_add_participant'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }