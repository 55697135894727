<template>
  <b-modal
    :visible="modalVisible"
    id="exit-modal"
    ref="exitModal"
    hide-header-close
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="mx-auto">
        <h3 class="mb-0">{{ $t('update_participant_visit_date') }}</h3>
      </div>
    </template>
    <template v-if="showDateSelector">
      <b-row class="pt-3">
        <b-col md="5">
          <p class="opacity-6 text-sm mb-0">{{ $t('visit_name') }}</p>
          <p class="pt-2 font-weight-600 text-sm">
            {{ visitType === 'ANNUAL' ? 'Annual visit' : 'End of study' }}
          </p>
        </b-col>
        <b-col md="5">
          <p class="opacity-6 text-sm mb-0">{{ $t('visit_date') }}</p>
          <date-picker
            v-model="selectedDate"
            type="week"
            :lang="lang"
            format="YYYY-MM-DD"
            :default-value="new Date(this.minDate)"
            :disabled-date="disabledDatesInCalendar"
            :show-week-number="false"
          >
            <template slot="input">
              <b-form-input
                type="text"
                readonly
                v-model="transformedDate"
                :placeholder="this.scheduledDate"
            /></template>
            <template slot="icon-calendar">
              <img
                :src="require(`@/assets/icons/calendar.svg`)"
                class="calendar-icon"
              />
            </template>
          </date-picker>
        </b-col>
      </b-row>
      <b-row> </b-row>
      <b-row>
        <b-col md="12" class="mt-5 d-flex justify-content-around">
          <base-button type="secondary" @click="closeModal">{{
            $t('cancel')
          }}</base-button>
          <base-button
            type="primary"
            :disabled="!transformedDate.length"
            @click="showConfirmationMessage"
            >{{ $t('update') }}</base-button
          >
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <p>{{ $t('update_participant_visit_date_info') }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="mt-4 d-flex justify-content-around">
          <base-button
            type="secondary"
            :disabled="waitingResponse"
            @click="closeConfirmationMessage"
          >
            {{ $t('no_cancel') }}</base-button
          >
          <base-button
            type="primary"
            :disabled="waitingResponse"
            @click="updateVisitDate"
          >
            {{ $t('yes_continue')
            }}<b-spinner v-if="waitingResponse" small class="ml-2"></b-spinner
          ></base-button>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-footer>
      <div class="w-100 text-right"></div>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'ParticipantRescheduleVisitModal',
  components: { DatePicker },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    visitType: {
      type: String,
      default: '',
    },
    scheduledDate: {
      type: String,
      default: '',
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      selectedDate: '',
      showDateSelector: true,
      transformedDate: '',
      waitingResponse: false,
    };
  },

  watch: {
    selectedDate(newVal) {
      if (newVal === '' || newVal === null) {
        this.resetInputValue();
      } else {
        this.transformedDate = this.moment(newVal)
          .startOf('isoWeek')
          .format('DD/MM/YYYY');
      }
    },
  },

  computed: {
    ...mapState({
      participantNotes: (state) => state.participants.participantNotes,
      selectedParticipant: ({ participants }) => {
        const { clientId, studyId, protocolId, siteId, id } =
          participants.selectedParticipant;
        return { clientId, studyId, protocolId, siteId, id };
      },
    }),
    formattedMinDate() {
      let minDateMoment = this.moment(this.minDate);
      const inTwoWeeks = this.moment().startOf('day').add(14, 'days');

      // Update minDate if it is closer than 2 weeks from now
      if (minDateMoment.isBefore(inTwoWeeks)) minDateMoment = inTwoWeeks;

      // If minDate is not monday assign next monday
      if (minDateMoment.weekday() !== 1) {
        minDateMoment = minDateMoment.weekday(7);
      }

      return new Date(minDateMoment);
    },
    formattedMaxDate() {
      let maxDateMoment = this.moment(this.maxDate);

      // If maxDate is closer than (changed) formattedMinDate
      const minDateMoment = this.moment(this.formattedMinDate);
      if (maxDateMoment.isBefore(minDateMoment)) maxDateMoment = minDateMoment;

      // If maxDate is not Sunday assign next Sunday
      if (maxDateMoment.weekday() !== 0) {
        maxDateMoment = maxDateMoment.weekday(7);
      }

      return new Date(maxDateMoment);
    },
  },

  methods: {
    ...mapActions({
      setVisitsReschedule: 'participants/updateParticipantVisit',
    }),
    showConfirmationMessage() {
      this.showDateSelector = false;
    },
    closeConfirmationMessage() {
      this.showDateSelector = true;
    },

    updateVisitDate() {
      this.waitingResponse = true;
      let selectedDate = this.moment(this.transformedDate, 'DD-MM-YYYY');
      let newDate = {
        date: selectedDate.format('YYYY-MM-DD'),
        visitType: this.visitType,
      };
      let payload = {
        ...this.selectedParticipant,
      };
      payload = { ...payload, newDate };

      this.$store
        .dispatch('participants/updateParticipantVisit', payload)
        .then((resp) => {
          if (resp.code === 200) {
            this.$toast.info(this.$t('toast_participant_visit_date_updated'));
            this.$emit('updatedVisitDate', resp.data.scheduledDate);
          }
        })
        .catch(() => {
          this.$toast.error(this.$t('error_something_went_wrong'));
        })
        .finally(() => {
          this.waitingResponse = false;
          this.closeModal();
        });
    },

    closeModal() {
      //this.$refs.exitModal.hide();
      this.$emit('close-modal');
      this.resetInputValue();
      this.showDateSelector = true;
    },

    resetInputValue() {
      this.selectedDate = '';
      this.transformedDate = '';
    },

    disabledDatesInCalendar(date) {
      return date < this.formattedMinDate || date > this.formattedMaxDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  background: transparent;
  color: #565f77;
  height: 40px;

  &::placeholder {
    color: #565f77;
    font-size: 13px;
  }
}
.calendar-icon {
  position: relative;
}
</style>
