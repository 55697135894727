<template>
  <WebUICard>
    <b-spinner v-if="loading" class="mx-auto d-block"></b-spinner>
    <div class="bg-white shadow" v-else>
      <b-row
        class="collapse-body border-bottom mx-0 py-3 px-1"
        v-b-toggle.study-schedule-collapse
      >
        <b-col class="d-flex justify-content-between">
          <div class="avenir-bold collapse-title">
            {{ $t('participant_schedule_study') }}
          </div>
          <b-form-checkbox
            class="checkbox"
            name="check-tasks"
            switch
            v-model="onlyActiveTasks"
            >{{ $t('show_only_active_tasks') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-collapse id="study-schedule-collapse" visible>
        <template
          v-if="weeksData.length > 0 && participantDateStart.length > 0"
        >
          <div class="border-bottom">
            <ParticipantDetailStudyScheduleWeeksNav
              :weeks="weeksData"
              :selectedWeekIndex="selectedWeekIndex"
              :participantIsDraft="participantIsDraft"
              @selectWeek="handleChangeWeek"
            />
          </div>
          <div class="mt-3 px-1 mx-2 pb-4">
            <ParticipantStudyScheduleWeeksHeader
              :weeks="weeksData"
              :selectedWeekIndex="selectedWeekIndex"
              :participantIsDraft="participantIsDraft"
            />
            <ParticipantDetailStudyScheduleWeeksTasks
              :weeks="weeksData"
              :onlyActiveTasks="onlyActiveTasks"
              :selectedWeekIndex="selectedWeekIndex"
            />
          </div>
        </template>
      </b-collapse>
    </div>
  </WebUICard>
</template>

<script>
import { participantDetailStudyScheduleWeeksDataObjectMapper } from '@/object-mappers/participant.object-mapper';
import ParticipantStudyScheduleWeeksHeader from '@/components/Participants/Details/ParticipantDetailStudyScheduleWeeksHeaderComponent.vue';
import ParticipantDetailStudyScheduleWeeksTasks from '@/components/Participants/Details/ParticipantDetailStudyScheduleWeeksTasksComponent.vue';
import ParticipantDetailStudyScheduleWeeksNav from '@/components/Participants/Details/ParticipantDetailStudyScheduleWeeksNavComponent.vue';
import { fetchParticipantStudyScheduleFromWeek } from '@/api/participant';
import WebUICard from '@/shared/components/WebUICard.vue';
import {
  lastWeekMondayDate,
  dateToReverseDateString,
  getMondayFromDate,
  formatDate,
} from '@/utils/dateTransform';
import { isNil } from 'lodash/lang';
import { studyScheduleWeeksDefaults } from '@/constants/participant-study-schedule.constants';
import { mapState } from 'vuex';

export default {
  components: {
    ParticipantStudyScheduleWeeksHeader,
    ParticipantDetailStudyScheduleWeeksNav,
    ParticipantDetailStudyScheduleWeeksTasks,
    WebUICard,
  },
  props: ['participantParams', 'participantIsDraft'],
  computed: {
    ...mapState({
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
      userLanguage: ({ auth }) => auth.lang,
    }),
    participantDateStart() {
      if (this.participantIsDraft) {
        const createdDate = new Date(this.selectedParticipant.createdAt * 1000);
        return formatDate(getMondayFromDate(createdDate));
      }

      return this.selectedParticipant?.dateStart ?? null;
    },
  },
  data() {
    return {
      loading: false,
      onlyActiveTasks: false,
      weeksData: [],
      selectedWeekIndex: 0,
    };
  },
  created() {
    this.getParticipantStudySchedule();
  },
  methods: {
    async getParticipantStudySchedule() {
      if (this.participantDateStart?.length) {
        this.loading = true;

        try {
          const payload = this.getPayload();
          const response = await fetchParticipantStudyScheduleFromWeek(payload);
          this.weeksData = participantDetailStudyScheduleWeeksDataObjectMapper({
            weeks: response.data?.weeks ?? [],
            participantStartDateString: this.participantDateStart,
            locale: this.userLanguage?.replace('_', '-'),
          });
          this.selectedWeekIndex = this.initialSelectedNumWeek(this.weeksData);
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    getPayload() {
      return {
        ...this.participantParams,
        onlyActiveTasks: false,
        weeksToDisplay: this.weeksToDisplay(
          this.selectedParticipant?.weeksIntoStudy
        ),
        dateFrom: this.dateFrom(this.selectedParticipant),
      };
    },
    initialSelectedNumWeek(weeks) {
      const offset = this.participantIsDraft ? 1 : 2;
      const currentWeek = weeks[weeks.length - offset];
      return currentWeek?.numWeek ?? 0;
    },
    weeksToDisplay(weeksIntoStudy) {
      return !isNil(weeksIntoStudy) && weeksIntoStudy > 1
        ? weeksIntoStudy + studyScheduleWeeksDefaults.weeksIntoStudyOffset
        : studyScheduleWeeksDefaults.displayedNumOfWeeks;
    },
    dateFrom(selectedParticipant) {
      const dateStart = this.participantDateStart;
      const weeksIntoStudy = selectedParticipant?.weeksIntoStudy ?? 0;
      return dateToReverseDateString(
        !isNil(dateStart) && weeksIntoStudy > 0
          ? new Date(dateStart)
          : lastWeekMondayDate()
      );
    },
    handleChangeWeek(numWeekIndex) {
      this.selectedWeekIndex = numWeekIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  ::v-deep label {
    margin-top: 0 !important;
  }
}
</style>
