<template>
  <div>
    <div class="bg-white border">
      <b-row class="border-bottom mx-0 py-3 px-1 align-items-center">
        <b-col md="12" lg="6" class="pr-1">
          <span class="avenir-bold mb-0 text-sm">
            {{ $t('id') }}: {{ participant.participantDisplayId }}
          </span>
        </b-col>
        <b-col class="badges-container" md="12" lg="6">
          <WebBadgeComponent
            v-if="participantSubStatus && subStatusBadgeToggle"
            lg="12"
            :status="participantSubStatus"
          ></WebBadgeComponent>
          <WebBadgeComponent
            v-if="participantStatus"
            lg="12"
            class="ml-1"
            :status="participantStatus"
          ></WebBadgeComponent>
        </b-col>
      </b-row>

      <b-row class="mt-4 mx-0 px-1">
        <b-col>
          <p class="font-weight-600">{{ $t('personal_information') }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-0 px-1" d-flex justify-content-center>
        <b-col md="12" lg="6">
          <p class="opacity-6 text-sm">{{ $t('height') }}</p>
          <p class="font-weight-600 text-sm">
            {{ participant.height }} {{ $t('unit_cm') }}
          </p>
        </b-col>
        <b-col md="6" sm="12">
          <p class="opacity-6 text-sm">{{ $t('weight') }}</p>
          <p class="font-weight-600 text-sm">
            {{ participant.weight }} {{ $t('unit_kg') }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mx-0 mt-2 px-1" d-flex justify-content-center>
        <b-col md="12" lg="6">
          <p class="opacity-6 text-sm">{{ $t('gender') }}</p>
          <p class="font-weight-600 text-sm">
            {{
              participant.gender.name === 'gender_male'
                ? $t('gender_male')
                : $t('gender_female')
            }}
          </p>
        </b-col>
        <b-col md="12" lg="6">
          <p class="opacity-6 text-sm">{{ $t('phone') }}</p>
          <p class="font-weight-600 text-sm">{{ participant.phone }}</p>
        </b-col>
      </b-row>
      <b-row class="mx-0 mb-2 mt-2 px-1">
        <b-col>
          <p class="opacity-6 text-sm">{{ $t('participant_id_subject') }}</p>
          <p class="font-weight-600 text-sm">
            {{ participant.subjectCode }}
          </p>
        </b-col>
        <b-col>
          <p class="opacity-6 text-sm">{{ $t('type') }}</p>
          <p class="font-weight-600 text-sm">
            {{
              participant.tags.includes('healthy_control')
                ? $t('healthy_control')
                : $t('patient')
            }}
          </p>
        </b-col>
      </b-row>
      <b-row
        class="mx-0 mb-2 px-1 pb-4 mt-2"
        v-if="
          !$store.getters['participants/isParticipantDroppedOut'] &&
          $store.getters['auth/canWriteParticipant']
        "
      >
        <b-col
          v-if="$store.getters['auth/canManageUsers']"
          cols="12"
          class="d-flex justify-content-center"
        >
          <b-button
            v-if="showHideSidebarButton"
            pill
            variant="outline-primary"
            class="w-100"
            @click="
              $router.push({
                name: 'participant-update',
                params: { id: participant.participantDisplayId },
              })
            "
          >
            <span class="avenir-bold">{{ $t('update_information') }}</span>
          </b-button>
        </b-col>
      </b-row>

      <div class="border-bottom"></div>

      <b-row class="mt-4 mx-0 px-1">
        <b-col>
          <p class="font-weight-600">{{ $t('study_information') }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-0 px-1" d-flex justify-content-center>
        <b-col lg="12">
          <p class="opacity-6 text-sm">{{ $t('study_name') }}</p>
          <p class="font-weight-600 text-sm mt-0">
            {{ participant.studyName }}
          </p>
        </b-col>
        <b-col lg="12">
          <p class="opacity-6 text-sm">{{ $t('protocol_name') }}</p>
          <p class="font-weight-600 text-sm">
            {{ participant.protocolName }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-0 px-1">
        <b-col>
          <p class="opacity-6 text-sm">{{ $t('site_name') }}</p>
          <p class="font-weight-600 text-sm">
            {{ participant.siteName }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-0 px-1">
        <b-col>
          <p class="opacity-6 text-sm">{{ $t('study_start_date') }}</p>
          <p class="font-weight-600 text-sm">
            {{ moment(participant.dateStart).format('DD/MM/YYYY') }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-0 px-1">
        <b-col>
          <p class="opacity-6 text-sm">{{ $t('study_end_date') }}</p>
          <p class="font-weight-600 text-sm">
            {{
              $store.getters['participants/isParticipantDroppedOut']
                ? moment(participant.diabledAt).format('DD/MM/YYYY')
                : moment(participant.dateEnd).format('DD/MM/YYYY')
            }}
          </p>
        </b-col>
      </b-row>

      <template v-if="isDeviceInfoAvailable">
        <div class="border-bottom"></div>
        <b-row class="mt-4 mx-0 px-1">
          <b-col>
            <p class="font-weight-600">{{ $t('device_information') }}</p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-0 px-1">
          <b-col>
            <p class="opacity-6 text-sm">{{ $t('brand') }}</p>
            <p class="font-weight-600 text-sm">
              {{ participant.deviceInfo.brand }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-0 px-1">
          <b-col>
            <p class="opacity-6 text-sm">{{ $t('model') }}</p>
            <p class="font-weight-600 text-sm">
              {{ participant.deviceInfo.model }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-0 px-1">
          <b-col>
            <p class="opacity-6 text-sm">{{ $t('operating_system') }}</p>
            <p class="font-weight-600 text-sm">
              {{ participant.deviceInfo.operatingSystem }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-0 px-1">
          <b-col>
            <p class="opacity-6 text-sm">{{ $t('version') }}</p>
            <p class="font-weight-600 text-sm">
              {{ participant.deviceInfo.osVersion }}
            </p>
          </b-col>
        </b-row>
      </template>

      <div class="border-bottom"></div>
      <div v-if="showUpcomingVisit">
        <b-row class="mt-4 mx-0 px-1">
          <b-col>
            <p class="font-weight-600">{{ $t('upcoming_visit') }}</p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-0 px-1">
          <b-col>
            <p class="opacity-6 text-sm">{{ $t('visit_name') }}</p>
            <p class="font-weight-600 text-sm">
              {{ upcomingVisit.visitType }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-0 px-1">
          <b-col>
            <p class="opacity-6 text-sm">{{ $t('visit_date') }}</p>
            <p class="font-weight-600 text-sm">
              {{ visitDate ? visitDate : formatedUpcomingVisitDate }}
            </p>
          </b-col>
        </b-row>
        <div class="mt-2 mx-0 px-1">
          <b-col>
            <b-button
              pill
              variant="outline-primary"
              class="w-100 mb-4"
              @click="showScheduledDateModal = true"
            >
              <span class="avenir-bold">{{ $t('reschedule_visit') }}</span>
            </b-button>
          </b-col>
        </div>
      </div>
    </div>
    <div
      v-if="!discontinuedParticipant"
      class="bg-white shadow-none border mb-4"
      style="border-top: 0 !important"
    >
      <b-row class="mx-0 py-3 px-1">
        <b-col>
          <p class="font-weight-600">{{ $t('schedule_adjustments') }}</p>
        </b-col>
      </b-row>
      <b-row class="mx-0 px-1" v-if="participant.partners.length">
        <b-col cols="12">
          <p class="opacity-6 text-sm">{{ $t('devices_third_party') }}</p>
        </b-col>
        <ul>
          <b-row
            class="d-flex align-items-center"
            cols="12"
            v-for="partner in participant.partners"
            :key="partner.id"
          >
            <li class="bold d-flex align-items-center">
              <i
                v-if="partner.isEnabled"
                class="ni ni-check-bold text-primary mr-2"
              ></i>
              <i
                v-if="!partner.isEnabled"
                class="ni ni-fat-remove text-danger mr-2"
              ></i>
              {{ partner.partnerName }}
            </li>
          </b-row>
        </ul>
        <b-col
          cols="12"
          class="d-flex justify-content-center"
          v-if="$store.getters['auth/canWriteParticipant']"
        >
          <b-button
            v-if="showHideSidebarButton"
            pill
            variant="outline-primary"
            class="w-100 mb-4"
            @click="
              $router.push({
                name: 'change-consent',
                params: { id: participant.participantDisplayId },
              })
            "
          >
            <span class="avenir-bold">{{ $t('change_consent') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <div class="border-bottom"></div>
      <b-row class="mx-0 px-1 mt-3" v-if="participant.excludedGames.length">
        <b-col cols="12">
          <p class="opacity-6 text-sm">{{ $t('tasks_excluded') }}</p>
        </b-col>
        <ul>
          <b-row
            class="d-flex align-items-center"
            cols="12"
            v-for="task in participant.excludedGames"
            :key="task.id"
          >
            <li class="d-flex align-items-center">
              <i class="ni ni-fat-remove text-danger mr-2"></i>
              {{ $t(task.testVersion.test.name) }}
            </li>
          </b-row>
        </ul>
      </b-row>
      <template
        v-if="
          !discontinuedParticipant &&
          $store.getters['auth/canWriteParticipant'] &&
          showHideSidebarButton
        "
      >
        <b-row class="mx-0 mb-2 px-1 pb-4 mt-2">
          <b-col cols="12" class="d-flex justify-content-center mt-3">
            <b-button
              pill
              class="w-100"
              variant="primary"
              @click="
                $router.push({
                  name: 'tailor-schedule',
                  params: { id: participant.participantDisplayId },
                })
              "
            >
              <span class="avenir-bold">{{ $t('schedule_tailor') }}</span>
            </b-button>
          </b-col>
        </b-row>
        <div class="border-bottom"></div>
      </template>
      <div class="bg-white shadow-none">
        <b-row class="mx-0 py-3 px-1">
          <b-col>
            <p class="font-weight-600">{{ $t('camera_calibration') }}</p>
          </b-col>
        </b-row>
        <b-row class="mx-0 px-1">
          <b-col cols="12">
            <p class="opacity-6 text-sm mb-2">
              {{ $t('camera_calibration_date_time') }}
            </p>
            <p
              class="font-weight-600 text-sm"
              v-if="participant.cameraCalibration"
            >
              {{
                moment
                  .unix(participant.cameraCalibration.calibratedAt)
                  .format('DD/MM/YYYY HH:mm')
              }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-row
      class="mx-0 mb-2 px-1 pb-4"
      style="margin-top: 40px"
      v-if="isDropOutAvailable && !discontinuedParticipant"
    >
      <b-col cols="12" class="drop-out-button d-flex justify-content-center">
        <b-button
          pill
          class="w-100"
          variant="danger"
          @click="openDiscountinueModal"
        >
          <span class="avenir-bold">{{ $t('discontinue_participant') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row
      class="mx-0 mb-2 px-1 pb-4"
      style="margin-top: 40px"
      v-if="draftParticipant"
    >
      <b-col cols="12" class="drop-out-button d-flex justify-content-center">
        <b-button
          pill
          class="w-100"
          variant="primary"
          @click="
            $router.push({
              name: 'participant-update',
              params: {
                id: participant.participantDisplayId,
                status: participantStatus.name,
              },
            })
          "
        >
          <span class="avenir-bold">{{ $t('participant_update') }}</span>
        </b-button>
      </b-col>
    </b-row>

    <participant-reschedule-visit-modal
      :modal-visible="showScheduledDateModal"
      :visitType="upcomingVisit.visitType"
      :scheduledDate="visitDate ? visitDate : formatedUpcomingVisitDate"
      :maxDate="upcomingVisit.maximumAllowedDate"
      :minDate="upcomingVisit.minimumAllowedDate"
      @exit="exitScheduledDateModal"
      @close-modal="showScheduledDateModal = false"
      @updatedVisitDate="reloadVisitsInformation"
    />
  </div>
</template>

<script>
import ParticipantRescheduleVisitModal from '@/components/Participants/ModalRescheduleVisit/ParticipantRescheduleVisitModal.vue';
import { isNil } from 'lodash/lang';
import WebBadgeComponent from '@/shared/components/WebBadgeComponent.vue';
import {
  participantDiscontinuedKeys,
  participantStatusId,
} from '@/constants/participantStatuses';

export default {
  components: {
    ParticipantRescheduleVisitModal,
    WebBadgeComponent,
  },
  name: 'ParticipantSideBar',
  props: {
    participant: Object,
    discontinuedParticipant: Boolean,
    draftParticipant: Boolean,
  },
  data() {
    return {
      participantDiscontinuedKeys,
      reasonText: '',
      badgeKey: 0,
      isUnderstand: false,
      upcomingVisit: {},
      showUpcomingVisit: false,
      showScheduledDateModal: false,
      visitDate: '',
      showEnterReasonTextarea: false,
      subStatusBadgeToggle: true,
      showHideSidebarButton: true,
      selectOptions: [
        {
          label: this.$t('discontinue_participant_dropout'),
          value: 'dropped-out',
        },
        {
          label: this.$t('discontinue_participant_consent_withdrawn'),
          value: 'consent-withdrawn',
        },
        { label: this.$t('discontinue_participant_lost'), value: 'lost' },
        { label: this.$t('discontinue_participant_other'), value: 'other' },
      ],
    };
  },
  created() {
    this.renderSubstatusBadge();
    if (this.participant.participantVisits.length > 0) {
      this.getUpcomingVisit(this.participant.participantVisits);
    }
  },
  watch: {
    participantStatusDiscontinue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.badgeKey += 1;
      }
    },
  },
  computed: {
    participantStatusDiscontinue() {
      return (
        this.participant?.status?.id === participantStatusId.discontinuedReason
      );
    },
    participantStatus() {
      return this.participant.status;
    },
    participantSubStatus() {
      return this.participant.subStatus;
    },
    discontinuedReason() {
      return this.participant?.discontinuedReason?.discontinuedReason;
    },
    isDropOutAvailable() {
      return (
        this.$store.getters['auth/canWriteParticipant'] &&
        (this.participant.status.name === 'ACTIVE' ||
          this.participant.status.name === 'PENDING')
      );
    },
    isDeviceInfoAvailable() {
      return !isNil(this.participant) && !isNil(this.participant.deviceInfo);
    },
    formatedUpcomingVisitDate() {
      return this.moment(this.upcomingVisit.scheduledDate).format('DD/MM/YYYY');
    },
    participantParams: (state) => state.participants.participantParams,
  },

  methods: {
    renderSubstatusBadge() {
      if (!this.participantStatusDiscontinue) {
        this.subStatusBadgeToggle = true;
        this.showHideSidebarButton = true;
      }
      if (
        this.participantStatus.slug === 'screened-out' ||
        this.participantStatus.slug === 'draft'
      ) {
        this.subStatusBadgeToggle = false;
        this.showHideSidebarButton = false;
      }
    },
    openDiscountinueModal() {
      this.$emit('open-modal');
    },

    getUpcomingVisit(visits) {
      const participantIsActive = this.participant.status.name === 'ACTIVE';
      const participantIsPatientType =
        !this.participant.tags.includes('healthy_control');

      if (participantIsActive && participantIsPatientType) {
        const now = this.moment().startOf('day');
        const today = now.format('YYYY-MM-DD');
        const inSixMonths = now.add(180, 'days');

        const upcomingVisit = visits.reduce((acc, visit) => {
          const isValidVisitDate =
            today <= visit.scheduledDate &&
            this.moment(visit.scheduledDate).isBefore(inSixMonths);

          if (!isValidVisitDate) return acc;
          if (acc === undefined) return visit;

          const isCloserVisit = visit.scheduledDate < acc.scheduledDate;

          return isValidVisitDate && isCloserVisit ? visit : acc;
        }, undefined);

        if (upcomingVisit) {
          this.upcomingVisit = upcomingVisit;
          this.showUpcomingVisit = true;
        }
      }
    },
    reloadVisitsInformation(dateFromResponse) {
      const date = this.moment(dateFromResponse, 'YYYY-MM-DD');
      this.visitDate = date.format('DD/MM/YYYY');
    },
    exitScheduledDateModal() {
      this.showScheduledDateModal = false;
    },
  },
};
</script>

<style lang="scss">
#modal-dropout {
  .modal-header {
    justify-content: center !important;
    & h5 {
      font-size: 24px; // TODO: turn to variable
    }
  }
  .modal-body {
    padding: 40px 58px 0;
  }
  .alert-danger {
    margin: 2rem 0 0;
    padding: 24px 45px;
    background-color: #d15259;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 30px;
    .info-icon {
      width: 53px;
      height: 53px;
    }
    .header {
      margin: 0 0 8px;
      font-size: 18px;
      font-weight: 800;
    }
    .custom-checkbox {
      padding-left: 40px;
      .custom-control-label {
        display: flex;
        height: 24px;
        align-items: center;
        margin-top: 0;
        font-size: 1rem;
        &:before,
        &:after {
          top: 0;
          left: -40px;
          width: 24px;
          height: 24px;
          background-color: transparent;
          border-radius: 50%;
          border-color: white;
        }
      }
    }
  }
  .modal-footer {
    padding: 40px 58px 2rem;
    justify-content: start;
    button {
      min-width: 250px;
      font-size: 1rem;
    }
    .btn-secondary {
      margin-right: 20px;
    }
  }
}
</style>

<style scoped lang="scss">
li {
  list-style: none;
  margin-bottom: 1em;
}

.uncheck-icon {
  color: #f35656;
  margin-right: 14px;
}
</style>
