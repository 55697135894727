<template>
  <b-row>
    <b-col cols="3" class=""></b-col>
    <b-col cols="9" class="">
      <b-row class="mb-4 text-center">
        <b-col span="1" v-for="(week, index) in weeksData" :key="index">
          <div v-if="participantIsDraft">
            <div class="mb-3">
              {{ weeksFromNowHeader(week.dateStart, index) }}
            </div>
            <div v-if="week.numWeek > 0">
              {{ $t('week') }} {{ week.numWeek }}
            </div>
          </div>
          <div v-else-if="week.numWeek > 0">
            <div class="mb-3">
              {{ weeksFromNowHeader(week.dateStart, index) }}
            </div>
            <div>{{ $t('week') }} {{ week.numWeek }}</div>
            <div>{{ week.weekDate }}</div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { weeksFromNow } from '@/utils/dateTransform';
import { transformWeeksParams } from '@/utils/stringParams';

export default {
  name: 'ParticipantStudyScheduleWeeksHeader',
  props: ['weeks', 'selectedWeekIndex', 'participantIsDraft'],
  computed: {
    weeksData() {
      const startOffset = this.selectedWeekIndex > 1 ? 2 : 1;
      const endOffset = 1;
      const weeks = this.selectedWeekIndex > 1 ? [] : [{}];
      this.weeks
        .slice(
          this.selectedWeekIndex - startOffset,
          this.selectedWeekIndex + endOffset
        )
        .forEach((week) => {
          const { dateStart, numWeek, weekDate } = week;
          weeks.push({ dateStart, numWeek, weekDate });
        });
      return weeks;
    },
  },
  methods: {
    weeksFromNowHeader(dateString, index) {
      const numWeeks = this.participantIsDraft
        ? index - 1
        : weeksFromNow(new Date(dateString));
      switch (numWeeks) {
        case -1:
          return this.$t('week_last');
        case 1:
          return this.$t('week_next');
        case 0:
          return this.$t('week_current');
        default:
          return numWeeks < -1
            ? transformWeeksParams(this.$t('weeks_ago'), Math.abs(numWeeks))
            : '';
      }
    },
  },
};
</script>
