<template>
  <div>
    <b-modal
      :visible="modalVisible"
      @change="closeModal"
      header-class="justify-content-center"
      hide-header-close
      hide-footer
      no-close-on-backdrop
      id="modal-activate"
      size="xl"
    >
      <template slot="modal-title">
        <p class="modal-title">{{ $t(modalTitle) }}</p>
      </template>
      <b-row class="mt-4 ml-3">
        <b-col cols="4">
          <label>{{ $t('study_start_date') }}</label>
          <p class="opacity-6 text-sm">{{ startDate }}</p>
        </b-col>
        <b-col cols="4">
          <label>{{ $t('study_end_date') }}</label>
          <p class="opacity-6 text-sm">{{ endDate }}</p>
        </b-col>
      </b-row>

      <InfoPanel
        class="mt-4 ml-3"
        :inputNum="1"
        :title="this.$t('activate_participant_info')"
        :message="warningMessage"
        :radioText="this.$t('i_understand_continue')"
        @check-confirm="checkConfirm"
      ></InfoPanel>

      <b-row class="modal-buttons mt-4">
        <base-button
          type="secondary"
          :disabled="disabledCancelButton"
          @click="closeModal"
          >{{ $t('cancel') }}</base-button
        >
        <base-button
          type="primary"
          :disabled="!acceptCheckbox && !sendingDataToAPI"
          @click="activateCurrentParticipant"
          >{{ $t('activate_participant') }}</base-button
        >
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { activateParticipant } from '@/api/participant';
import { mapState } from 'vuex';
import { getMondayFromDate, addWeeksToDate } from '@/utils/dateTransform';
import { getProtocolDetail } from '@/api/protocol';
import InfoPanel from '@/components/InfoPanel.vue';

export default {
  name: 'ModalActivateParticipant',
  components: { InfoPanel },

  data() {
    return {
      acceptCheckbox: false,
      isDropOutAvailable: true,
      reasonText: '',
      studyDuration: '',
      startDate: '',
      endDate: '',
      sendingDataToAPI: false,
      disabledCancelButton: false,
    };
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    warningColor: {
      type: String,
    },
    warningTitle: {
      type: String,
    },
    warningMessage: {
      type: String,
    },
    buttonText: {
      type: String,
    },
  },

  created() {
    this.getStudyDuration();
  },

  computed: {
    ...mapState({
      selectedParticipant: ({ participants }) => {
        const { clientId, studyId, protocolId, siteId, id } =
          participants.selectedParticipant;
        return { clientId, studyId, protocolId, siteId, id };
      },
    }),
  },

  methods: {
    getStudyDuration() {
      const { clientId, studyId, protocolId } = this.selectedParticipant;
      getProtocolDetail({
        clientId,
        studyId,
        protocolId,
      }).then((res) => {
        if (res.code === 200) {
          this.studyDuration = res.data.studyDurationPerParticipant;
          this.setStartDateEndDate();
        }
      });
    },
    setStartDateEndDate() {
      const mondayOfCurrentWeeek = getMondayFromDate(new Date());
      this.startDate = this.moment(mondayOfCurrentWeeek).format('DD/MM/YYYY');
      const studyEndDate = addWeeksToDate(
        mondayOfCurrentWeeek,
        this.studyDuration
      );
      this.endDate = this.moment(studyEndDate).format('DD/MM/YYYY');
    },
    checkConfirm(event) {
      !event.target.checked
        ? (this.acceptCheckbox = false)
        : (this.acceptCheckbox = true);
    },
    closeModal() {
      this.$emit('close-modal');
    },
    activateCurrentParticipant() {
      this.sendingDataToAPI = true;
      this.disabledCancelButton = true;
      const { clientId, studyId, protocolId, siteId, id } =
        this.selectedParticipant;
      activateParticipant({
        clientId,
        studyId,
        protocolId,
        siteId,
        id,
      })
        .then((res) => {
          if (res.code === 201) {
            this.$emit('activate-components');
            this.$toast.info(this.$t('toast_participant_activated'));
          }
        })
        .catch((err) => {
          this.$toast.error(
            this.$t(err?.data?.msg ?? this.$t('error_something_went_wrong'))
          );
        })
        .finally(() => {
          this.sendingDataToAPI = false;
          this.disabledCancelButton = false;
          this.closeModal();
        });
    },
  },
};
</script>

<style scoped lang="scss">
#modal-activate {
  /*.modal-header {
    justify-content: center !important;
    & h5 {
      font-size: 24px; // TODO: turn to variable
    }
  }*/
  .modal-body {
    padding: 40px 58px 0;
  }

  .modal-buttons {
    padding: 40px 58px 2rem;
    display: flex;
    justify-content: flex-start !important;
  }

  ::v-deep .modal-footer {
    padding: 40px 58px 2rem;
    justify-content: flex-start !important;
    button {
      min-width: 250px;
      font-size: 1rem;
    }
    .btn-secondary {
      margin-right: 20px;
    }
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: #a1a8c0 !important;
    border-color: #e8e9ec !important;
  }
}
</style>
