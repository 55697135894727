<template>
  <b-spinner class="mt-4 mx-auto d-block" v-if="firstLoading"></b-spinner>
  <div class="mt-4" v-else>
    <participants-table
      :participantListTableData="participants"
      :orderBy="orderBy"
      :isLoading="loading"
      :class="{ 'loading-table': loading }"
      @sorting="handleSortingEvent"
      @goToDetail="goToDetail"
    />
    <PaginatorTableComponent
      @changePageEvent="handleChangePageEvent"
      :current-page="pagination.currentPage"
      :total-rows="pagination.totalCount"
      :page-size="pagination.pageSize"
    />
  </div>
</template>

<script>
import ParticipantsTable from '@/components/Tables/ParticipantsTable.vue';
import PaginatorTableComponent from '@/shared/components/PaginatorTableComponent.vue';
import { paginationDefaults } from '@/constants/pagination-constants';
import { mapActions, mapState } from 'vuex';
import listMixin from '@/mixins/listMixin';
import { participantListDataTableObjectMapper } from '@/object-mappers/participant.object-mapper';

export default {
  name: 'ParticipantList',
  components: {
    PaginatorTableComponent,
    ParticipantsTable,
  },
  mixins: [listMixin],
  props: {
    filterData: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      participants: ({ participants }) =>
        participantListDataTableObjectMapper(participants.all),
      storePagination: ({ participants }) => participants.pagination,
    }),
  },
  watch: {
    filterData() {
      this.resetPage();
      this.getListData('participants');
    },
  },
  data() {
    return {
      firstLoading: true,
      loading: true,
      pagination: {
        pageSize: paginationDefaults.itemsPerPage,
        page: paginationDefaults.firstPageIndex,
        totalCount: paginationDefaults.totalCount,
      },
      orderBy: {
        column: 'createdAt',
        ascending: false,
      },
    };
  },
  created() {
    this.getListData('participants');
  },
  methods: {
    ...mapActions({
      fetchListData: 'participants/fetchAllParticipants',
      setParticipantParams: 'participants/setParticipantParams',
    }),
    goToDetail(participant) {
      this.setParticipantParams({
        clientId: participant.clientId,
        studyId: participant.studyId,
        protocolId: participant.protocolId,
        siteId: participant.siteId,
        participantId: participant.participantId,
      });

      this.$emit('goToDetail', participant.participantDisplayId);
    },
  },
};
</script>
