<template>
  <div>
    <h2>{{ $t('site_address') }}</h2>

    <b-row>
      <b-col>
        <label>{{ $t('address') }}</label>
        <b-form-input
          v-model="siteAddress.address"
          :placeholder="$t('address')"
          data-testid="address-input"
        ></b-form-input>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <label>{{ $t('city') }}</label>
            <b-form-input
              v-model="siteAddress.city"
              :placeholder="$t('city')"
              data-testid="city-input"
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>{{ $t('country') }}</label>
            <v-select
              v-model="siteAddress.countryId"
              :placeholder="$t('country')"
              :options="countries"
              :reduce="(country) => country.id"
              label="shortName"
              :disabled="countries.length === 0"
              id="countries"
            />
          </b-col>
          <b-col cols="4">
            <label>{{ $t('postal_code') }}</label>
            <b-form-input
              v-model="siteAddress.postalCode"
              :placeholder="$t('postal_code')"
              data-testid="postal-input"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          :disabled="!validForm"
          @click="$emit('nextStep')"
          data-testid="next-button"
          >{{ $t('next') }}
          <arrow-right />
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    siteAddress: {
      address: String,
      city: String,
      countryId: String,
      postalCode: String,
    },
  },
  computed: {
    ...mapState({
      countries: (state) => state.resources.countries,
    }),
    validForm() {
      const { address, city, countryId, postalCode } = this.siteAddress;
      return !!address && !!city && !!countryId && !!postalCode;
    },
  },
};
</script>

<style></style>
