<template>
  <WebUICard v-if="endDateIsAfterTodayDate">
    <b-row class="collapse-body mx-0 py-3 px-1" v-b-toggle.schedule-collapse>
      <b-col cols="7">
        <p class="avenir-bold collapse-title mb-0">
          {{ $t('participant_schedule_relapse') }}
          <span class="avenir opacity-6">{{ relapseId }}</span>
        </p>
      </b-col>
      <b-col class="text-right">
        <b-badge
          v-if="relapseScheduleData.hasPendingChanges"
          pill
          class="badge-purple text-white d-inline-flex justify-content-center font-weight-normal text-lg mr-2"
        >
          {{ $t('pending_changes') }}
        </b-badge>
        <b-badge
          v-if="relapseScheduleData.scheduleStartedAt"
          pill
          class="badge badge-success text-white d-inline-flex justify-content-center font-weight-normal text-lg"
          variant="success"
        >
          {{ $t('ACTIVE') }}
        </b-badge>
        <b-badge
          v-if="!relapseScheduleData.scheduleStartedAt"
          pill
          class="badge-orange text-white d-inline-flex justify-content-center font-weight-normal text-lg"
        >
          {{ $t('created') }}
        </b-badge>
      </b-col>
    </b-row>
    <b-collapse id="schedule-collapse" visible>
      <div class="mt-3 px-1 mx-0 pb-4">
        <relapse-schedule :schedules="relapseScheduleData" />
      </div>
    </b-collapse>
  </WebUICard>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import RelapseSchedule from '@/components/Participants/RelapseSchedule';
import WebUICard from '@/shared/components/WebUICard.vue';

export default {
  name: 'ParticipantRelapseScheduleStatusComponent.vue',
  components: {
    RelapseSchedule,
    WebUICard,
  },

  props: {
    relapseScheduleData: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters({ dropped: 'participants/isParticipantDroppedOut' }),
    ...mapState({
      relapseId: (state) => state.relapses?.list[0]?.displayId,
    }),
    endDateIsAfterTodayDate() {
      const endDate = new Date(this.relapseScheduleData.scheduleEndAt);
      const todayDate = new Date();
      return endDate > todayDate;
    },
  },
};
</script>

<style scoped lang="scss">
.badge {
  text-transform: capitalize;
}

.badge-success {
  background-color: var(--green);
}

.badge-danger {
  background-color: #f35656;
}

.badge-orange {
  background-color: #f58b44;
}

.badge-purple {
  background-color: #41479b;
}
</style>
