<template>
  <div>
    <b-row>
      <b-spinner v-if="loading" class="mx-auto d-block"></b-spinner>
      <b-col v-else cols="10" class="center mt-4">
        <div class="bg-white shadow">
          <change-history-table
            view="changes-history"
            :tableData="participantChangesList"
            :initial-pagination-values="paginationValues"
            :key="componentKey"
          ></change-history-table>
          <b-pagination
            v-model="startPage"
            :total-rows="totalCount"
            :perPage="10"
            class="d-flex justify-content-center align-items-center mt-4"
            style="padding-bottom: 15px"
            aria-controls="my-table"
            align="center"
            @input="getParticipantChanges(startPage)"
          >
            <template #first-text>
              <span>{{ $t('first') }}</span>
            </template>
            <template #prev-text>
              <span>{{ $t('prev') }}</span>
            </template>
            <template #next-text>
              <span>{{ $t('next') }}</span>
            </template>
            <template #last-text>
              <span>{{ $t('last') }}</span>
            </template>
            <template #page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <div v-else>{{ page }}</div>
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ChangeHistoryTable from '@/components/Tables/ChangeHistoryTable';
import { mapState } from 'vuex';

export default {
  components: {
    ChangeHistoryTable,
  },
  computed: {
    ...mapState({
      participantParams: (state) => state.participants.participantParams,
      participant: (state) => state.participants.selectedParticipant,
      participantChangesList: ({ participantsChanges }) =>
        participantsChanges.list,
      participantChangesPagination: ({ participantsChanges }) =>
        participantsChanges.pagination,
    }),
  },
  data() {
    return {
      participantChangesRecords: [],
      paginationValues: null,
      startPage: 1,
      limit: 10,
      loading: true,
      totalCount: 0,
      componentKey: 1,
      totalPages: 1,
    };
  },
  created() {
    this.getParticipantChanges(this.startPage);
  },
  watch: {
    '$route.name': {
      handler: function () {
        this.setBreadCrumbData({
          'changes-history': [
            {
              text: this.$t('participants'),
              to: { name: 'participants' },
            },
            {
              text: this.participant.participantDisplayId,
              to: { name: 'view-participant' },
            },
            {
              text: this.$t('change_history'),
              to: { name: 'changes-history' },
            },
          ],
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getParticipantChanges(page) {
      const { participantId } = this.participantParams;
      let offset = this.limit * (page - 1);
      return new Promise((resolve) => {
        this.$store
          .dispatch(
            'participantsChanges/dispatchFetchParticipantChangeRecords',
            {
              'filter[participant_id]': participantId,
              limit: this.limit,
              offset: offset,
              sort: '-created_at',
            }
          )
          .then(() => {
            this.totalCount = this.participantChangesPagination.totalCount;
            this.totalPages = this.participantChangesPagination.totalPages;
            this.loading = false;
            this.componentKey += 1;
            resolve();
          });
      });
    },
  },
};
</script>
