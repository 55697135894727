<template>
  <div>
    <h2 class="mb-4">{{ $t('overview') }}</h2>

    <div class="overview-card">
      <p class="text-lg">{{ $t('schedule_information') }}</p>
      <b-row>
        <b-col>
          <b-row>
            <b-col class="opacity-6"> {{ $t('participant_id') }} </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              relapse.participant.participantDisplayId
            }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="opacity-6">{{ $t('participant_id_subject') }} </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              relapse.participant.subjectCode
            }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="opacity-6">{{ $t('relapse_id') }}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ relapse.displayId }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="opacity-6">{{ $t('relapse_report_date') }}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{
              moment.unix(relapse.reportedAt).format('DD/MM/YYYY')
            }}</b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="opacity-6">{{ $t('duration') }}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="avenir-bold">{{ $t('six_weeks') }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div class="overview-card mb-5">
      <p class="text-lg">{{ $t('tests_games') }}</p>
      <p class="avenir-bold">
        {{
          transformParams($t('relapse_schedule_duration_text'), (duration = 6))
        }}
      </p>
      <schedule :schedules="schedule" :weekLength="6" />
    </div>

    <b-form-checkbox v-model="confirmation"
      ><span class="text-lg">{{
        $t('relapse_schedule_confirmation')
      }}</span></b-form-checkbox
    >

    <b-row class="my-5">
      <b-col>
        <base-button
          type="secondary"
          @click="$emit('back')"
          :disabled="submitting"
          data-testid="back-button"
          >{{ $t('back') }}
        </base-button>
        <base-button
          type="primary"
          @click="createRelapseSchedule"
          :disabled="submitting || !confirmation"
          data-testid="submit-button"
        >
          {{ $t('relapse_schedule_create') }}
          <b-spinner small v-if="submitting"></b-spinner>
        </base-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { postRelapseSchedule } from '@/api/relapses';
import Schedule from '../Overviews/Schedule.vue';
import { mapState } from 'vuex';
import { transformDurationParams } from '@/utils/stringParams';

export default {
  components: { Schedule },
  data() {
    return {
      numberOfWeeks: 4,
      isView: this.$route.name === 'view-protocol',
      isEdit: this.$route.name === 'edit-protocol',
      submitting: false,
      confirmation: false,
    };
  },
  props: {
    relapse: Object,
    games: Array,
  },

  computed: {
    ...mapState({
      participant: (state) => state.participants.selectedParticipant,
      relapseParams: ({ relapses }) => relapses.relapsePrams,
    }),

    schedule() {
      return this.games.map((item) => ({
        name: item.name,
        version: item.version,
        testVersionId: item.id,
        frequency: 1,
        days: [0, 1, 0, 0, 1, 0, 0],
      }));
    },
  },
  methods: {
    transformParams(string, num) {
      return transformDurationParams(string, num);
    },
    async createRelapseSchedule() {
      this.submitting = true;
      try {
        const { data } = await postRelapseSchedule(this.relapseParams, {
          tests: this.games.map((item) => item.id),
        });
        data.id && this.$toast.info(this.$t('toast_relapse_schedule_created'));
        this.$router.push({
          name:
            this.$route.name === 'create-relapse-schedule'
              ? 'view-relapse'
              : `${this.$route.meta.parent}-view-relapse`,
        });
      } catch (error) {
        this.$toast.error(
          this.$t(error.data.message) || this.$t('error_something_went_wrong')
        );
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style></style>
