<template>
  <div>
    <ValidationObserver v-slot="{ valid }">
      <h3>{{ $t('study_information') }}</h3>
      <b-row>
        <b-col cols="4">
          <label>{{ $t('study') }}</label>
          <p class="opacity-6">
            {{ selectedParticipant.studyName }}
          </p>
        </b-col>
        <b-col cols="4">
          <label>{{ $t('protocol') }}</label>
          <p class="opacity-6">
            {{ selectedParticipant.protocolName }}
          </p>
        </b-col>
        <b-col cols="4">
          <label>{{ $t('site') }}</label>
          <p class="opacity-6">
            {{ selectedParticipant.siteName }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4">
          <label>{{ $t('study_start_date') }}</label>
          <p class="opacity-6">
            {{ moment(selectedParticipant.dateStart).format('DD/MM/YYYY') }}
          </p>
        </b-col>
        <b-col cols="6">
          <label>{{ $t('study_end_date_planned') }}</label>
          <p class="opacity-6">
            {{ moment(selectedParticipant.dateEnd).format('DD/MM/YYYY') }}
          </p>
        </b-col>
      </b-row>

      <HorizontalDivider
        colorLine="E8E9EC"
        lineWidth="1"
        colsWidth="12"
      ></HorizontalDivider>

      <h3 class="mt-4">{{ $t('participant_information') }}</h3>
      <b-row>
        <b-col cols="4">
          <label class="mb-3">{{ $t('phone_number_mobile') }}</label>
          <div class="mt-1">
            <flag-icons :phone="selectedParticipant.phone" showNumber />
          </div>
        </b-col>

        <b-col cols="4">
          <label>
            <span v-if="readSubject">{{ $t('participant_id_subject') }}</span>
            <div v-else class="d-flex">
              <span class="editing mr-2"
                >{{ $t('participant_id_subject') }} </span
              ><edit-icon class="align-self-stretch"></edit-icon>
            </div>
          </label>
          <b-form-input
            v-model="info.subjectCode"
            :placeholder="selectedParticipant.subjectCode"
            maxLength="100"
            id="subject-id"
            @focus="labeFocus('subject')"
            @focusout="labelFocusOut('subject')"
            data-testid="subject-input"
          ></b-form-input>
        </b-col>

        <b-col cols="4">
          <label
            ><span v-if="readType">{{ $t('type') }}</span>
            <div v-else class="d-flex">
              <span class="editing mr-2">{{ $t('type') }} </span
              ><edit-icon class="align-self-stretch"></edit-icon>
            </div>
          </label>
          <b-form-radio-group v-model="selectedType" data-testid="tags-inputs">
            <b-form-radio class="mr-5" :value="1" data-testid="tags-true-value">
              <span class="opacity-6 avenir">{{ $t('patient') }}</span>
            </b-form-radio>
            <b-form-radio :value="2" data-testid="tags-false-value">
              <span class="opacity-6 avenir">{{ $t('healthy_control') }}</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="4">
          <label
            ><span v-if="readGender">{{ $t('gender') }}</span>
            <div v-else class="d-flex">
              <span class="editing mr-2">{{ $t('gender') }} </span
              ><edit-icon class="align-self-stretch"></edit-icon>
            </div>
          </label>
          <b-form-radio-group
            v-model="selectedGender"
            data-testid="gender-inputs"
          >
            <b-form-radio class="mr-5" :value="1" data-testid="male-radio">
              <span class="opacity-6 avenir">{{ $t('gender_male') }}</span>
            </b-form-radio>
            <b-form-radio :value="2" data-testid="female-radio" checked>
              <span class="opacity-6 avenir">{{ $t('gender_female') }}</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-col>

        <b-col cols="4">
          <label>
            <span v-if="readHeight">{{ $t('height') }}</span>
            <div v-else class="d-flex">
              <span class="editing mr-2">{{ $t('height') }} </span
              ><edit-icon class="align-self-stretch"></edit-icon>
            </div>
          </label>
          <ValidationProvider
            name="height"
            rules="between:100,300"
            v-slot="{ errors }"
          >
            <b-input-group class="w-50">
              <template #append>
                <b-input-group-text class="w-50"
                  ><span class="opacity-6">{{
                    $t('unit_cm')
                  }}</span></b-input-group-text
                >
              </template>
              <b-form-input
                type="number"
                v-model.number="info.height"
                :placeholder="heightToString"
                @focus="labeFocus('height')"
                @focusout="labelFocusOut('height')"
                data-testid="height-input"
              ></b-form-input>
            </b-input-group>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-col>

        <b-col cols="4">
          <label
            ><span v-if="readWeight">{{ $t('weight') }}</span>
            <div v-else class="d-flex">
              <span class="editing mr-2">{{ $t('weight') }} </span
              ><edit-icon class="align-self-stretch"></edit-icon>
            </div>
          </label>
          <ValidationProvider
            name="weight"
            rules="between:10,999"
            v-slot="{ errors }"
          >
            <b-input-group class="w-50">
              <template #append>
                <b-input-group-text
                  ><span class="opacity-6">{{
                    $t('unit_kg')
                  }}</span></b-input-group-text
                >
              </template>
              <b-form-input
                type="number"
                :placeholder="weightToString"
                v-model.number="info.weight"
                @focus="labeFocus('weight')"
                @focusout="labelFocusOut('weight')"
                data-testid="weight-input"
              ></b-form-input>
            </b-input-group>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-col>
      </b-row>

      <HorizontalDivider
        colorLine="e8e9ec"
        lineWidth="1"
        colsWidth="12"
      ></HorizontalDivider>

      <b-row class="mt-4">
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('icf_signed') }}</span>
          <p class="avenir-bold mt-2">
            {{ $t('yes') }}
          </p>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="4">
          <span class="opacity-6 normal-label">{{ $t('language') }}</span>
          <p class="avenir-bold mt-2">
            {{ languageNameFromCode(selectedParticipant.language) }} -
            {{ selectedParticipant.language.toUpperCase() }}
          </p>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="selectedParticipant.partners.length > 0">
        <b-col>
          <span class="opacity-6 normal-label">{{
            $t('devices_third_party')
          }}</span>
          <p
            v-for="partner in selectedParticipant.partners"
            :key="partner.partnerId"
            class="avenir-bold mt-2"
          >
            <i class="ni ni-check-bold text-primary mr-2" />{{
              partner.partnerName
            }}
          </p>
        </b-col>
      </b-row>

      <HorizontalDivider
        v-if="showChangesReason"
        colorLine="e8e9ec"
        lineWidth="1"
        colsWidth="12"
      ></HorizontalDivider>

      <!-- change reason -->
      <b-row v-if="showChangesReason" class="mt-4 mb-4">
        <b-col>
          <h3>{{ $t('change_reason') }}</h3>
          <span>{{ $t('change_reason_info') }}</span>
          <b-form-radio-group v-model="changeReason" class="custom-radio">
            <b-form-radio value="change" class="d-block">
              <span class="opacity-6 avenir ml-0">
                {{ $t('change_reason_change') }}
              </span>
            </b-form-radio>
            <b-form-radio value="correction" class="d-block">
              <span class="opacity-6 avenir">
                {{ $t('change_reason_correction') }}
              </span>
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <HorizontalDivider
        colorLine="e8e9ec"
        lineWidth="1"
        colsWidth="12"
      ></HorizontalDivider>

      <!-- alert -->
      <info-panel
        v-if="displayPanel && showChangesReason"
        class="mt-4"
        :inputNum="1"
        :title="alertTitle"
        :message="alertMessage"
        :radioText="inputText"
        @check-confirm="checkConfirm"
      ></info-panel>

      <b-row class="mt-5">
        <b-col>
          <base-button
            type="primary"
            :disabled="disabledButton"
            @click="handleNextClick"
            rounded
            data-testid="next-button"
          >
            {{ $t('next') }}
            <b-spinner v-if="loading || !valid" small></b-spinner>
            <arrow-right />
          </base-button>
        </b-col>
        <b-col> </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import FlagIcons from '@/components/FlagIcons.vue';
import { fetchParticipants } from '@/api/participant';
import InfoPanel from '@/components/InfoPanel';
import EditIcon from '@/components/Icons/EditIcon';

import { mapGetters, mapState } from 'vuex';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import { languagesDefault } from '@/constants/languages-constants';

export default {
  components: {
    ValidationObserver,
    InfoPanel,
    HorizontalDivider,
    EditIcon,
    FlagIcons,
  },

  data() {
    return {
      addedByClientUser: this.$route.name === 'add-participant',
      alertTitle: '',
      alertMessage: '',
      changeReason: null,
      componentKey: 0,
      disabledButton: false,
      displayPanel: false,
      editing: false,
      endDate: '',
      inputText: '',
      isProtocolView: this.$route.name === 'protocol-create-participant',
      loading: false,
      originGender: 0,
      originType: 0,
      originHeight: 0,
      originSubject: '',
      originWeight: 0,
      phoneNumber: '',
      readSubject: true,
      readHeight: true,
      readWeight: true,
      readType: true,
      readGender: true,
      subjectId: '',
      selectedGender: 1,
      selectedType: null,
      showChangesReason: false,
      tag: [],
      updatedData: { height: '', weight: '' },
      valueType: null,
      languagesList: [...languagesDefault],
    };
  },
  props: {
    info: Object,
  },

  created() {
    this.defaultGender();
    this.defaultType();
    this.defaultInputsData();
  },

  watch: {
    info: {
      handler: function () {
        if (
          (this.info.subjectCode === '' ||
            this.info.subjectCode === this.originSubject) &&
          (this.info.height === '' ||
            this.info.height === null ||
            this.info.height === this.originHeight) &&
          (this.info.weight === '' ||
            this.info.weight === null ||
            this.info.weight === this.originWeight) &&
          (this.info.gender === null ||
            this.info.gender === this.originGender) &&
          this.originType === this.valueType
        ) {
          this.showChangesReason = false;
        } else {
          this.showChangesReason = true;
        }
      },
      deep: true,
    },

    showChangesReason(value) {
      if (value) {
        this.disabledButton = true;
      } else {
        this.disabledButton = false;
        this.changeReason = null;
      }
    },
    changeReason(value) {
      this.displayText(value);
      this.info.reason = value;
      this.displayPanel = true;
    },
    selectedType(value) {
      this.valueType = value;
      if (value === this.originType) {
        this.readType = true;
        value === 1
          ? (this.info.tags = ['patient'])
          : (this.info.tags = ['healthy_control']);
      } else {
        value === 1
          ? (this.info.tags = ['patient'])
          : (this.info.tags = ['healthy_control']);
        this.readType = false;
      }
    },
    selectedGender(value) {
      if (value != this.originGender) {
        this.selectedGender = value;
        this.info.gender = value;
        this.readGender = false;
      } else {
        this.selectedGender = value;
        this.info.gender = value;
        this.readGender = true;
      }
    },
    watchSubjectCode(value) {
      if (value === '' || value === this.originSubject) {
        this.readSubject = true;
      } else {
        this.readSubject = false;
      }
    },
    watchHeight(value) {
      if (value === '' || value === this.originHeight) {
        this.readHeight = true;
      }
    },
    watchWeight(value) {
      if (value === '' || value === this.originWeight) {
        this.readWeight = true;
      }
    },
  },

  computed: {
    watchSubjectCode() {
      return this.info.subjectCode;
    },
    watchHeight() {
      return this.info.height;
    },
    watchWeight() {
      return this.info.weight;
    },
    validForm() {
      const { icfSigned, gender, height, tags, weight } = this.info;
      return (
        !!this.phoneNumber &&
        icfSigned &&
        !!gender &&
        !!height &&
        !!weight &&
        !!tags
      );
    },
    ...mapState({
      site: ({ sites }) => sites.selectedSite,
      study: ({ studies }) => studies.selectedStudy,
      siteProtocol: ({ sites }) => sites.siteProtocol,
      selectedProtocol: ({ protocols }) => protocols.selectedProtocol,
      association: ({ participants }) => participants.association,
      selectedParticipant: ({ participants }) =>
        participants.selectedParticipant,
    }),
    ...mapGetters({ partners: 'resources/extractPartners' }),

    heightToString() {
      return String(this.selectedParticipant.height);
    },

    weightToString() {
      return String(this.selectedParticipant.weight);
    },

    calculateEndDate() {
      const { studyDurationPerParticipant } = this.siteProtocol;
      let now = new Date();
      return now.setDate(now.getDate() + studyDurationPerParticipant * 7);
    },

    calculateStartDate() {
      return this.moment().startOf('isoWeek').format('DD/MM/YYYY');
    },
  },

  methods: {
    checkConfirm(event) {
      !event.target.checked
        ? (this.disabledButton = true)
        : (this.disabledButton = false);
    },
    defaultInputsData() {
      this.originHeight = this.selectedParticipant.height;
      this.originWeight = this.selectedParticipant.weight;
      this.originSubject = this.selectedParticipant.subjectCode;
    },
    defaultType() {
      if (
        this.selectedParticipant.tags.length === 0 ||
        this.selectedParticipant.tags[0] != 'healthy_control'
      ) {
        this.selectedType = 1;
        this.originType = 1;
      } else {
        this.selectedType = 2;
        this.originType = 2;
      }
    },
    defaultGender() {
      if (this.selectedParticipant.gender.id === 1) {
        this.selectedGender = 1;
        this.originGender = 1;
      } else {
        this.selectedGender = 2;
        this.originGender = 2;
      }
    },

    labeFocus(param) {
      if (param === 'subject') {
        this.readSubject = false;
      }
      if (param === 'height') {
        this.readHeight = false;
      }
      if (param === 'weight') {
        this.readWeight = false;
      }
    },
    labelFocusOut(param) {
      if (param === 'subject' && this.info.subjectCode === '') {
        this.readSubject = true;
      }
      if (
        (param === 'height' && this.info.height === '') ||
        this.info.height === null
      ) {
        this.readHeight = true;
      }
      if (
        (param === 'weight' && this.info.weight === '') ||
        this.info.height === null
      ) {
        this.readWeight = true;
      }
    },
    displayText(value) {
      if (value === 'change') {
        this.alertTitle = this.$t('change_box_info_change_header');
        this.alertMessage = this.$t('change_box_info_change_detail');
      } else {
        this.alertTitle = this.$t('change_box_info_correction_header');
        this.alertMessage = this.$t('change_box_info_correction_detail');
      }
      this.inputText = this.$t('i_understand_continue');
    },

    async validateSubjectCode() {
      try {
        const { data } = await fetchParticipants({
          'filter[subject_code]': this.info.subjectCode,
        });
        if (data?.length === 0) {
          return true;
        } else {
          this.$toast.error(this.$t('error_subject_id_not_valid'));
        }
      } catch (error) {
        this.$toast.error(this.$t(error.msg));
      }
    },

    async handleNextClick() {
      this.loading = true;
      this.componentKey += 1;
      let isValidSubject = true;
      if (this.info.subjectCode) {
        isValidSubject = await this.validateSubjectCode();
      }
      if (isValidSubject) this.$emit('nextStep');
      this.loading = false;
    },
    languageNameFromCode(languageCode) {
      const filtered = this.languagesList.filter(
        (language) => language.code === languageCode
      );
      return filtered.length > 0 ? filtered[0].name : '';
    },
  },
};
</script>

<style scoped lang="scss">
.input-group-text {
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
  color: inherit;
  border-radius: 0px;
  font-family: 'Avenir-Bold';
}

::v-deep .custom-radio {
  .custom-control-label span {
    margin-left: -10px !important;
  }
}

.text-lg {
  font-size: 1.125rem !important;
}
.editing {
  color: #3331e0;
}
.divider {
  border-bottom: 1px solid #c0c4cc;
}
</style>
