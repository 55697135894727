var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.isEdit && !_vm.client.representatives.length) ||
    (_vm.isView && !_vm.representatives.length)
  )?_c('div',{staticClass:"text-center"},[_c('b-spinner')],1):_c('div',[(!_vm.isView)?_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('overview')))]):_vm._e(),_c('div',{staticClass:"overview-card"},[_c('p',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.$t('client_information')))]),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"opacity-6"},[_vm._v(" "+_vm._s(_vm.$t('client_name_legal'))+" ")])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"avenir-bold"},[_vm._v(_vm._s(_vm.client.basic.legalName))])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"opacity-6"},[_vm._v(" "+_vm._s(_vm.$t('client_type'))+" ")])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"avenir-bold"},[_vm._v(_vm._s(_vm.$t(this.clientTypeText)))])],1)],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"opacity-6"},[_vm._v(" "+_vm._s(_vm.$t('program_manager'))+" ")])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"avenir-bold",domProps:{"innerHTML":_vm._s(
              _vm.managers.find(
                function (manager) { return manager.id === _vm.client.basic.programManagerId; }
              ).label
            )}})],1)],1)],1),_c('p',{staticClass:"opacity-6 mt-4"},[_vm._v(" "+_vm._s(_vm.$t('description'))+" ")]),_c('div',{staticClass:"row"},[_c('p',{staticClass:"avenir-bold col"},[_vm._v(_vm._s(_vm.client.basic.description))])])],1),(_vm.isEdit)?_c('div',[_c('client-assigned-users-table',{attrs:{"sectionTitle":this.$t('client_representatives'),"representatives":_vm.editingClientRepresentatives.length
          ? _vm.editingClientRepresentatives
          : _vm.client.representatives}})],1):_c('div',[_c('client-assigned-users-table',{attrs:{"sectionTitle":this.$t('client_representatives'),"representatives":_vm.isView ? _vm.representatives : _vm.client.representatives}})],1),_c('div',{staticClass:"overview-card"},[_c('p',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.$t('client_address_official_contact')))]),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"opacity-6"},[_vm._v(" "+_vm._s(_vm.$t('website'))+" ")]),_c('div',{staticClass:"avenir-bold text-break"},[_vm._v(" "+_vm._s(_vm.client.contact.website)+" ")])]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"opacity-6"},[_vm._v(" "+_vm._s(_vm.$t('email'))+" ")]),_c('div',{staticClass:"avenir-bold text-break"},[_vm._v(" "+_vm._s(_vm.client.contact.email)+" ")])]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"opacity-6"},[_vm._v(" "+_vm._s(_vm.$t('phone'))+" ")]),_c('div',{staticClass:"avenir-bold"},[_vm._v(_vm._s(_vm.client.contact.phone))])])],1),_c('address-overview',{attrs:{"contact":_vm.client.contact}})],1),(!_vm.isInvoiceEmpty)?_c('div',{staticClass:"overview-card"},[_c('p',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.$t('client_address_invoice'))+" ")]),_c('address-overview',{attrs:{"contact":_vm.client.invoice}})],1):_vm._e(),(!_vm.isView)?_c('b-row',{staticClass:"my-5"},[_c('b-col',[_c('base-button',{attrs:{"type":"secondary","disabled":_vm.loading,"data-testid":"back-button"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(_vm._s(_vm.$t('back'))+" ")]),_c('base-button',{attrs:{"type":"primary","disabled":_vm.loading,"data-testid":"submit-button"},on:{"click":function($event){_vm.$route.name === 'create-client' ? _vm.postClient() : _vm.updateClient()}}},[_vm._v(_vm._s(_vm.$route.name === 'create-client' ? 'Create Client' : 'Update Client')+" "),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }