<template>
  <div>
    <!-- <p :class="{ 'avenir-bold': isParticipant }">
      A week has
      <b class="avenir-bold" :class="{ 'mx-2': isParticipant }">{{
        weekLength
      }}</b>
      days
    </p> -->
    <b-row class="">
      <b-col span="5" class=""></b-col>
      <b-col span="8" class="">
        <b-row class="mb-4 text-center">
          <b-col span="1" v-for="day in days" :key="day.name">
            {{ $t(day.name) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      class="border-bottom"
      :class="isParticipant ? 'py-4' : 'py-2'"
      v-for="(schedule, index) in schedules"
      :key="index"
    >
      <b-col span="5">
        <b-row>
          <b-col
            v-if="schedule.version"
            class="avenir-bold"
            :class="{ 'text-sm': isParticipant }"
          >
            {{ $t(schedule.name) }} ( {{ schedule.version }} )
          </b-col>
          <b-col
            v-else
            class="avenir-bold"
            :class="{ 'text-sm': isParticipant }"
          >
            {{ $t(schedule.name) }}
          </b-col>
          <b-col class="avenir-bold text-sm">
            <span class="mx-2">
              {{
                transformParams(
                  $t('relapse_schedule_cycle'),
                  schedule.frequency
                )
              }}</span
            >
          </b-col>
        </b-row>
      </b-col>
      <b-col span="8">
        <b-row class="list-inline text-center">
          <template v-for="(day, index) in schedule.days">
            <b-col span="1" :key="Math.random() + index">
              <i v-if="day === 1" class="ni ni-check-bold text-primary" />
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { transformDurationParams } from '../../utils/stringParams';
export default {
  props: {
    schedules: Array,
    weekLength: Number,
  },
  data() {
    return {
      days: [
        {
          name: this.$t('day_mon'),
        },
        {
          name: this.$t('day_tue'),
        },
        {
          name: this.$t('day_wed'),
        },
        {
          name: this.$t('day_thu'),
        },
        {
          name: this.$t('day_fri'),
        },
        {
          name: this.$t('day_sat'),
        },
        {
          name: this.$t('day_sun'),
        },
      ],
      isParticipant: this.$route.name === 'view-participant',
    };
  },
  methods: {
    transformParams(text, variable) {
      return transformDurationParams(text, variable);
    },
  },
};
</script>

<style></style>
