<template>
  <div class="bg-white">
    <wizard-header title="Update Site" @goBack="showConfirmationModal = true" />
    <div class="bg-white border-bottom">
      <b-container>
        <div class="py-4">
          <step-wizard :currentStep="step" wizardType="sites"></step-wizard>
        </div>
      </b-container>
    </div>

    <div class="bg-white p-5" v-if="!loadingStatistics">
      <basic-information
        :isProtocol="isProtocolView"
        :basic="site.basic"
        :hasParticipantsNotScreenedOut="hasParticipantsNotScreenedOut"
        v-show="step === 0"
        @nextStep="step++"
      />
      <site-address
        :siteAddress="site.siteAddress"
        v-show="step === 1"
        @nextStep="
          () => {
            checkIsEdited();
            step++;
          }
        "
        @back="step--"
      />
      <overview
        :site="site"
        :addingSite="submitting"
        :is-updated="isSiteDataUpdated"
        v-if="step === 2"
        @back="step--"
        @updateSite="updateSite"
        :isProtocol="isProtocolView"
      />
    </div>

    <!-- Confirmation -->
    <b-modal
      v-model="showConfirmationModal"
      title="Update Site"
      footer-class="justify-content-center"
      header-class="justify-content-center"
      title-tag="h3"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <p class="text-center px-5">
        {{ $t('exit_update_site') }}
      </p>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" class="rounded-pill" @click="goBack">
          {{ $t('yes_cancel') }}
        </b-button>
        <b-button variant="primary" class="rounded-pill" @click="cancel()">
          {{ $t('no_continue') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BasicInformation from '@/components/Sites/BasicInformation';
import SiteAddress from '@/components/Sites/Address';
import Overview from '@/components/Sites/Overview';
import WizardHeader from '@/components/WizardHeader';

import { updateSite, updateSiteContact } from '@/api/site';
import { reduceKeys, hasKeysLength } from '@/utils';
import { equalArrays } from '@/utils/array.utils';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    BasicInformation,
    SiteAddress,
    Overview,
    WizardHeader,
  },
  data() {
    return {
      loadingStatistics: false,
      isSiteDataUpdated: false,
      showConfirmationModal: false,
      submitting: false,
      step: 0,
      siteInit: null,
      site: {
        basic: {
          description: '',
          protocolId: '',
          name: '',
        },
        siteAddress: {
          address: '',
          city: '',
          countryId: '',
          postalCode: '',
        },
      },
    };
  },

  created() {
    if (this.isProtocolView) this.site.basic.protocolId = this.protocol.id;
    this.site = JSON.parse(JSON.stringify(this.selectedSite));
    this.siteInit = JSON.parse(JSON.stringify(this.selectedSite));
    this.getSiteStatistics();
    this.setBreadCrumbData({
      'client-edit-site': [
        {
          text: this.$t('clients'),
          to: { name: 'clients' },
        },
        {
          text: this.selectedClient?.basic?.legalName,
          to: { name: 'client-details' },
        },
        {
          text: this.selectedStudy?.name,
          to: { name: 'client-study-details' },
        },
        {
          text: this.selectedSite.basic.name,
          to: { name: 'client-view-site' },
        },
        {
          text: this.$t('site_update'),
          active: true,
        },
      ],
      'study-edit-site': [
        {
          text: this.$t('studies'),
          to: { name: 'studies' },
        },
        {
          text: this.selectedStudy?.name,
          to: { name: 'study-details' },
        },
        {
          text: this.selectedSite.basic.name,
          to: { name: 'study-view-site' },
        },
        {
          text: this.$t('site_update'),
          active: true,
        },
      ],
      'protocol-edit-site': [
        {
          text: this.$t('protocols'),
          to: { name: 'protocols' },
        },
        {
          text: this.protocol?.name,
          to: { name: 'view-protocol' },
        },
        {
          text: this.selectedSite.basic.name,
          to: { name: 'protocol-view-site' },
        },
        {
          text: this.$t('site_update'),
          active: true,
        },
      ],
      'edit-site': [
        {
          text: this.$t('sites'),
          to: { name: 'sites' },
        },
        {
          text: this.selectedSite.basic.name,
          to: { name: 'view-site' },
        },
        {
          text: this.$t('site_update'),
          active: true,
        },
      ],
    });
  },
  computed: {
    ...mapState({
      selectedStudy: (state) => state.studies.selectedStudy,
      protocol: (state) => state.protocols.selectedProtocol,
      selectedSite: (state) => state.sites.selectedSite,
      selectedClient: (state) => state.clients.selectedClient,
      isProtocolView: (state) => state.sites.isProtocolView,
      siteParams: (state) => state.sites.siteParams,
      siteStatistics: (state) => state.sites.siteStatistics,
    }),
    hasParticipantsNotScreenedOut() {
      const { participantStatistics } = this.siteStatistics;
      const total = participantStatistics?.total ?? 0;
      const screenedOut = participantStatistics?.['screened-out'] ?? 0;
      return total - screenedOut > 0;
    },
  },
  methods: {
    ...mapActions({
      fetchSiteStatistics: 'sites/getSiteStatistics',
    }),
    checkIsEdited() {
      const oldS = this.siteInit;
      const newS = this.site;
      this.isSiteDataUpdated =
        oldS.basic.description !== newS.basic.description ||
        oldS.basic.protocolId !== newS.basic.protocolId ||
        oldS.basic.name !== newS.basic.name ||
        !equalArrays(oldS.basic.languages, newS.basic.languages) ||
        oldS.siteAddress.address !== newS.siteAddress.address ||
        oldS.siteAddress.city !== newS.siteAddress.city ||
        oldS.siteAddress.countryId !== newS.siteAddress.countryId ||
        oldS.siteAddress.postalCode !== newS.siteAddress.postalCode;
    },
    goBack() {
      this.$router.push({
        name:
          this.$route.name === 'edit-site'
            ? 'view-site'
            : `${this.$route.meta.parent}-view-site`,
      });
    },
    async updateSite() {
      this.submitting = true;

      try {
        const promises = [];
        /**
         * Check if any key value has changed in
         * basic object with store object
         */
        const basic = reduceKeys(this.selectedSite.basic, this.site.basic);

        if (
          !equalArrays(
            this.selectedSite.basic.languages,
            this.site.basic.languages
          )
        ) {
          basic.languages = [...this.site.basic.languages];
        } else {
          delete basic.languages;
        }
        hasKeysLength(basic) &&
          promises.push(
            updateSite(this.siteParams, {
              ...this.site.basic,
              ...(this.site.basic.description !== null &&
              this.site.basic.description.trim() === ''
                ? { description: null }
                : {}),
            })
          );
        /**
         * Check if any key has changed in
         * contact object with store object
         */
        const contact = reduceKeys(
          this.selectedSite.siteAddress,
          this.site.siteAddress
        );
        hasKeysLength(contact) &&
          promises.push(
            updateSiteContact(this.siteParams, this.site.siteAddress)
          );

        promises.length
          ? (await Promise.all(promises),
            this.$toast.info(this.$t('toast_site_updated')),
            this.goBack())
          : this.$toast.error(this.$t('error_something_went_wrong'));
      } catch (error) {
        this.$toast.error(this.$t('error_something_went_wrong'));
      } finally {
        this.submitting = false;
      }
    },
    async getSiteStatistics() {
      this.loadingStatistics = true;
      try {
        const { clientId, studyId, protocolId, siteId } = this.siteParams;
        await this.fetchSiteStatistics({
          clientId,
          studyId,
          protocolId,
          siteId,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingStatistics = false;
      }
    },
  },
};
</script>

<style></style>
